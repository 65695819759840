import { EditOutlined, UserOutlined } from "@ant-design/icons";
import {
	Avatar,
	Button,
	Col,
	Divider,
	Row,
	Typography,
	Badge,
	Skeleton,
	Tabs,
	Input,
	Form,
	Select,
	Switch,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import firebase from "../../../../API/firebase";
import CoverPhotoUploadModal from "../../../../Components/CoverPhotoUploadModal";
import ProfilePicUploadModal from "../../../../Components/ProfilePicUploadModal";
import { statusTagFormatter } from "../../../../Functions/formatters";
import "../../../../Styles/quill.snow.css";
import { colorsLight } from "../../../../Styles/Themes";
import { motion } from "framer-motion";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

const { Title } = Typography;

const CourseProviderViewHeader = ({ data }) => {
	const [editing, setEditing] = useState(false);
	const [summary, setSummary] = useState("");
	const [details, setDetails] = useState("");
	const [hoverProfilePic, setHoverProfilePic] = useState(false);
	const [loading, setloading] = useState(false);
	const [editProfilePic, setEditProfilePic] = useState(false);
	const [editCover, setEditCover] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [disabled, setDisabled] = useState([""]);
	const [form] = Form.useForm();

	const toggleEdit1 = () => {
		setEditProfilePic(false);
	};

	const toggleEdit2 = () => {
		setEditCover(false);
	};

	const imageBackground = {
		backgroundImage: `url(${data.coverPic})`,
		backgroundColor: colorsLight("grey"),
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: 130,
	};

	useEffect(() => {
		if (data.summary && data.details) {
			setSummary(data.summary);
			setDetails(data.details);
		}
	}, [data]);

	let children = [];

	const updateProfile = (val) => {
		console.log(val);
		setloading(true);
		firebase
			.firestore()
			.collection("CourseProviders")
			.doc(data.key)
			.update({
				summary: summary,
				details: details,
				name: val.name || "",
				websiteUrl: val.websiteUrl || "",
				location: val.location || "",
				courseAttributes: val.courseAttributes || [],
			})
			.then((res) => {
				console.log(res);
				setloading(false);
				setEditing(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const toggleEdit = () => {
		if (editing) {
			setEditing(false);
		} else {
			setExpanded(true);
			setEditing(true);
		}
	};

	const animVariants = {
		open: {
			maxHeight: 10000,
			opacity: 1,
		},
		closed: {
			maxHeight: 0,
			opacity: 0,
		},
	};

	useEffect(() => {
		let list = [];
		data.courseAttributes &&
			data.courseAttributes.forEach((item, index) => {
				item.type !== "selection" && list.push(index);
			});
		setDisabled(list);
	}, [data]);

	const isEven = (num) => {
		if (num % 2 === 0) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Badge.Ribbon
			color={statusTagFormatter(data.status)}
			text={
				<p style={{ fontSize: 14, marginLeft: 12, marginRight: 12 }}>
					{data.status ? data.status.toUpperCase() : "Loading..."}
				</p>
			}
			placement="start"
		>
			<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
				<Row style={imageBackground}>
					<Row
						justify="end"
						align="top"
						style={{
							backgroundColor: colorsLight("cardCover"),
							height: "100%",
							width: "100%",
						}}
					>
						<Row
							onClick={() => setEditCover(true)}
							justify="center"
							align="middle"
							style={styles.editIconWrapper}
						>
							<EditOutlined style={styles.editIcon} />
						</Row>
					</Row>
				</Row>
				<Row justify="space-between">
					<Row>
						<Row justify="center" align="middle" style={styles.imageWrapper}>
							<Avatar src={data.displayPic} size={130} icon={<UserOutlined />} />
							<Row
								justify="center"
								align="middle"
								onClick={() => setEditProfilePic(true)}
								onMouseEnter={() => setHoverProfilePic(true)}
								onMouseLeave={() => setHoverProfilePic(false)}
								style={{
									height: 130,
									width: 130,
									backgroundColor: hoverProfilePic
										? "rgba(0,0,0,0.5)"
										: "rgba(0,0,0,0)",
									position: "absolute",
									borderRadius: 65,
									cursor: "pointer",
								}}
							>
								{hoverProfilePic && (
									<p style={{ color: "white", fontWeight: "bold", margin: 0 }}>
										Edit Photo
									</p>
								)}
							</Row>
						</Row>
						<Col style={{ paddingLeft: 12, marginTop: 6 }}>
							<Title style={{ margin: 0, maxWidth: 450 }} level={2}>
								{data.name}
							</Title>
							<Title style={{ margin: 0, fontWeight: 300, marginLeft: 2 }} level={4}>
								{data.primaryCategory}
							</Title>
						</Col>
					</Row>
					<Row style={{ paddingRight: 12, paddingTop: 12 }}>
						{!editing && (
							<Button onClick={() => toggleEdit()} type="primary">
								Edit Profile
							</Button>
						)}
						{editing && (
							<>
								<Button size="small" onClick={() => toggleEdit()}>
									Cancel
								</Button>
								<Button
									size="small"
									htmlType="submit"
									form="infoForm"
									key="submit"
									style={{ marginLeft: 12 }}
									type="primary"
								>
									Update Profile
								</Button>
							</>
						)}
					</Row>
				</Row>

				<Tabs
					style={{ marginLeft: 24, marginRight: 24, marginTop: 12, marginBottom: 24 }}
					type="card"
				>
					<Tabs.TabPane tab="Information" key="1">
						{!editing ? (
							<>
								<Row style={styles.paddingHoro}>
									<Title level={5}>Basic Info:</Title>
								</Row>
								<Row style={{ paddingLeft: 12 }}>
									<Col style={{ paddingRight: 12 }} span={12}>
										<Row justify="space-between" align="bottom">
											<p className="Key">Name:</p>
											<p className="Value">{data.name || "-"}</p>
										</Row>
										<Row justify="space-between" align="bottom">
											<p className="Key">Website:</p>
											<a
												href={data.websiteUrl}
												target="_blank"
												className="Value"
											>
												{data.websiteUrl || "-"}
											</a>
										</Row>
									</Col>
									<Col style={{ paddingLeft: 12 }} span={12}>
										<Row justify="space-between" align="bottom">
											<p className="Key">Location:</p>
											<p className="Value">{data.location || "-"}</p>
										</Row>
									</Col>
								</Row>
								<Row style={{ ...styles.paddingHoro, marginTop: 12 }}>
									<Title level={5}>Course Attributes:</Title>
								</Row>
								{data.courseAttributes && (
									<Row style={{ paddingLeft: 12 }}>
										{data.courseAttributes.map((item, index) => (
											<Col
												key={index}
												style={{
													paddingRight: !isEven(index + 1) ? 12 : 0,
													paddingLeft: isEven(index + 1) ? 12 : 0,
												}}
												span={12}
											>
												<Row justify="space-between" align="middle">
													<p className="Key">{item.label}:</p>
													<p className="Value">{`${
														item.filterable ? "Filterable" : ""
													} ${item.type}`}</p>
												</Row>
											</Col>
										))}
									</Row>
								)}
							</>
						) : (
							<Form
								onFinish={updateProfile}
								layout="vertical"
								form={form}
								initialValues={
									data.courseAttributes && data.courseAttributes.length > 0
										? data
										: {
												...data,
												courseAttributes: [
													{ label: "Duration", type: "string" },
												],
										  }
								}
								id="infoForm"
							>
								<Row style={styles.paddingHoro}>
									<Title level={5}>Basic Info:</Title>
								</Row>
								<Row style={{ paddingLeft: 12 }}>
									<Col style={{ paddingRight: 12 }} span={12}>
										<Row justify="space-between" align="center">
											<p className="Key">Name:</p>
											<Form.Item
												style={{
													marginTop: 0,
													marginBottom: 6,
													width: "70%",
												}}
												required={true}
												name="name"
											>
												<Input
													style={{ textAlign: "end" }}
													placeholder="Please enter first name"
												/>
											</Form.Item>
										</Row>
									</Col>
									<Col style={{ paddingLeft: 12 }} span={12}>
										<Row justify="space-between" align="center">
											<p className="Key">Website:</p>
											<Form.Item
												style={{
													marginTop: 0,
													marginBottom: 6,
													width: "70%",
												}}
												required={false}
												name="websiteUrl"
											>
												<Input
													style={{ textAlign: "end" }}
													placeholder="Please enter website url"
												/>
											</Form.Item>
										</Row>
									</Col>
								</Row>
								<Row style={{ ...styles.paddingHoro, marginTop: 12 }}>
									<Title level={5}>Course Attributes:</Title>
								</Row>
								<Row>
									<Col style={{ paddingLeft: 12 }} span={4}>
										<p className="Key">Label:</p>
									</Col>
									<Col style={{ paddingLeft: 12 }} span={4}>
										<p className="Key">Type:</p>
									</Col>
									<Col style={{ paddingLeft: 6 }} span={4}>
										<p className="Key">Database Ref:</p>
									</Col>
									<Col style={{ paddingLeft: 6 }} span={7}>
										<p className="Key">Options:</p>
									</Col>
									<Col style={{ paddingLeft: 6 }} span={3}>
										<p className="Key">Filterable:</p>
									</Col>
									<Col span={2}></Col>
									<Form.List name="courseAttributes">
										{(fields, { add, remove }) => (
											<>
												{fields.map((field, index) => (
													<Form.Item key={index} noStyle>
														<Row
															style={{
																width: "100%",
																paddingLeft: 12,
																margin: 0,
															}}
														>
															<Col
																style={{ paddingRight: 24 }}
																span={4}
															>
																<Form.Item
																	{...field}
																	name={[field.name, "label"]}
																>
																	<Input
																		disabled={index <= 2}
																		placeholder="Please enter label name"
																	/>
																</Form.Item>
															</Col>
															<Col
																style={{ paddingRight: 24 }}
																span={4}
															>
																<Form.Item
																	{...field}
																	shouldUpdate={() => true}
																	name={[field.name, "type"]}
																>
																	<Select disabled={index <= 2}>
																		<Select.Option
																			key="singleLine"
																			value="Text Input"
																		>
																			Text Input
																		</Select.Option>
																		<Select.Option
																			key="multiLine"
																			value="Text Area"
																		>
																			Text Area
																		</Select.Option>
																		<Select.Option
																			key="checkBox"
																			value="Check Box"
																		>
																			Checkbox
																		</Select.Option>
																		<Select.Option
																			key="selection"
																			value="Selection"
																		>
																			Selection
																		</Select.Option>
																	</Select>
																</Form.Item>
															</Col>
															<Col
																style={{ paddingRight: 24 }}
																span={4}
															>
																<Form.Item
																	{...field}
																	name={[field.name, "ref"]}
																	rules={[
																		{
																			required: false,
																			message:
																				"We need a ref",
																		},
																	]}
																>
																	<Input
																		disabled={index <= 2}
																		placeholder="e.g firstName or lastSeen"
																	/>
																</Form.Item>
															</Col>
															<Col
																style={{ paddingRight: 24 }}
																span={7}
															>
																<Form.Item
																	initialValue={[""]}
																	{...field}
																	name={[field.name, "options"]}
																>
																	<Select
																		disabled={index <= 2}
																		mode="tags"
																	/>
																</Form.Item>
															</Col>
															<Col
																style={{ paddingRight: 24 }}
																span={3}
															>
																<Form.Item
																	initialValue={false}
																	{...field}
																	name={[
																		field.name,
																		"filterable",
																	]}
																	valuePropName="checked"
																>
																	<Switch disabled={index <= 2} />
																</Form.Item>
															</Col>
															<Col span={2}>
																<Row
																	justify="end"
																	align="center"
																	style={{
																		paddingTop: 0,
																		width: "100%",
																	}}
																>
																	{index > 2 && (
																		<Button
																			disabled={index <= 2}
																			onClick={() =>
																				remove(field.name)
																			}
																		>
																			Remove
																		</Button>
																	)}
																</Row>
															</Col>
														</Row>
														<Row
															style={{
																paddingLeft: 12,
																marginBottom:
																	fields.length === index + 1
																		? 24
																		: 0,
															}}
														>
															{fields.length === index + 1 && (
																<Button onClick={() => add()}>
																	Add Attribute
																</Button>
															)}
														</Row>
													</Form.Item>
												))}
											</>
										)}
									</Form.List>
								</Row>
							</Form>
						)}
					</Tabs.TabPane>
					<Tabs.TabPane tab="Profile" key="2">
						<Row style={styles.paddingHoro}>
							<Title level={5}>Summary</Title>
						</Row>
						{!editing && (
							<Row style={styles.paddingHoro}>
								<p style={{ margin: 0 }}>{data.summary}</p>
							</Row>
						)}
						{!data.summary && (
							<Row style={styles.paddingHoro}>
								<Skeleton active paragraph={{ width: "100%", rows: 5 }} />
							</Row>
						)}
						{editing && (
							<Row style={{ ...styles.paddingHoro, width: "100%" }}>
								<Col span={24}>
									<TextArea
										style={{ width: "100%" }}
										value={summary}
										onChange={(val) => setSummary(val.target.value)}
										placeholder="Please enter summary"
									/>
								</Col>
							</Row>
						)}
						<motion.div
							initial={{ opacity: 0 }}
							animate={expanded ? "open" : "closed"}
							variants={animVariants}
						>
							<Row style={{ ...styles.paddingHoro, marginTop: 42 }}>
								<Title level={5}>About</Title>
							</Row>
							{!editing && (
								<Row style={{ ...styles.paddingHoro, marginBottom: 42 }}>
									<div dangerouslySetInnerHTML={{ __html: data.details }} />
								</Row>
							)}
							{!data.details && (
								<Row style={styles.paddingHoro}>
									<Skeleton active paragraph={{ width: "100%", rows: 8 }} />
								</Row>
							)}
							{editing && (
								<Row style={{ ...styles.paddingHoro, marginBottom: 70 }}>
									<ReactQuill
										placeholder="Enter coach bio..."
										theme="snow"
										value={details}
										onChange={setDetails}
										style={{ width: "100%" }}
									/>
								</Row>
							)}
						</motion.div>
						<Row justify="center" align="middle" style={{ width: "100%" }}>
							{!editing && (
								<Col
									style={{ cursor: "pointer" }}
									onClick={() => setExpanded(!expanded)}
								>
									<Row justify="center" align="middle">
										<Row
											justify="center"
											align="middle"
											style={{
												height: 30,
												width: 30,
												backgroundColor: "white",
												boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
												borderRadius: 15,
											}}
										>
											{!expanded && <ArrowDownOutlined />}
											{expanded && <ArrowUpOutlined />}
										</Row>
									</Row>
									<Row>
										<p
											style={{
												color: colorsLight("grey"),
												fontSize: 14,
												marginTop: 6,
											}}
										>
											{expanded ? "Minimise Profile" : "Expand Profile"}
										</p>
									</Row>
								</Col>
							)}
						</Row>
					</Tabs.TabPane>
				</Tabs>
				<ProfilePicUploadModal
					visible={editProfilePic}
					dismiss={toggleEdit1}
					userType="CourseProviders"
					data={data}
				/>
				<CoverPhotoUploadModal
					visible={editCover}
					dismiss={toggleEdit2}
					userType="CourseProviders"
					data={data}
				/>
			</Col>
		</Badge.Ribbon>
	);
};

const styles = {
	imageWrapper: {
		height: 142,
		width: 142,
		borderRadius: 71,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginTop: -60,
		marginLeft: 24,
	},
	paddingHoro: {
		paddingLeft: 12,
		paddingRight: 12,
	},
	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
};

export default CourseProviderViewHeader;
