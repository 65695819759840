import { Row, Col, Layout, Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import { colorsLight } from "../../../Styles/Themes";
import firebase from "../../../API/firebase";
import ServiceProviderViewHeader from "./Components/ServiceProviderViewHeader";
import { useParams, Link } from "react-router-dom";
import BackButton from "../../../Components/Navigation/BackButton";
import ServiceProviderServiceList from "./Components/ServiceProviderServiceList";
import ServiceProviderQuickActions from "./Components/ServiceProviderQuickActions";
import ServiceProviderCommercials from "./Components/ServiceProviderCommercials";

const { Header, Content } = Layout;

const ServiceProviderView = () => {
	// const [addModal, setAddModal] = useState(false);
	const [data, setData] = useState({});
	const [Services, setServices] = useState([]);
	const [commercials, setCommercials] = useState({});
	const [editComms, setEditComms] = useState(false);
	// const [users, setUsers] = useState([]);
	const { providerId } = useParams();

	// const dismissModal = () => setAddModal(false);

	const toggleEditComms = () => setEditComms(!editComms);

	useEffect(() => {
		let listener = async () =>
			firebase
				.firestore()
				.collection("ServiceProviders")
				.doc(providerId)
				.onSnapshot((doc) => {
					setData({ ...doc.data(), key: doc.id });
				});

		let listener2 = async () =>
			firebase
				.firestore()
				.collection("ServiceProviders")
				.doc(providerId)
				.collection("Services")
				.onSnapshot((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setServices(list);
				});

		let listener3 = async () =>
			firebase
				.firestore()
				.collection("Commercials")
				.doc(providerId)
				.onSnapshot((doc) => {
					setCommercials({ ...doc.data(), key: doc.id });
				});

		listener();
		listener2();
		listener3();
	}, [providerId]);

	return (
		<Layout className="App">
			<MenuMain active="5" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton to={`/Service-Providers`} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row style={{ marginBottom: 12 }}>
							<Breadcrumb>
								<Breadcrumb.Item>
									<Link to="/Service-Providers">Services</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>{data.name}</Breadcrumb.Item>
							</Breadcrumb>
						</Row>
						<Row>
							<Col span={16}>
								<ServiceProviderViewHeader data={data} />
								<ServiceProviderServiceList
									data={Services}
									providerId={providerId}
									providerData={data}
									markup={commercials.markup}
								/>
							</Col>
							<Col style={{ paddingLeft: 24 }} span={8}>
								<ServiceProviderQuickActions data={data} list={Services} />
								<ServiceProviderCommercials
									commercials={commercials}
									editing={editComms}
									toggleEditComms={toggleEditComms}
								/>
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		paddingLeft: 22,
		paddingRight: 22,
		paddingTop: 12,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default ServiceProviderView;
