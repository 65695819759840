import { Row, Col, Layout, Typography, Divider, Upload, message, Button, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import MenuMain from "../../Components/MainMenu";
import { colorsLight } from "../../Styles/Themes";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import UserContext from "../../Context/UserContext";
import { app, firestore, storage } from "firebase";
import ImageLibraryUploadModal from "../../Components/ImageLibrary/ImageUploadModal";
const { Header, Content } = Layout;

const ImageLibraryView = () => {
	const { userInfo } = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const [hover, setHover] = useState();
	const [modal, setModal] = useState(false);
	const [images, setImages] = useState([]);

	useEffect(() => {
		let listener = async () =>
			firestore()
				.collection("ImageLibrary")
				.onSnapshot((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setImages(list);
				});
		listener();
	}, []);

	const toggleModal = () => setModal(!modal);

	const deleteImage = async (url, imageId) => {
		try {
			const ref = storage().refFromURL(url);

			await firestore().collection("ImageLibrary").doc(imageId).delete();
			await ref.delete();
			message.success("Image deleted");
			return;
		} catch (err) {
			message.error("Could not delete image, please try again");
			console.log(err);
		}
	};

	const testImage = (fileName, fileRef) => {
		setLoading(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("TestImage")({ fileName: fileName, fileRef: fileRef })
			.then((res) => {
				console.log(res);
				setLoading(false);
			})
			.catch((err) => {
				message.error("something went wrong");
				console.log(err);
				setLoading(false);
			});
	};

	return (
		<Layout className="App">
			<MenuMain active="z" />
			<Layout>
				<Header className="Header">
					<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row style={{ padding: 24 }}>
							<Typography.Title level={1}>Image Library</Typography.Title>
						</Row>
						<Row style={{ padding: 24 }}>
							<Col span={24} className="Card">
								<Row justify="space-between">
									<Typography.Title level={4}>Upload photos</Typography.Title>

									<Button onClick={toggleModal} type="primary">
										Upload Image
									</Button>
								</Row>

								<Row>
									<Divider style={{ marginTop: 12, marginBottom: 12 }} />
								</Row>
								<Row style={{ minHeight: 500 }} data-testid="images-list">
									{images.map((image, index) => (
										<Col
											onMouseEnter={() => setHover(index)}
											onMouseLeave={setHover}
											style={{
												height: 150,
												paddingLeft: 6,
												paddingRight: 6,
											}}
											key={index}
											span={6}
										>
											<img
												style={{
													boxShadow: "1px 2px 2px rgba(0,0,0,0.3)",
													padding: 6,
													objectFit: "cover",
													height: "100%",
													width: "100%",
												}}
												src={image.url}
												alt="library"
												data-testid="image"
											/>
											{hover === index && (
												<Row
													style={{
														padding: 6,
														position: "absolute",
														top: 0,
														width: "96.5%",
														height: "100%",
													}}
												>
													<Row
														style={{
															backgroundColor: "rgba(0,0,0,0.5)",
															height: "100%",
															width: "100%",
														}}
														justify="center"
														align="middle"
													>
														<Button
															onClick={() =>
																deleteImage(image.url, image.key)
															}
															size="small"
															data-testid="delete"
														>
															Delete Image
														</Button>
													</Row>
												</Row>
											)}
										</Col>
									))}
									{loading && (
										<Col
											style={{ backgroundColor: "grey", height: 120 }}
											span={6}
											data-testid="loading"
										>
											<Row
												style={{ height: "100%" }}
												justify="center"
												align="middle"
											>
												<Spin />
											</Row>
										</Col>
									)}
								</Row>
							</Col>
						</Row>
					</Col>
					<ImageLibraryUploadModal visible={modal} dismiss={toggleModal} />
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default ImageLibraryView;
