import { Row, Col, Typography, Button, notification, message } from "antd";
import React, { useState } from "react";
import firebase from "../../../../API/firebase";
const { Title } = Typography;

const TeamMemberQuickActions = ({ data }) => {
	const [loading, setloading] = useState(false);
	const [loading2, setloading2] = useState(false);

	const resetPassword = async () => {
		setloading(true);
		await firebase.auth().sendPasswordResetEmail(data.email);
		openNotificationWithIcon("success");
		setloading(false);
		return;
	};

	const sendInvite = async (uid, email, name) => {
		setloading2(true);
		firebase
			.app()
			.functions("asia-northeast1")
			.httpsCallable("InviteAdmin")({ name, email, uid })
			.then((res) => {
				console.log(res.data);
				message.success(`Successfully sent invitation to ${email}.`);
				setloading2(false);
			})
			.catch((err) => {
				console.log(err);
				message.error(`Something went wrong!`);
				setloading2(false);
			});
	};

	const openNotificationWithIcon = (type) => {
		notification[type]({
			message: "Password successfully reset",
			description: `${data.firstName} has been sent a password reset email to ${data.email}`,
		});
	};

	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Quick Actions</Title>
			</Row>
			<Row style={styles.row}>
				<Col style={{ paddingRight: 6 }} span={12}>
					<Button
						loading={loading2}
						onClick={() => sendInvite(data.key, data.email, data.firstName)}
						style={styles.button}
						data-testid="invite"
					>
						Send Invitation
					</Button>
				</Col>
				<Col style={{ paddingLeft: 6 }} span={12}>
					<Button
						loading={loading}
						onClick={resetPassword}
						style={styles.button}
						data-testid="reset"
					>
						Reset Password
					</Button>
				</Col>
			</Row>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default TeamMemberQuickActions;
