import { Row, Col, Divider, Typography, InputNumber } from "antd";
import React from "react";
import { priceFormatter } from "../../../../../Functions/formatters";
import { colorsLight } from "../../../../../Styles/Themes";
const { Title } = Typography;

const CoachPackageViewCommercials = ({
	data,
	costPrice,
	setCostPrice,
	editing,
	salePrice,
	profit,
}) => {
	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Commercials</Title>
			</Row>
			<Row justify="space-between" style={styles.row}>
				<p className="Key">Sale Price:</p>
				{salePrice && (
					<p style={styles.cost} data-testid="cost">
						{priceFormatter(salePrice, "dollars")}
					</p>
				)}
			</Row>
			<Row justify="space-between" style={{ ...styles.row, marginBottom: editing ? 12 : 0 }}>
				<p className="Key">Coach Fee:</p>
				{data.costPrice && !editing && (
					<p style={styles.cost} data-testid="price">
						{priceFormatter(data.costPrice, "dollars")}
					</p>
				)}
				{editing && (
					<InputNumber
						size="large"
						style={{ ...styles.cost, width: 110 }}
						value={costPrice}
						formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						onChange={(val) => setCostPrice("costPrice", val)}
						data-testid="price-edit"
					/>
				)}
			</Row>
			<Divider style={{ margin: 0 }} />
			<Row justify="space-between" style={styles.row}>
				<p className="Key">Profit:</p>
				{data.costPrice && (
					<p style={styles.profit} data-testid="profit">
						{priceFormatter(profit, "dollars")}
					</p>
				)}
			</Row>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
		height: 30,
	},
	profit: {
		fontSize: 22,
		fontWeight: "bold",
		color: colorsLight("lightBlue"),
		margin: 0,
	},
	cost: {
		fontSize: 18,
		fontWeight: "bold",
		margin: 0,
	},
};

export default CoachPackageViewCommercials;
