import { Button, Col, Divider, Input, InputNumber, message, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { priceFormatter } from "../../../../Functions/formatters";

const AccountRiskManagementPanel = ({ accountId }) => {
	const [loading, setLoading] = useState(false);
	const [exposure, setExposure] = useState(0);
	const [ppexposure, setPPExposure] = useState(0);
	const [editing, setEditing] = useState(false);

	useEffect(() => {
		let listener = firestore()
			.collection("RiskManagement")
			.doc(accountId)
			.onSnapshot((doc) => {
				if (doc.exists) {
					setExposure(doc.data().totalExposure);
					setPPExposure(doc.data().maxSingleExposure);
				}
			});

		return () => {
			listener();
		};
	}, []);

	const toggleEdit = () => setEditing(!editing);

	const saveChanges = () => {
		setLoading(true);
		firestore()
			.collection("RiskManagement")
			.doc(accountId)
			.set({ totalExposure: exposure, maxSingleExposure: ppexposure })
			.then(() => {
				setLoading(false);
				toggleEdit();
			})
			.catch((err) => {
				setLoading(false);
				message.error(err);
				toggleEdit();
			});
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Typography.Title level={4} style={{ margin: 0 }}>
					Account Credit Limits
				</Typography.Title>
				{!editing ? (
					<Button onClick={toggleEdit} size="small">
						Edit
					</Button>
				) : (
					<Row gutter={12}>
						<Col>
							<Button onClick={toggleEdit} size="small">
								Cancel
							</Button>
							<Button onClick={saveChanges} size="small">
								Update
							</Button>
						</Col>
					</Row>
				)}
			</Row>
			<Divider style={{ margin: "6px 0px" }} />
			<Row style={{ width: "100%" }} justify="space-between">
				<Col span={12}>
					<p className="Key">Total exposure limit</p>
				</Col>

				<Col span={12}>
					{!editing ? (
						<p className="Value">{priceFormatter(exposure, "dollars")}</p>
					) : (
						<InputNumber
							style={{ width: "100%" }}
							formatter={(value) =>
								`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
							value={exposure}
							onChange={(val) => setExposure(val)}
						/>
					)}
				</Col>
			</Row>
			<Row style={{ width: "100%", paddingTop: 12 }} justify="space-between">
				<Col span={12}>
					<p className="Key">Max exposure per employee</p>
				</Col>

				<Col span={12}>
					{!editing ? (
						<p className="Value">{priceFormatter(ppexposure, "dollars")}</p>
					) : (
						<InputNumber
							style={{ width: "100%" }}
							formatter={(value) =>
								`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
							value={ppexposure}
							onChange={(val) => setPPExposure(val)}
						/>
					)}
				</Col>
			</Row>
		</Col>
	);
};

export default AccountRiskManagementPanel;
