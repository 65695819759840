import { Row, Col, Layout } from "antd";
import React, { useEffect, useState } from "react";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import { colorsLight } from "../../../Styles/Themes";
import firebase from "../../../API/firebase";
import { useParams } from "react-router-dom";
import TeamMemberHeader from "./Components/TeamMember-Header";
import TeamMemberQuickActions from "./Components/TeamMemberQuickActions";
import BackButton from "../../../Components/Navigation/BackButton";

const { Header, Content } = Layout;

const TeamMemberView = () => {
	const [data, setData] = useState({});
	const { teamMemberId } = useParams();

	useEffect(() => {
		let listener = async () =>
			await firebase
				.firestore()
				.collection("Admins")
				.doc(teamMemberId)
				.onSnapshot((snap) => {
					snap.exists && setData({ ...snap.data(), key: snap.id });
				});
		listener();
	}, [teamMemberId]);

	return (
		<Layout className="App">
			<MenuMain active="z" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton to="/Team" />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row style={styles.row}>
							<Col span={16}>
								<TeamMemberHeader data={data} />
							</Col>
							<Col style={{ paddingLeft: 24 }} span={8}>
								<TeamMemberQuickActions data={data} />
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 22,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default TeamMemberView;
