import { Row, Col, Typography, Table, Button, Tag } from "antd";
import React, { useState } from "react";
import AddPackage from "./AddCoachPackage";
import { PlusOutlined } from "@ant-design/icons";
import { getProfit } from "../../../../Functions/helpers";
import { priceFormatter } from "../../../../Functions/formatters";
import { Link } from "react-router-dom";
const { Title } = Typography;

const CoachPackages = ({ data, markup, coachData }) => {
	const [modal, setModal] = useState(false);

	const dismiss = () => setModal(false);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => <Tag color={text === "active" ? "green" : "red"}>{(text || "").toUpperCase()}</Tag>,
		},
		{
			title: "Coach's Fee",
			dataIndex: "costPrice",
			key: "costPrice",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="package-price">
					{priceFormatter(text || 0, "dollars")}
				</p>
			),
		},
		{
			title: "Profit",
			dataIndex: "costPrice",
			key: "costPrice",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="package-profit">
					{getProfit(text, markup)}
				</p>
			),
		},
		{
			title: "Orders",
			dataIndex: "orders",
			key: "orders",
		},
		{
			title: "Sessions",
			dataIndex: "sessions",
			key: "sessions",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="package-session">
					{text}
				</p>
			),
		},
		{
			title: "Action",
			render: (record) => (
				<Link to={`/Coach-Package/${coachData.key}&${record.key}`}>
					<Button size="small" data-testid="package-view">
						View
					</Button>
				</Link>
			),
		},
	];

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Title level={4}>Packages</Title>
				<Button onClick={() => setModal(!modal)} data-testid="package-add">
					<PlusOutlined />
					Add New Package
				</Button>
			</Row>
			<Row style={{ paddingTop: 6 }}>
				<Table style={{ width: "100%" }} columns={columns} dataSource={data} />
			</Row>
			<AddPackage
				visible={modal}
				dismiss={dismiss}
				coachId={coachData.key}
				coachCat={coachData.primaryCategory || "none"}
				coachData={coachData}
			/>
		</Col>
	);
};

export default CoachPackages;
