import { Button, Col, Divider, Layout, message, Row, Select, Typography, List, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import BackButton from "../../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../../Styles/Themes";
import CourseCardLite from "./Components/CourseCardLite";
import { filterData } from "../../../Functions/utilities";
import { firestore } from "firebase";
import AddProviderToPlaylistModal from "./Components/AddProviderToPlaylistModal";

const { Header, Content } = Layout;

const StoreCourseProviderPage = () => {
	const [data, setData] = useState({});
	const [items, setItems] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const { providerId } = useParams();
	const history = useHistory();
	const [, setSpan] = useState(4);
	const [, setRemainderSpan] = useState(4);
	const [attributes, setAttributes] = useState({});
	const [filters, setFilters] = useState({});
	const [formFilters] = Form.useForm();
	const [, setSorting] = useState(false);
	const [courseAttis, setCourseAttis] = useState([]);
	const [addPlaylistModal, setAddPlaylistModal] = useState(false);

	useEffect(() => {
		firestore()
			.collection("CourseProviders")
			.doc(providerId)
			.get()
			.then((doc) => {
				doc.exists && setData({ ...doc.data(), key: doc.id });
			})
			.catch((err) => {
				message.error(err);
			});

		firestore()
			.collection("Courses")
			.where("owner", "==", providerId)
			.get()
			.then((snap) => {
				let list = [];
				snap.forEach((doc) => {
					// list.push({ ...doc.data(), key: doc.id });

					let i = 0;
					while (i < 1) {
						list.push({ ...doc.data(), key: doc.id });
						i++;
					}
				});
				setItems(list);
				setFilteredData(list);
			});
	}, [providerId]);

	useEffect(() => {
		if (data.courseAttributes) {
			const cleanAttis = data.courseAttributes.filter((item) => item.filterable === true);
			const attributeCount = cleanAttis.length + 1;
			const res = Math.floor(24 / attributeCount);
			const remainder = 24 - res * (attributeCount - 1);
			console.log(`span ${res}`);
			console.log(`remainder ${remainder}`);
			setCourseAttis(cleanAttis);
			setRemainderSpan(remainder);
			setSpan(res);
		}
	}, [data]);

	useEffect(() => {
		if (items.length > 0 && courseAttis) {
			let obj = {};
			let stateObj = { price: "" };
			courseAttis.forEach((item) => {
				const ref = item.ref;
				let list = [];

				items.forEach((item) => {
					if (item[ref] && !list.includes(item[ref])) {
						item[ref] && list.push(item[ref]);
					}
				});
				obj[item.ref] = list;
				stateObj[item.ref] = "";
			});
			setAttributes(obj);
			setFilters(stateObj);
		}
	}, [items, courseAttis]);

	const handleFilterState = (ref, val) => {
		let newState = filters;
		newState[ref] = val;
		setFilters(newState);
	};

	const applyFilters = () => {
		setSorting(true);
		let res = filterData(items, filters);
		setFilteredData(res);
		setSorting(false);
	};

	useEffect(() => {
		console.log(filteredData);
	}, [filteredData]);

	const resetFilters = () => {
		let stateObj = { price: "" };
		courseAttis.forEach((item) => {
			stateObj[item.ref] = "";
		});
		setFilters(stateObj);
		formFilters.resetFields();
		let filtrd = filterData(items, stateObj);
		setFilteredData(filtrd);
	};

	const playlistModal = () => setAddPlaylistModal(!addPlaylistModal);

	return (
		<Layout className="App">
			<MenuMain active="2" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							paddingLeft: 24,
							paddingRight: 24,
						}}
					>
						<Row style={{ maxWidth: maxContentWidth, width: "100%" }}>
							<Col style={{ paddingTop: 24 }} span={24}>
								<Row>
									<Typography.Title data-testid="course-name">
										{data.name || "Loading"}
									</Typography.Title>
								</Row>
								<Row className="Card">
									<Col span={24}>
										<Row justify="space-between">
											<Typography.Title level={4}>
												Filter courses
											</Typography.Title>
											<Typography.Link onClick={playlistModal}>
												Add provider to playlist
											</Typography.Link>
										</Row>
										<Divider style={{ margin: "0px 0px 6px 0px" }} />
										<Row>
											<Col flex="auto">
												<Form style={{ height: 60 }} form={formFilters}>
													<Row>
														{courseAttis.length > 0 && (
															<>
																{courseAttis.map((item, index) => (
																	<Col
																		style={{ paddingRight: 12 }}
																		flex="auto"
																		key={index}
																	>
																		<p className="Key">
																			{item.label}
																		</p>
																		<Form.Item name={item.ref}>
																			<Select
																				onChange={(val) =>
																					handleFilterState(
																						item.ref,
																						val
																					)
																				}
																				placeholder={`Filter by ${item.label.toLowerCase()}`}
																				style={{
																					width: "100%",
																				}}
																			>
																				<Select.Option
																					key="z"
																					value=""
																				></Select.Option>
																				{attributes[
																					item.ref
																				] &&
																					attributes[
																						item.ref
																					].map(
																						(
																							item,
																							index
																						) => (
																							<Select.Option
																								key={
																									index
																								}
																								value={
																									item
																								}
																							>
																								{
																									item
																								}
																							</Select.Option>
																						)
																					)}
																			</Select>
																		</Form.Item>
																	</Col>
																))}
																<Col
																	style={{ paddingRight: 12 }}
																	flex="auto"
																>
																	<p className="Key">Price</p>
																	<Form.Item name="price">
																		<Select
																			onChange={(val) =>
																				handleFilterState(
																					"price",
																					val
																				)
																			}
																			placeholder="Sort by price"
																			style={{
																				width: "100%",
																			}}
																		>
																			<Select.Option
																				key="1"
																				value="desc"
																			>
																				High to low
																			</Select.Option>
																			<Select.Option
																				key="2"
																				value="asc"
																			>
																				Low to high
																			</Select.Option>
																		</Select>
																	</Form.Item>
																</Col>
															</>
														)}
													</Row>
												</Form>
											</Col>
											<Col flex="164px">
												<Row
													style={{ height: "100%", width: "100%" }}
													justify="end"
													align="bottom"
												>
													<Button
														onClick={resetFilters}
														data-testid="course-reset"
													>
														Reset
													</Button>
													<Button
														onClick={applyFilters}
														type="primary"
														style={{ marginLeft: 12 }}
														data-testid="course-filter"
													>
														Apply
													</Button>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row style={{ width: "100%", marginBottom: 24 }}>
									<List
										pagination={
											filteredData.length > 0
												? {
														pageSize: 9,
												  }
												: false
										}
										style={{ width: "100%" }}
										grid={{ column: 3, gutter: 24 }}
										dataSource={filteredData}
										renderItem={(item, index) => (
											<Col key={index}>
												<CourseCardLite data={item} />
											</Col>
										)}
										data-testid="course-list"
									/>
								</Row>
							</Col>
						</Row>
					</Row>
					<AddProviderToPlaylistModal
						visible={addPlaylistModal}
						dismiss={playlistModal}
						providerData={data}
						courses={items}
					/>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
	leftPad: {
		paddingLeft: 0,
		paddingRight: 12,
	},
	centrePad: {
		paddingLeft: 12,
		paddingRight: 12,
	},
	rightPad: {
		paddingLeft: 12,
		paddingRight: 0,
	},
};

export default StoreCourseProviderPage;
