import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../API/firebase";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import MenuMain from "../../Components/MainMenu";
import DataContext from "../../Context/DataContext";
import { colorsLight } from "../../Styles/Themes";
import AddCoach from "./Components/AddCoach";
import CoachesTable from "./Components/CoachesTable";

const { Header, Content } = Layout;
const { Title } = Typography;

const CoachesPage = () => {
	const [addModal, setAddModal] = useState(false);
	const { coaches, setCoaches } = useContext(DataContext);
	// const [users, setUsers] = useState([]);
	const { tableRef } = useParams();
	const history = useHistory();

	const dismissModal = () => setAddModal(false);

	useEffect(() => {
		const listener = firestore()
			.collection("Coaches")
			.onSnapshot((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setCoaches(list);
			});
		return () => {
			listener();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout className="App">
			<MenuMain active="3" />
			<Layout>
				<Header className="Header">
					<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row justify="space-between">
							<Title data-testid="coach-title">Coaches</Title>
							<Button
								onClick={() => setAddModal(!addModal)}
								type="primary"
								style={{ marginTop: 12 }}
								data-testid="coach-button"
							>
								<PlusOutlined /> Add New Coach
							</Button>
						</Row>

						<Row style={styles.row}>
							<Col span={24} data-testid="coach-table">
								<CoachesTable tableRef={tableRef} data={coaches} />
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
			<AddCoach visible={addModal} dismiss={dismissModal} />
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 22,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default CoachesPage;
