import { Row, Col, Divider, Typography, Button, InputNumber, message } from "antd";
import React, { useEffect, useState } from "react";
import firebase from "../../../../../API/firebase";
import { priceFormatter } from "../../../../../Functions/formatters";
import { colorsLight } from "../../../../../Styles/Themes";
const { Title } = Typography;

const ServiceCommercials = ({ costPrice, setCostPrice, markup, data }) => {
	const [profit, setProfit] = useState(0);
	const [salePrice, setSalePrice] = useState(0);
	const [editing, setEditing] = useState(false);

	const [, setloading] = useState(false);

	useEffect(() => {
		if (markup && costPrice) {
			let markupAsPerc = markup / 100;
			let profitMargin = costPrice * markupAsPerc;
			setProfit(profitMargin);
			let salep = costPrice + profitMargin;
			setSalePrice(salep);
		}
	}, [costPrice, markup]);

	const toggleEdit = () => {
		if (!editing) {
			setEditing(true);
		} else {
			if (costPrice !== data.costPrice) {
				setCostPrice(data.costPrice);
				setEditing(false);
			} else {
				setEditing(false);
			}
		}
	};

	const updateProfile = () => {
		setloading(true);
		firebase
			.firestore()
			.collection("ServiceProviders")
			.doc(data.owner)
			.collection("Services")
			.doc(data.key)
			.update({
				costPrice,
			})
			.then(() => {
				setloading(false);
				setEditing(false);
			})
			.catch(() => {
				setloading(false);
				setEditing(false);
				message.error("Count not update cost price");
			});
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Title level={5}>Commercials</Title>
				{!editing && (
					/* eslint-disable jsx-a11y/anchor-is-valid */
					<a onClick={toggleEdit} data-testid="edit">
						Edit
					</a>
				)}
				{editing && (
					<Row>
						<Button size="small" type="link" onClick={toggleEdit} data-testid="cancel">
							Cancel
						</Button>
						<Button
							size="small"
							type="link"
							onClick={updateProfile}
							data-testid="update"
						>
							Update
						</Button>
					</Row>
				)}
			</Row>
			<Row justify="space-between" style={styles.row}>
				<p className="Key">Sale Price:</p>
				{salePrice && (
					<p style={styles.cost} data-testid="cost">
						{priceFormatter(salePrice, "dollars")}
					</p>
				)}
			</Row>
			<Row justify="space-between" style={{ ...styles.row, marginBottom: editing ? 12 : 0 }}>
				<p className="Key">Coach Fee:</p>
				{costPrice && !editing && (
					<p style={styles.cost} data-testid="price">
						{priceFormatter(costPrice, "dollars")}
					</p>
				)}
				{editing && (
					<InputNumber
						size="large"
						style={{ ...styles.cost, width: 110 }}
						value={costPrice}
						formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						onChange={setCostPrice}
						data-testid="price-edit"
					/>
				)}
			</Row>
			<Divider style={{ margin: 0 }} />
			<Row justify="space-between" style={styles.row}>
				<p className="Key">Profit:</p>
				{costPrice && (
					<p style={styles.profit} data-testid="profit">
						{priceFormatter(profit, "dollars")}
					</p>
				)}
			</Row>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
		height: 30,
	},
	profit: {
		fontSize: 22,
		fontWeight: "bold",
		color: colorsLight("lightBlue"),
		margin: 0,
	},
	cost: {
		fontSize: 18,
		fontWeight: "bold",
		margin: 0,
	},
};

export default ServiceCommercials;
