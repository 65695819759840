import { Row } from "antd";
import React from "react";

const EnvChecker = () => {
	return (
		<>
			{process.env.REACT_APP_PROJECT_ID === "careerbase-development-2610e" && (
				<Row justify="center" align="middle" className="WarningBanner">
					<p style={{ margin: 0, color: "white" }}>
						DEVELOPMENT ENVIRONMENT, CHANGES WON'T REFLECT IN LIVE VERSION
					</p>
				</Row>
			)}
		</>
	);
};

export default EnvChecker;
