import React from "react";
import { useMediaQuery } from "react-responsive";
import { UserProvider } from "./Context/UserContext";
import { DataProvider } from "./Context/DataContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Authentication/login";
import PrivateRoute from "./Components/Navigation/PrivateRoute";
import TeamMembers from "./Pages/Team/TeamMembers";
import CoachesPage from "./Pages/Coaches/Coaches";
import CourseProviders from "./Pages/Courses/CourseProviders";
import MembersPage from "./Pages/Members/Members";
import AccountsPage from "./Pages/AccountsPage/Accounts";
import ManagersPage from "./Pages/Managers/Managers";
import CoachViewPage from "./Pages/Coaches/CoachViewPage/CoachViewPage";
import CoachPackageView from "./Pages/Coaches/CoachViewPage/CoachPackageView/CoachPackageView";
import CourseProviderView from "./Pages/Courses/CourseProviderView/CourseProviderView";
import CourseView from "./Pages/Courses/CourseProviderView/CourseView/CourseView";
import MobileLandingPage from "./Components/MobileLandingPage";
import ServiceProviders from "./Pages/Services/ServiceProviders";
import ServiceProviderView from "./Pages/Services/ServiceProviderView/ServiceProviderView";
import ServiceView from "./Pages/Services/ServiceProviderView/ServiceView/ServiceView";
import TeamMemberView from "./Pages/Team/TeamMemberView/TeamMemberView";
import AccountViewPage from "./Pages/AccountsPage/AccountView/AccountViewPage";
import Browse from "./Pages/Browse/Browse";
import MyAccountPage from "./Pages/MyAccount/MyAccount";
import Activate from "./Pages/Authentication/activate";
import ImageLibraryView from "./Pages/ImageLibrary/ImageLibraryView";
import StoreViewCoachPackage from "./Pages/Browse/ViewCoachingPackage/Store-ViewCoachPackage";
import ViewCoachProfilePage from "./Pages/Browse/ViewCoachProfile/ViewCoachProfilePage";
import StoreCourseProviderPage from "./Pages/Browse/ViewCourseProvider/Store-ViewCourseProvider";
import StoreViewCoursePage from "./Pages/Browse/ViewCourseStorePage/Store-ViewCoursePage";
import StoreViewServiceProviders from "./Pages/Browse/ViewServiceProviders/Store-ViewServiceProviders";
import StoreServiceProviderProfile from "./Pages/Browse/ViewServiceProviderProfile/Store-ServiceProviderProfile";
import PlaylistsPage from "./Pages/Playlists/PlaylistsPage";
import ViewPlaylistPage from "./Pages/Playlists/ViewPlaylist/ViewPlaylistPage";
import ViewMemberPage from "./Pages/Members/ViewMember/ViewMemberPage";
import EnvChecker from "./Components/EnvChecker";
import FulfilmentPage from "./Pages/Fulfilment/FulfilmentPage";
import AdminTools from "./Pages/AdminTools/AdminToolsPage";
import ViewAllProgramsPage from "./Pages/Members/ViewMember/ViewAllPrograms/ViewAllProgramsPage";
import ViewProgramPage from "./Pages/Members/ViewMember/ViewAllPrograms/ViewProgram/ViewProgramPage";

const App = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

	return (
		<>
			{!isMobile && (
				<UserProvider>
					<DataProvider>
						<EnvChecker />
						<Router>
							<Switch>
								<Route path="/Login" component={Login} />

								<PrivateRoute path="/Team">
									<TeamMembers />
								</PrivateRoute>
								<PrivateRoute path="/Team-member/:teamMemberId">
									<TeamMemberView />
								</PrivateRoute>

								<PrivateRoute path="/Browse">
									<Browse />
								</PrivateRoute>

								<PrivateRoute path="/Coaches">
									<CoachesPage />
								</PrivateRoute>

								<PrivateRoute path="/Coach/:coachId">
									<CoachViewPage />
								</PrivateRoute>

								<PrivateRoute path="/Coach-Package/:coachIdANDpackageId">
									<CoachPackageView />
								</PrivateRoute>

								<PrivateRoute path="/Course-Providers">
									<CourseProviders />
								</PrivateRoute>

								<PrivateRoute path="/Course-Provider/:providerId">
									<CourseProviderView />
								</PrivateRoute>

								<PrivateRoute path="/Course-Provider-Courses/:providerId">
									<StoreCourseProviderPage />
								</PrivateRoute>

								<PrivateRoute path="/Course/:providerIdANDcourseId">
									<CourseView />
								</PrivateRoute>

								<PrivateRoute path="/Service-Providers">
									<ServiceProviders />
								</PrivateRoute>

								<PrivateRoute path="/Store-Service-Providers">
									<StoreViewServiceProviders />
								</PrivateRoute>

								<PrivateRoute path="/Store-Service-Provider/:providerId">
									<StoreServiceProviderProfile />
								</PrivateRoute>

								<PrivateRoute path="/Service-Provider/:providerId">
									<ServiceProviderView />
								</PrivateRoute>

								<PrivateRoute path="/Service/:providerIdANDServiceId">
									<ServiceView />
								</PrivateRoute>

								<PrivateRoute path="/Members">
									<MembersPage />
								</PrivateRoute>

								<PrivateRoute path="/Member/:memberId">
									<ViewMemberPage />
								</PrivateRoute>

								<PrivateRoute path="/Accounts">
									<AccountsPage />
								</PrivateRoute>

								<PrivateRoute path="/Account/:accountId">
									<AccountViewPage />
								</PrivateRoute>

								<PrivateRoute path="/Managers">
									<ManagersPage />
								</PrivateRoute>

								<PrivateRoute path="/My-Account">
									<MyAccountPage />
								</PrivateRoute>

								<PrivateRoute path="/Image-Library">
									<ImageLibraryView />
								</PrivateRoute>

								<PrivateRoute path="/Playlists">
									<PlaylistsPage />
								</PrivateRoute>

								<PrivateRoute path="/View-Playlist/:playlistId">
									<ViewPlaylistPage />
								</PrivateRoute>

								<PrivateRoute path="/Store-Package-View/:packageIdANDref">
									<StoreViewCoachPackage />
								</PrivateRoute>

								<PrivateRoute path="/Coach-Profile/:coachId">
									<ViewCoachProfilePage />
								</PrivateRoute>

								<PrivateRoute path="/Store-Course-View/:providerIdANDcourseId">
									<StoreViewCoursePage />
								</PrivateRoute>

								<PrivateRoute path="/Fulfilment/:programId">
									<FulfilmentPage />
								</PrivateRoute>

								<PrivateRoute path="/ViewPrograms/:memberId">
									<ViewAllProgramsPage />
								</PrivateRoute>

								<PrivateRoute path="/ViewProgram/:programId">
									<ViewProgramPage />
								</PrivateRoute>

								<Route path="/activate/:params" component={Activate} />

								<PrivateRoute path="/Home">
									<Home />
								</PrivateRoute>

								<PrivateRoute path="/AdminTools">
									<AdminTools />
								</PrivateRoute>

								<PrivateRoute path="/">
									<Home />
								</PrivateRoute>
							</Switch>
						</Router>
					</DataProvider>
				</UserProvider>
			)}
			{isMobile && <MobileLandingPage />}
		</>
	);
};

export default App;
