import { Row, Col, Typography, Button, Modal, Input, message } from "antd";
import { firestore } from "firebase";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getSalePrice } from "../../../../../Functions/helpers";
const { Title } = Typography;

const CourseViewQuickActions = ({ data, markup, providerData }) => {
	const [modal, setModal] = useState(false);
	const [delPhrase, setDelPhrase] = useState("");
	const [loading, setLoading] = useState(false);
	const [loading1, setLoading1] = useState(false);
	const history = useHistory();

	// const openNotificationWithIcon = (type, title, message) => {
	// 	notification[type]({
	// 		message: title,
	// 		description: message,
	// 	});
	// };

	const deleteCourse = () => {
		if (delPhrase === "DELETE-COURSE") {
			firestore()
				.collection("CourseProviders")
				.doc(data.owner)
				.collection("Courses")
				.doc(data.key)
				.delete()
				.then(() => {
					history.goBack();
					message.success("Course successfully deleted. Changes may take a few minutes to reflect in store.");
				});
		} else {
			message.error("Please type 'DELETE-COURSE' to delete this course.");
		}
	};

	const toggleStatus = () => {
		setLoading(true);
		if (data.status === "active") {
			firestore()
				.collection("CourseProviders")
				.doc(providerData.key)
				.collection("Courses")
				.doc(data.key)
				.update({ status: "inactive" })
				.then(() => {
					return firestore().collection("Courses").doc(data.key).delete();
				})
				.then(() => {
					setLoading(false);
					message.success("Course disabled from store");
				})
				.catch((err) => {
					message.error("Could not disable course, check log for more info");
					console.log(err);
				});
		} else {
			firestore()
				.collection("CourseProviders")
				.doc(providerData.key)
				.collection("Courses")
				.doc(data.key)
				.update({ status: "active" })
				.then(() => {
					//GETS THE PROPER SALE PRICE
					const salePrice = getSalePrice(data.costPrice, markup, "none");
					let storeListingObj = {};

					//CREATES A STORE LISTING OBJ
					let originalKeys = Object.keys(data);
					originalKeys.forEach((key) => {
						if (key !== "costPrice" && key !== "status") {
							storeListingObj = { ...storeListingObj, [key]: data[key] };
						}
					});
					storeListingObj = { ...storeListingObj, price: salePrice };
					return firestore().collection("Courses").doc(data.key).set(storeListingObj);
				})
				.then(() => {
					message.success("Store listing enabled");
					setLoading(false);
				})
				.catch((err) => {
					message.error("Could not enable listing, check log for more info");
					console.log(err);
				});
		}
	};

	const storeSync = () => {
		if (data.status === "active") {
			setLoading1(true);
			//GETS THE PROPER SALE PRICE
			const salePrice = getSalePrice(data.costPrice, markup, "none");
			let storeListingObj = {};

			//CREATES A STORE LISTING OBJ
			let originalKeys = Object.keys(data);
			originalKeys.forEach((key) => {
				if (key !== "costPrice" && key !== "status") {
					storeListingObj = { ...storeListingObj, [key]: data[key] };
				}
			});
			storeListingObj = { ...storeListingObj, price: salePrice };
			firestore()
				.collection("Courses")
				.doc(data.key)
				.set(storeListingObj)
				.then(() => {
					setLoading1(false);
					message.success("Course successfully synced with store");
				})
				.catch((err) => {
					setLoading1(false);
					message.error("Could not sync with store, check log for more info.");
					console.log(err);
				});
		} else {
			message.error("This Course is not active in the store. Please activate before syncing");
		}
	};

	const toggleModal = () => setModal(!modal);

	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Quick Actions</Title>
			</Row>
			<Row gutter={12} style={styles.row}>
				<Col span={12}>
					<Button loading={loading} style={styles.button} onClick={toggleStatus}>
						{data.status === "active" ? "Disable Listing" : "Enable Listing"}
					</Button>
				</Col>
				<Col span={12}>
					<Button onClick={toggleModal} danger style={styles.button} data-testid="delete">
						Delete Course
					</Button>
				</Col>
			</Row>
			<Row gutter={12} style={{ ...styles.row, paddingTop: 12 }}>
				<Col span={12}>
					<Button onClick={storeSync} loading={loading1} style={styles.button}>
						Sync store listing
					</Button>
				</Col>
				<Col span={12}></Col>
			</Row>
			<Modal
				title="Confirm Deletion"
				visible={modal}
				onCancel={toggleModal}
				footer={
					<Row>
						<Button onClick={toggleModal} data-testid="close-modal">
							Cancel
						</Button>
						<Button disabled={delPhrase !== "DELETE-COURSE"} onClick={deleteCourse} type="primary">
							Delete
						</Button>
					</Row>
				}>
				<Col span={24}>
					<Row>
						<p>Are you sure you want to delete this provider? This cannot be undone.</p>
					</Row>
					<Row>
						<p>To confirm, please type DELETE-COURSE to confirm.</p>
					</Row>
					<Input
						value={delPhrase}
						onChange={(val) => setDelPhrase(val.target.value)}
						placeholder="DELETE-COURSE"
						data-testid="del-phrase"
					/>
				</Col>
			</Modal>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default CourseViewQuickActions;
