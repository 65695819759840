import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import firebase from "../../API/firebase";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import MenuMain from "../../Components/MainMenu";
import { colorsLight } from "../../Styles/Themes";
import AddMemberModal from "./Components/AddMemberModal";
import MembersTable from "./Components/MembersTable";

const { Header, Content } = Layout;
const { Title } = Typography;

const MembersPage = () => {
	const [addModal, setAddModal] = useState(false);
	const [users, setUsers] = useState([]);

	const dismissModal = () => setAddModal(false);

	useEffect(() => {
		let listener = firebase
			.firestore()
			.collection("Members")
			.onSnapshot((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setUsers(list);
			});
		return listener;
	}, []);

	return (
		<Layout className="App">
			<MenuMain active="6" />
			<Layout>
				<Header className="Header">
					<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row justify="space-between">
							<Title data-testid="members-title">Members</Title>
							<Button
								onClick={() => setAddModal(!addModal)}
								type="primary"
								style={{ marginTop: 12 }}
								data-testid="members-button"
							>
								<PlusOutlined /> New Engagement
							</Button>
						</Row>

						<Row style={styles.row}>
							<Col span={24} data-testid="members-table">
								<MembersTable data={users} />
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
			<AddMemberModal visible={addModal} dismiss={dismissModal} />
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 22,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default MembersPage;
