import { Row, Col, Typography, Tag, Table, Button } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Redirect } from "react-router-dom";
import AddService from "./AddService";
import { priceFormatter, statusTagFormatter } from "../../../../Functions/formatters";
import { getProfit } from "../../../../Functions/helpers";
const { Title } = Typography;

const ServiceProviderServiceList = ({ data, markup, providerId, providerData }) => {
	const [modal, setModal] = useState(false);
	const [navRef, setNavRef] = useState("");
	const [nav, setNav] = useState(false);

	const dismiss = () => setModal(false);

	useEffect(() => {
		navRef && setNav(true);
	}, [navRef]);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Cost Price",
			dataIndex: "costPrice",
			key: "costPrice",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="service-price">
					{priceFormatter(text, "dollars")}
				</p>
			),
		},
		{
			title: "Profit",
			dataIndex: "costPrice",
			key: "costPrice",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="service-profit">
					{getProfit(text, markup)}
				</p>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => (
				<Tag color={statusTagFormatter(text)} data-testid="service-status">
					{text.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Type",
			dataIndex: "ServiceType",
			key: "ServiceType",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="service-type">
					{text}
				</p>
			),
		},
		{
			title: "Action",
			render: (record) => (
				<Button
					size="small"
					onClick={() => setNavRef(`/Service/${providerId}&${record.key}`)}
					data-testid="service-view"
				>
					View
				</Button>
			),
		},
	];

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Title level={4}>Services</Title>
				<Button onClick={() => setModal(!modal)} data-testid="add-service">
					<PlusOutlined />
					Add Service
				</Button>
			</Row>
			<Row style={{ paddingTop: 6 }} data-testid="service-list">
				<Table style={{ width: "100%" }} columns={columns} dataSource={data} />
			</Row>

			<AddService
				visible={modal}
				dismiss={dismiss}
				providerId={providerId}
				data={providerData}
			/>
			{nav && <Redirect to={navRef} />}
		</Col>
	);
};

export default ServiceProviderServiceList;
