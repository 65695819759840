import { Row, Col, Typography, Tag, Table, Button } from "antd";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import AddCourse from "./AddCourse";
import { priceFormatter, statusTagFormatter } from "../../../../Functions/formatters";
import { getProfit } from "../../../../Functions/helpers";
const { Title } = Typography;

const CourseProviderCourseList = ({ data, markup, providerId, providerData }) => {
	const [modal, setModal] = useState(false);

	const dismiss = () => setModal(false);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Cost Price",
			dataIndex: "costPrice",
			key: "costPrice",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="course-price">
					{text ? priceFormatter(text, "dollars") : "0"}
				</p>
			),
		},
		{
			title: "Profit",
			dataIndex: "costPrice",
			key: "costPrice",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="course-profit">
					{getProfit(text, markup)}
				</p>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => (
				<Tag color={statusTagFormatter(text)} data-testid="course-status">
					{text.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Type",
			dataIndex: "courseType",
			key: "courseType",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="course-type">
					{text}
				</p>
			),
		},
		{
			title: "Action",
			render: (record) => (
				<Link to={`/Course/${providerId}&${record.key}`}>
					<Button size="small" data-testid="course-view">
						View
					</Button>
				</Link>
			),
		},
	];

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Title level={4}>Courses</Title>
				<Button onClick={() => setModal(!modal)} data-testid="add-course">
					<PlusOutlined />
					Add Course
				</Button>
			</Row>
			<Row style={{ paddingTop: 6 }} data-testid="course-list">
				<Table style={{ width: "100%" }} columns={columns} dataSource={data} />
			</Row>

			<AddCourse
				visible={modal}
				dismiss={dismiss}
				providerId={providerId}
				data={providerData}
			/>
		</Col>
	);
};

export default CourseProviderCourseList;
