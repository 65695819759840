import { Col, Layout, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import BackButton from "../../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../../Styles/Themes";
import firebase from "../../../API/firebase";
import CoachProfileHeader from "./Components/CoachProfileHeader";
const { Header, Content } = Layout;

const ViewCoachProfilePage = () => {
	const [coachData, setCoachData] = useState({});
	const { coachId } = useParams();
	const history = useHistory();

	useEffect(() => {
		const listener = async () =>
			await firebase
				.firestore()
				.collection("Coaches")
				.doc(coachId)
				.get()
				.then((doc) => {
					doc.exists && setCoachData({ ...doc.data(), key: doc.id });
				})
				.catch((err) => {
					message.error(err);
				});

		listener();
	}, [coachId]);

	return (
		<Layout className="App">
			<MenuMain active="2" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							padding: 24,
						}}
					>
						<Col span={24} style={{ maxWidth: maxContentWidth }}>
							<Row>
								<Col span={16}>
									<CoachProfileHeader data={coachData} />
								</Col>
								<Col span={8}></Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default ViewCoachProfilePage;
