import { Avatar, Badge, Button, Col, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { coachTagFormatter, getInitials, priceFormatter } from "../../../Functions/formatters";
import { getImage } from "../../../Functions/utilities";
import { colorsLight } from "../../../Styles/Themes";

const { Paragraph } = Typography;

const CoachCard = ({ data = {}, loading = false, buttonLabel, buttonLink, index }) => {
	const hideBadge = window.location.pathname.split("/")[1].toLocaleLowerCase().includes("view");

	let namCount = data.name ? data.name.length : 0;

	const styles = {
		wrapper: {
			width: 494,
			height: 296,
			borderRadius: 9,
			backgroundColor: colorsLight("white"),
			margin: "0px 26px 36px 10px",
		},
		headerImage: {
			backgroundImage: `url(${data.coverPic ? data.coverPic : getImage()})`,
			backgroundSize: "cover",
			height: 117,
			width: 494,
			borderRadius: "9px 9px 0px 0px",
		},
		name: {
			fontSize: 18,
			color: colorsLight("white"),
			fontWeight: "bold",
			lineHeight: 1.3,
			marginTop: 20,
		},
		avatar: {
			padding: 4,
			backgroundColor: "white",
			boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
			borderRadius: 62,
			marginTop: 0,
			maxWidth: 88,
		},
		imageOverlay: {
			backgroundColor: loading ? "rgb(216 216 216)" : "rgba(2, 48, 71,0.7)",
			height: "100%",
			width: 494,
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: "0px 20px",
		},
		label: {
			fontSize: 13,
			color: "#565656",
		},
		text: {
			fontWeight: 600,
			fontSize: 14,
			color: "#000000",
		},
		pts: {
			fontWeight: 600,
			fontSize: 18,
			color: colorsLight("lightBlue"),
		},
		row: {
			width: "100%",
			padding: "6px 20px",
		},
		summary: {
			marginBottom: 0,
			minHeight: 65,
		},
		button: {
			width: "100%",
			padding: "0px 10px",
		},
	};

	return (
		<Row key={index} style={styles.wrapper} data-testid="coach-card">
			<Badge.Ribbon
				style={{ display: hideBadge ? "none" : "block" }}
				color={coachTagFormatter(data.primaryCategory)}
				placement="start"
				text={
					<span data-testid="package-tag">
						{data.primaryCategory ? data.primaryCategory.toUpperCase() : "LOADING"}
					</span>
				}
			>
				<Row style={styles.headerImage} data-testid="package-header">
					<Row
						justify="space-between"
						align="middle"
						style={styles.imageOverlay}
						data-testid="package-overlay"
					>
						<Col style={{ paddingTop: namCount > 28 ? 36 : 42 }} span={17}>
							<p style={{ margin: 0, color: "white" }}>{data.coachName}</p>

							<Typography.Title
								style={{ fontSize: 18, color: "white" }}
								ellipsis={{
									rows: 2,
									expandable: false,
								}}
								data-testid="package-name"
								level={3}
							>
								{data.name}
							</Typography.Title>
						</Col>
						<Col span={7} style={styles.avatar} data-testid="package-coach">
							<Avatar src={data.displayPic} size={80}>
								{getInitials(data.name || "")}
							</Avatar>
						</Col>
					</Row>
				</Row>
				<Row justify="space-between" style={styles.row}>
					<Col span={8}>
						<div style={styles.label}>Delivery:</div>
						<div style={styles.text} data-testid="package-delivery">
							{data.delivery}
						</div>
					</Col>
					<Col span={8}>
						<div style={styles.label}>Length:</div>
						<div style={styles.text} data-testid="package-sessions">
							{data.sessions} Sessions
						</div>
					</Col>
					<Col span={8}>
						<div style={styles.label}>Price:</div>
						<Row style={{ width: 250 }}>
							<div style={styles.pts} data-testid="package-price">
								{priceFormatter(data.price, "points")} pts
							</div>
							<p
								style={{
									margin: 0,
									marginLeft: 3,
									fontSize: 12,
									marginTop: 6,
									color: colorsLight("grey"),
								}}
							>
								({priceFormatter(data.price, "dollars")})
							</p>
						</Row>
					</Col>
				</Row>
				<Row style={styles.row}>
					<Paragraph
						style={styles.summary}
						ellipsis={{
							rows: 3,
							expandable: false,
							symbol: "...",
							suffix: <a href={`${buttonLink}`}>Read on</a>,
						}}
						data-testid="package-summary"
					>
						{data.summary}
					</Paragraph>
				</Row>
				<Row style={styles.button} justify="end">
					<Button>
						<Link to={`${buttonLink}`} data-testid="package-button">
							{buttonLabel}
						</Link>
					</Button>
				</Row>
			</Badge.Ribbon>
		</Row>
	);
};

export default CoachCard;
