import { Col, Row, Typography, Button } from "antd";
import React, { useState } from "react";
import { colorsLight } from "../../../../Styles/Themes";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import AddToPlaylistModal from "../../../Playlists/Components/AddToPlaylistModal";

const StoreCourseViewHeader = ({ data = {} }) => {
	const [modal, setModal] = useState(false);
	const styles = {
		imageHeader: {
			backgroundImage: `url(${data.coverPic})`,
			backgroundSize: "cover",
			width: "100%",
			height: 150,
			backgroundPosition: "center",
		},
		imageCover: {
			backgroundColor: colorsLight("cardCover"),
			height: "100%",
			width: "100%",
			padding: 24,
		},
	};

	const toggleModal = () => setModal(!modal);

	return (
		<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
			<Row style={styles.imageHeader}>
				<Row justify="space-between" align="bottom" style={styles.imageCover}>
					<Typography.Title
						level={2}
						style={{ color: "white", margin: 0, marginBottom: 0 }}
						data-testid="course-name"
					>
						{data.name}
					</Typography.Title>
					<Button
						onClick={toggleModal}
						icon={<PlusOutlined />}
						style={{ position: "absolute", top: 12, right: 12 }}
					>
						Add to playlist
					</Button>
				</Row>
			</Row>
			{data.availability === "dates" && (
				<>
					{data.dates.map((item, index) => (
						<Row
							key={index}
							gutter={16}
							style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 24 }}
						>
							<Col span={12}>
								<Typography.Title level={3}>When</Typography.Title>
								<p
									style={{ fontSize: 18, fontWeight: 300 }}
									data-testid="course-dateStart"
								>{`${moment(item.startTime).format("Do MMMM YYYY")} - ${moment(
									item.endTime
								).format("Do MMMM YYYY")}`}</p>
							</Col>
							<Col span={12}>
								<Typography.Title level={3}>Where</Typography.Title>
								<p
									style={{ fontSize: 18, fontWeight: 300 }}
									data-testid="course-dateWhere"
								>
									{item.details}
								</p>
							</Col>
						</Row>
					))}
				</>
			)}
			<Row
				style={{
					paddingLeft: 24,
					paddingRight: 24,
					paddingTop: data.availability === "dates" ? 0 : 16,
				}}
			>
				<Col span={24}>
					<Typography.Title level={3}>Summary</Typography.Title>
					<Typography.Paragraph data-testid="course-summary">
						{data.summary}
					</Typography.Paragraph>
				</Col>
			</Row>
			<Row style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 24 }}>
				<Col span={24}>
					<div
						dangerouslySetInnerHTML={{ __html: data.details }}
						data-testid="course-details"
					/>
				</Col>
			</Row>
			<AddToPlaylistModal visible={modal} dismiss={toggleModal} type="Courses" data={data} />
		</Col>
	);
};

export default StoreCourseViewHeader;
