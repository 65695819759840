import { LoadingOutlined } from "@ant-design/icons";
import { createAuthButton } from "@kloudless/authenticator/react";
import {
	Badge,
	Button,
	Col,
	Divider,
	Form,
	Input,
	Layout,
	message,
	Row,
	Spin,
	Typography,
	Avatar,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../API/firebase";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import MenuMain from "../../Components/MainMenu";
import BackButton from "../../Components/Navigation/BackButton";
import ProfilePicUploadModal from "../../Components/ProfilePicUploadModal";
import UserContext from "../../Context/UserContext";
import { getInitials } from "../../Functions/formatters";
import { colorsLight } from "../../Styles/Themes";

const { Header, Content } = Layout;

const AuthButton = (props) => (
	<Button data-testid="sync-button" onClick={props.onClick}>
		Connect Account
	</Button>
);

const MyAccountPage = () => {
	const { uid, userInfo } = useContext(UserContext);
	const [, setloading] = useState(false);
	const [editing, setEditing] = useState(false);
	const [editPic, setEditPic] = useState(false);
	const [hover, setHover] = useState(false);
	const [title, settitle] = useState("");
	// const timeString = moment(new Date()).format();
	// const endTime = moment(timeString).add(1, "year").format();
	let extraData = JSON.stringify({ uid: `${uid}` });
	const CustomAuthButton = createAuthButton(AuthButton);
	const history = useHistory();

	const updateSyncStatus = (result) => {
		firebase
			.firestore()
			.collection("Admins")
			.doc(uid)
			.update({
				kloudlessId: result.account.id,
				calendarSync: true,
				calendarService: result.account.service_name,
			})
			.then(() => {
				message.success("Successfully synced your calendar!");
			})
			.catch((err) => {
				message.error(
					"We've linked your account, however we couldn't sync it. try again later."
				);
			});
	};

	useEffect(() => {
		settitle(userInfo.title);
	}, [userInfo]);

	const updateInfo = (val) => {
		setloading(true);
		firebase
			.firestore()
			.collection("Admins")
			.doc(uid)
			.update({ ...val, title, displayName: `${val.firstName} ${val.lastName}` })
			.then(() => {
				setloading(false);
				toggleEdit();
				message.success("Successfully updated your info");
			})
			.catch((err) => {
				setloading(false);
				console.log(err);
				message.error("Something went wrong");
			});
	};

	const toggleEdit = () => setEditing(!editing);
	const toggleEdit1 = () => setEditPic(!editPic);

	const loadingIcon = <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />;

	return (
		<Layout className="App">
			<MenuMain />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row>
							<Col style={{ minWidth: 750 }} className="Card">
								<Row justify="space-between">
									<Col>
										<Row>
											<Avatar
												src={userInfo.displayPic}
												size={80}
												data-testid="avatar"
											>
												{userInfo.displayName ? (
													getInitials(userInfo.displayName)
												) : (
													<Spin indicator={loadingIcon} />
												)}
											</Avatar>
											{editing && (
												<Row
													justify="center"
													align="middle"
													onClick={toggleEdit1}
													onMouseEnter={() => setHover(true)}
													onMouseLeave={() => setHover(false)}
													style={{
														height: 80,
														width: 80,
														borderRadius: 40,
														backgroundColor: hover
															? "rgba(0,0,0,0.5)"
															: "rgba(0,0,0,0)",
														position: "absolute",
														cursor: "pointer",
													}}
													data-testid="edit-photo"
												>
													{hover && (
														<p style={{ color: "white", margin: 0 }}>
															Edit Photo
														</p>
													)}
												</Row>
											)}
											<ProfilePicUploadModal
												visible={editPic}
												dismiss={toggleEdit1}
												userType="Admins"
												data={userInfo}
											/>
											<Col style={{ marginLeft: 12, paddingTop: 6 }}>
												<Typography.Title
													style={{ margin: 0 }}
													level={2}
													data-testid="name"
												>
													{userInfo.displayName}
												</Typography.Title>
												{!editing ? (
													<p
														style={{
															margin: 0,
															fontSize: 16,
															fontWeight: 300,
														}}
														data-testid="title"
													>
														{userInfo.title}
													</p>
												) : (
													<Input
														style={{
															margin: 0,
															fontSize: 16,
															fontWeight: 300,
														}}
														value={title}
														onChange={(val) =>
															settitle(val.target.value)
														}
														data-testid="title"
													/>
												)}
											</Col>
										</Row>
									</Col>
									{!editing ? (
										<Button
											onClick={toggleEdit}
											size="small"
											data-testid="edit"
										>
											Edit Info
										</Button>
									) : (
										<Row>
											<Button
												style={{ marginRight: 12 }}
												onClick={toggleEdit}
												size="small"
												data-testid="cancel"
											>
												Cancel
											</Button>
											<Button
												type="primary"
												form="editInfo"
												htmlType="submit"
												key="submit"
												size="small"
												data-testid="update"
											>
												Update Info
											</Button>
										</Row>
									)}
								</Row>
								<Row style={{ paddingLeft: 6, paddingRight: 6 }}>
									<Divider style={{ marginTop: 12, marginBottom: 12 }} />
								</Row>
								<Row>
									<Typography.Title level={4}>Basic Information</Typography.Title>
								</Row>

								{!editing ? (
									<Row>
										<Col
											style={{ paddingLeft: 12, paddingRight: 12 }}
											span={12}
										>
											<Row justify="space-between">
												<p className="Key">First Name</p>
												<p className="Value" data-testid="fname">
													{userInfo.firstName || "Loading"}
												</p>
											</Row>
											<Row justify="space-between">
												<p className="Key">Last Name</p>
												<p className="Value" data-testid="lname">
													{userInfo.lastName || "Loading"}
												</p>
											</Row>
											<Row justify="space-between">
												<p className="Key">Email</p>
												<p className="Value" data-testid="email">
													{userInfo.email || "Loading"}
												</p>
											</Row>
										</Col>
										<Col
											style={{ paddingLeft: 12, paddingRight: 12 }}
											span={12}
										>
											<Row justify="space-between">
												<p className="Key">Last Online</p>
												<p className="Value" data-testid="lastSignIn">
													{userInfo.lastSignIn
														? moment(userInfo.lastSignIn).fromNow()
														: "Loading"}
												</p>
											</Row>
										</Col>
									</Row>
								) : (
									<Form
										id="editInfo"
										initialValues={userInfo}
										onFinish={updateInfo}
									>
										<Row>
											<Col
												style={{ paddingLeft: 12, paddingRight: 12 }}
												span={12}
											>
												<Row justify="space-between">
													<p className="Key">First Name</p>
													<Form.Item
														required={true}
														style={{ margin: 0, marginBottom: 6 }}
														name="firstName"
													>
														<Input
															style={{ textAlign: "end" }}
															placeholder="Please enter first name"
															data-testid="fname"
														/>
													</Form.Item>
												</Row>
												<Row justify="space-between">
													<p className="Key">Last Name</p>
													<Form.Item
														required={true}
														style={{ margin: 0, marginBottom: 6 }}
														name="lastName"
													>
														<Input
															style={{ textAlign: "end" }}
															placeholder="Please enter last name"
															data-testid="lname"
														/>
													</Form.Item>
												</Row>
												<Row justify="space-between">
													<p className="Key">Email</p>
													<Form.Item
														required={true}
														style={{ margin: 0, marginBottom: 6 }}
														name="email"
													>
														<Input
															style={{ textAlign: "end" }}
															placeholder="Please enter email"
															data-testid="email"
														/>
													</Form.Item>
												</Row>
											</Col>
											<Col
												style={{ paddingLeft: 12, paddingRight: 12 }}
												span={12}
											>
												<Row justify="space-between">
													<p className="Key">Last Online</p>
													<p className="Value" data-testid="lastSignIn">
														{userInfo.lastSignIn
															? moment(userInfo.lastSignIn).fromNow()
															: "Loading"}
													</p>
												</Row>
											</Col>
										</Row>
									</Form>
								)}
								<Row>
									<Typography.Title level={4}>Integrations</Typography.Title>
								</Row>

								<Badge.Ribbon
									color={userInfo.calendarSync ? colorsLight("green") : "red"}
									text={userInfo.calendarSync ? "ACTIVE" : "INACTIVE"}
									placement="start"
								>
									<Row
										style={{
											borderWidth: 1,
											borderRadius: 9,
											borderStyle: "solid",
											borderColor: colorsLight("lightgrey"),
											padding: 12,
											backgroundColor: "white",
											boxShadow: "1px 2px 2px rgba(0,0,0,0.2)",
										}}
										justify="space-between"
									>
										<Col style={{ paddingTop: 24 }}>
											<p style={{ fontSize: 22, fontWeight: 300, margin: 0 }}>
												Calendar Availability Sync
											</p>
											<Row>
												<p style={{ margin: 0, marginRight: 3 }}>
													Works with:
												</p>
												<p style={{ margin: 0, fontWeight: 600 }}>
													Google, Outlook, iCloud, Exchange Server &
													CalDAV
												</p>
											</Row>
										</Col>
										<Row align="bottom">
											<CustomAuthButton
												options={{
													client_id:
														"udgTFUF07bdTYfIVp228VrhBbSdq26W_ezYi5k5SwlZo7MWh",
													scope: "any.calendar",
													custom_properties: extraData,
												}}
												onSuccess={(result) => {
													updateSyncStatus(result);
												}}
												onError={(error) => {
													message.error(
														"Couldn't link your account, please try again."
													);
												}}
											/>
										</Row>
									</Row>
								</Badge.Ribbon>
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 24,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default MyAccountPage;
