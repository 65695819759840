import { Col, Layout, message, Row, Typography, List } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import BackButton from "../../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../../Styles/Themes";
import firebase from "../../../API/firebase";
import StoreServiceProviderProfileHeader from "./Components/StoreServiceProviderProfileHeader";
import SPProfilePackageCard from "./Components/Store-SP-Profile-Package";

const { Header, Content } = Layout;

const StoreServiceProviderProfile = () => {
	const [data, setData] = useState({});
	const [packages, setPackages] = useState([]);
	const { providerId } = useParams();
	const history = useHistory();

	useEffect(() => {
		firebase
			.firestore()
			.collection("ServiceProviders")
			.doc(providerId)
			.get()
			.then((doc) => doc.exists && setData({ ...doc.data(), key: doc.id }))
			.catch((err) => message.error(err));

		firebase
			.firestore()
			.collection("Services")
			.where("owner", "==", providerId)
			.get()
			.then((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setPackages(list);
			})
			.catch((err) => message.error(err));
	}, [providerId]);

	return (
		<Layout className="App">
			<MenuMain active="2" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							padding: 24,
						}}
					>
						<Col span={24} style={{ maxWidth: maxContentWidth }}>
							<Row>
								<Col span={16}>
									<StoreServiceProviderProfileHeader data={data} />
									<Typography.Title level={3}>
										Available Services
									</Typography.Title>
									<List
										dataSource={packages}
										renderItem={(item) => <SPProfilePackageCard data={item} />}
									/>
								</Col>
								<Col span={8}></Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default StoreServiceProviderProfile;
