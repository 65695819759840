import { Avatar, Button, Col, Row, Table, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { statusTagFormatter } from "../../../Functions/formatters";

const ServiceProvidersTable = ({ data }) => {
	const [navRef, setNavRef] = useState("");

	const columns = [
		{
			title: "Logo",
			dataIndex: "displayPic",
			key: "displayPic",
			render: (text, record) => <Avatar size={40} src={text} data-testid="service-avatar" />,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Website",
			dataIndex: "websiteUrl",
			key: "websiteUrl",
			render: (text) => (
				<a
					href={text}
					target="_blank"
					rel="noopener noreferrer"
					style={{ margin: 0 }}
					data-testid="service-url"
				>
					{text}
				</a>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => (
				<Tag color={statusTagFormatter(text)} data-testid="service-status">
					{text.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Last Sign-in",
			dataIndex: "lastSignIn",
			key: "lastSignIn",
			render: (text) => (
				<p style={{ margin: 0 }} data-testid="service-lastSignIn">
					{text !== "never" ? moment(text).fromNow() : "never"}{" "}
				</p>
			),
		},
		{
			title: "Action",
			render: (record) => (
				<Button
					onClick={() => setNavRef(`/Service-Provider/${record.key}`)}
					data-testid="service-view"
				>
					View
				</Button>
			),
		},
	];

	return (
		<Col className="Card" style={styles.card}>
			<Row style={styles.row}>
				<Table style={styles.table} columns={columns} dataSource={data} />
			</Row>
			{navRef && <Redirect to={navRef} />}
		</Col>
	);
};

const styles = {
	card: {},
	row: {
		width: "100%",
	},
	table: {
		width: "100%",
	},
};

export default ServiceProvidersTable;
