import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import moment from "moment";

const ScheduleItem = (input) => {
	const item = input.input;
	return (
		<Col style={{ marginBottom: 24 }}>
			<Row>
				<p style={{ margin: 0 }}>
					{item.startTime !== "TBD"
						? moment(item.startTime).format("Do MMMM @ h:mma")
						: "Not yet scheduled"}
				</p>
			</Row>
			<Row>
				<Typography.Title style={{ margin: 0 }} level={5}>
					{item.memberTitle || item.name}
				</Typography.Title>
			</Row>
		</Col>
	);
};

const ViewProgramSchedule = ({ timeline }) => {
	return (
		<Col className="Card" style={{ padding: 0 }}>
			<Row style={{ padding: 12 }}>
				<Typography.Title level={3}>Program Schedule</Typography.Title>
			</Row>
			<Divider style={{ margin: 0 }} />
			<Col style={{ padding: 12 }}>
				{timeline &&
					timeline.map((item, index) => <ScheduleItem input={item} key={index} />)}
			</Col>
		</Col>
	);
};

export default ViewProgramSchedule;
