import { Row, Col, Typography, Button, Popconfirm, message } from "antd";
import { firestore } from "firebase";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { getSalePrice } from "../../../../../Functions/helpers";
const { Title } = Typography;

const CoachPackageViewQuickActions = ({ data = {}, toggleEditing, markup, coachData }) => {
	const [loading, setloading] = useState(false);
	const [nav, setNav] = useState(false);
	const [link, setLink] = useState("");
	const [syncLoading, setSyncLoading] = useState(false);
	const [statLoading, setStatLoading] = useState(false);

	const deletePackage = async () => {
		setloading(true);
		firestore()
			.collection("Coaches")
			.doc(data.owner)
			.collection("Packages")
			.doc(data.key)
			.delete()
			.then(() => {
				return firestore().collection("Packages").doc(data.key).delete();
			})
			.then(() => {
				setloading(false);
				setLink(`/Coach/${data.owner}`);
				setNav(true);
			})
			.catch(() => {
				setloading(false);
				message.error("Could not delete package");
			});
	};

	const storeSync = () => {
		setSyncLoading(true);
		const salePrice = getSalePrice(data.costPrice, markup, "none");

		let storeListingObj = {};
		let originalKeys = Object.keys(data);
		originalKeys.forEach((key) => {
			if (key !== "costPrice" || key !== "status") {
				storeListingObj = { ...storeListingObj, [key]: data[key] };
			}
		});
		storeListingObj = {
			...storeListingObj,
			price: salePrice,
			coachName: coachData.displayName,
		};
		console.log(storeListingObj);
		firestore()
			.collection("Packages")
			.doc(data.key)
			.update(storeListingObj)
			.then(() => {
				setSyncLoading(false);
				message.success("Successfully sync listing with store");
			})
			.catch((err) => {
				message.error("Could not sync with store, check console for details");
				console.log(err);
				setSyncLoading(false);
			});
	};

	const toggleStatus = () => {
		setStatLoading(true);
		if (data.status === "active") {
			firestore()
				.collection("Coaches")
				.doc(coachData.key)
				.collection("Packages")
				.doc(data.key)
				.update({ status: "inactive" })
				.then(() => {
					return firestore().collection("Packages").doc(data.key).delete();
				})
				.then(() => {
					message.success("Store lising disabled");
					setStatLoading(false);
				})
				.catch((err) => {
					message.error("Could not disable listing");
					console.log(err);
					setStatLoading(false);
				});
		} else {
			firestore()
				.collection("Coaches")
				.doc(coachData.key)
				.collection("Packages")
				.doc(data.key)
				.update({ status: "active" })
				.then(() => {
					const salePrice = getSalePrice(data.costPrice, markup, "none");

					let storeListingObj = {};
					let originalKeys = Object.keys(data);
					originalKeys.forEach((key) => {
						if (key !== "costPrice" || key !== "status") {
							storeListingObj = { ...storeListingObj, [key]: data[key] };
						}
					});
					storeListingObj = {
						...storeListingObj,
						price: salePrice,
						coachName: coachData.displayName,
					};
					return firestore().collection("Packages").doc(data.key).set(storeListingObj);
				})
				.then(() => {
					message.success("Store lising enabled");
					setStatLoading(false);
				})
				.catch((err) => {
					message.error("Could not enable listing");
					console.log(err);
				});
		}
	};

	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Quick Actions</Title>
			</Row>
			<Row gutter={12} style={styles.row}>
				<Col style={{ paddingRight: 6 }} span={12}>
					<Button
						loading={loading}
						onClick={toggleEditing}
						style={styles.button}
						data-testid="edit"
					>
						Edit Package
					</Button>
				</Col>
				<Col span={12}>
					<Popconfirm
						okText="Yep"
						cancelText="Cancel"
						placement="topRight"
						title="Are you sure you want to delete this package?"
						onConfirm={deletePackage}
					>
						<Button loading={loading} danger style={styles.button} data-testid="delete">
							Delete Package
						</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Row gutter={12} style={{ width: "100%", paddingTop: 12 }}>
				<Col span={12}>
					<Button loading={syncLoading} style={styles.button} onClick={storeSync}>
						Sync with store
					</Button>
				</Col>
				<Col span={12}>
					<Button loading={statLoading} style={styles.button} onClick={toggleStatus}>
						{data.status === "active" ? "Disable Listing" : "Enable Listing"}
					</Button>
				</Col>
			</Row>
			{nav && <Redirect to={link} />}
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default CoachPackageViewQuickActions;
