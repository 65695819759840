import { Button, Col, message, notification, Popconfirm, Row, Typography } from "antd";
import { app, auth } from "firebase";
import React, { useState } from "react";
const { Title } = Typography;

const CoachViewQuickActions = ({ data }) => {
	const [loading, setloading] = useState(false);
	const [loading1, setloading1] = useState(false);
	const [loading2, setloading2] = useState(false);
	const [loading3, setloading3] = useState(false);

	const resetPassword = async () => {
		setloading(true);
		await auth().sendPasswordResetEmail(data.email);
		openNotificationWithIcon(
			"success",
			"Password successfully reset",
			`${data.firstName} has been sent a password reset email to ${data.email}`
		);
		setloading(false);
		return;
	};

	const toggleDisable = () => {
		setloading1(true);
		const backEnd = app().functions("asia-northeast1");
		backEnd
			.httpsCallable("DisableUser")({ type: "Coaches", uid: data.key })
			.then((res) => {
				if (res.data.type === "disabled") {
					message.error("Account disabled");
				} else {
					message.success("Account Enabled");
				}
				setloading1(false);
			})
			.catch(() => {
				openNotificationWithIcon(
					"error",
					"Something went wrong",
					`Please try again and contact support if the issue persists.`
				);
				setloading1(false);
			});
	};

	const deleteUser = () => {
		setloading2(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("DeleteUser")({ uid: data.key, type: "Coaches" })
			.then((res) => {
				message.success("This coach is no more...");
				console.log(res.data);
				setloading2(false);
			})
			.catch((err) => {
				console.log(err);
				message.error("Something went wrong, check log");
				setloading2(false);
			});
	};

	const InviteUser = () => {
		setloading3(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("InviteCoach")({
				uid: data.key,
				email: data.email,
				name: data.firstName,
				env:
					process.env.REACT_APP_PROJECT_ID === "careerbase-development-2610e"
						? "development"
						: "production",
			})
			.then((res) => {
				message.success("Invite sent!");
				console.log(res.data);
				setloading3(false);
			})
			.catch((err) => {
				console.log(err);
				message.error("Something went wrong, check log for more details");
				setloading3(false);
			});
	};

	const openNotificationWithIcon = (type, title, message) => {
		notification[type]({
			message: title,
			description: message,
		});
	};

	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Quick Actions</Title>
			</Row>
			<Row gutter={12} style={styles.row}>
				<Col span={12}>
					<Button
						danger
						loading={loading1}
						onClick={toggleDisable}
						style={styles.button}
						data-testid="update-status"
					>
						{data.status === "disabled" ? "Enable Account" : "Disable Account"}
					</Button>
				</Col>
				<Col span={12}>
					<Button
						loading={loading}
						onClick={resetPassword}
						style={styles.button}
						data-testid="reset"
					>
						Reset Password
					</Button>
				</Col>
			</Row>
			<Row gutter={12} style={{ ...styles.row, paddingTop: 12 }}>
				<Col span={12}>
					<Button
						loading={loading3}
						onClick={InviteUser}
						style={styles.button}
						data-testid="reset"
					>
						Invite Coach
					</Button>
				</Col>
				<Col span={12}>
					<Popconfirm
						title="Are you sure you want to delete this coach? This can't be undone."
						onConfirm={deleteUser}
					>
						<Button danger loading={loading2} style={styles.button}>
							Delete User
						</Button>
					</Popconfirm>
				</Col>
			</Row>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default CoachViewQuickActions;
