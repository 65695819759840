import { Row, Col, Typography, Select } from "antd";
import React from "react";
import { coachCategories } from "../../../../../Functions/categories";

const { Title } = Typography;

const CoachPackageDetails = ({ data, delivery, setDelivery, editing, primaryCategory }) => {
	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Package Details</Title>
			</Row>
			<Row justify="space-between" style={styles.row}>
				<p className="Key">Delivery</p>
				{editing ? (
					<Select
						style={{ width: "50%" }}
						value={delivery}
						onChange={(val) => setDelivery("delivery", val)}
						data-testid="delivery-edit"
					>
						<Select.Option key="1" value="In-person">
							In-person
						</Select.Option>
						<Select.Option key="2" value="Zoom">
							Zoom
						</Select.Option>
						<Select.Option key="3" value="Zoom or In-person">
							Zoom or In-person
						</Select.Option>
					</Select>
				) : (
					<p className="Value" data-testid="delivery">
						{data.delivery || "-"}
					</p>
				)}
			</Row>
			<Row justify="space-between" style={{ ...styles.row, marginTop: 12 }}>
				<p className="Key">Category</p>
				{editing ? (
					<Select
						style={{ width: "50%" }}
						value={primaryCategory}
						onChange={(val) => setDelivery("primaryCategory", val)}
						data-testid="delivery-edit"
					>
						{coachCategories.map((item, index) => (
							<Select.Option key={index} value={item}>
								{item}
							</Select.Option>
						))}
					</Select>
				) : (
					<p className="Value" data-testid="delivery">
						{primaryCategory || "-"}
					</p>
				)}
			</Row>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default CoachPackageDetails;
