import { Button, Col, Row, Statistic, Typography } from "antd";
import { app } from "firebase";
import moment from "moment";
import React, { useState } from "react";

const ManualCalendarCheck = () => {
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState({
		users: 0,
		apiCalls: 0,
		dbWrites: 0,
		writeErrors: 0,
		created: moment().format(),
	});

	const triggerSync = () => {
		setLoading(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("CalSyncTester")({})
			.then((res) => {
				console.log(res);
				setResponse(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Typography.Title level={5}>Manual Calendar Sync</Typography.Title>
				<Button size="small" onClick={triggerSync} loading={loading}>
					Check
				</Button>
			</Row>
			<Row>
				<Col span={12}>
					<Statistic title="Coaches" value={response.users} />
				</Col>
				<Col span={12}>
					<Statistic title="API Calls" value={response.apiCalls} />
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Statistic title="Writes" value={response.dbWrites} />
				</Col>
				<Col span={12}>
					<Statistic title="Errors" value={response.writeErrors} />
				</Col>
			</Row>
		</Col>
	);
};

const styles = {};

export default ManualCalendarCheck;
