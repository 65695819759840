import {
	Button,
	Col,
	Divider,
	Row,
	Table,
	Typography,
	Modal,
	Form,
	Input,
	Select,
	InputNumber,
	message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { priceFormatter } from "../../../../Functions/formatters";
import moment from "moment";
import { Link } from "react-router-dom";
import { colorsLight } from "../../../../Styles/Themes";
import { app, database } from "firebase";
import UserContext from "../../../../Context/UserContext";

const MemberPrograms = ({ programs, data }) => {
	const { userInfo } = useContext(UserContext);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text) => <p style={{ margin: 0 }}>{text}</p>,
		},
		{
			title: "Created",
			dataIndex: "created",
			key: "created",
			render: (text) => <p style={{ margin: 0 }}>{moment(text).format("D/M/YY HH:mm")}</p>,
		},
		{
			title: "Amount",
			dataIndex: "price",
			key: "price",
			render: (text) => (
				<p style={{ margin: 0 }}>{priceFormatter(Number(text), "dollars")}</p>
			),
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (text) => <p style={{ margin: 0 }}>{text}</p>,
		},
		{
			title: "Action",
			render: (record) => (
				<Link to={`/ViewProgram/${record.key}`}>
					<Button size="small" data-testid="members-view">
						View
					</Button>
				</Link>
			),
		},
	];

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Typography.Title level={3}>Programs</Typography.Title>
				<Link to={`/ViewPrograms/${data.key}`}>
					<Button>View All</Button>
				</Link>
			</Row>
			<Divider style={{ margin: "6px 0px" }} />
			<Row>
				<Table
					pagination={{ defaultPageSize: 5 }}
					columns={columns}
					dataSource={programs}
					style={{ width: "100%" }}
				/>
			</Row>
		</Col>
	);
};

const styles = {
	price: {
		fontSize: 22,
		fontWeight: "bold",
		color: colorsLight("lightBlue"),
	},
};

export default MemberPrograms;
