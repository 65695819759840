import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Form, message, Modal, Popconfirm, Row, Select, Switch, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useState } from "react";
import firebase from "../../../../../API/firebase";
import { colorsLight } from "../../../../../Styles/Themes";
const { Title } = Typography;
const { RangePicker } = DatePicker;

const CourseDatesModule = ({ data }) => {
	const [active, setActive] = useState(false);
	const [modal, setModal] = useState(false);
	const [modal1, setModal1] = useState(false);
	const [editing, setEditing] = useState();
	const [activeIndex, setActiveIndex] = useState();

	const toggleActive = () => {
		let avail = active ? "anytime" : "dates";
		let state = !active;
		setActive(state);
		firebase
			.firestore()
			.collection("CourseProviders")
			.doc(data.owner)
			.collection("Courses")
			.doc(data.key)
			.update({
				availability: avail,
			})
			.then(() => {
				message.success("Updated course availability");
			})
			.catch((err) => {
				setActive(!state);
			});
	};

	useEffect(() => {
		if (data.availability === "dates") {
			setActive(true);
		} else if (data.availability === "anytime") {
			setActive(false);
		}
	}, [data]);

	const toggleModal = () => setModal(!modal);
	const toggleModal1 = () => setModal1(!modal1);

	const toggleEdit = (index) => {
		if (modal) {
			setEditing({});
			setActiveIndex();
			setModal1(false);
		} else {
			let payload = {
				dates: [moment(data.dates[index].startTime), moment(data.dates[index].endTime)],
				location: data.dates[index].location,
				details: data.dates[index].details,
			};
			setEditing(payload);
			setActiveIndex(index);
			setModal1(true);
		}
	};

	const animVariants = {
		open: {
			maxHeight: 120,
			opacity: 1,
		},
		closed: {
			maxHeight: 0,
			opacity: 0,
		},
	};

	const addDate = (val) => {
		let startTime = moment(val.dates[0]).format();
		let endTime = moment(val.dates[1]).format();
		const ref = firebase.firestore().collection("CourseProviders").doc(data.owner).collection("Courses").doc(data.key);
		let existingDates = data.dates;
		const datePayload = {
			startTime,
			endTime,
			location: val.location,
			details: val.details || "",
		};
		existingDates.push(datePayload);
		let ordered = existingDates.sort(sortDates);
		ref.update({ dates: ordered })
			.then((res) => {
				console.log(res);
				message.success("Succesfully added course date");
				setModal(false);
			})
			.catch((err) => {
				message.error("Could not add date!");
			});
	};

	const editDate = (val) => {
		let startTime = moment(val.dates[0]).format();
		let endTime = moment(val.dates[1]).format();
		const ref = firebase.firestore().collection("CourseProviders").doc(data.owner).collection("Courses").doc(data.key);
		let existingDates = data.dates;
		const datePayload = {
			startTime,
			endTime,
			location: val.location,
			details: val.details || "",
		};
		existingDates[activeIndex] = datePayload;
		let ordered = existingDates.sort(sortDates);
		ref.update({ dates: ordered })
			.then(() => {
				message.success("Succesfully edited course date");
				setModal1(false);
			})
			.catch((err) => {
				message.error("Could not edit date!");
			});
	};

	const removeDate = (ind) => {
		const ref = firebase.firestore().collection("CourseProviders").doc(data.owner).collection("Courses").doc(data.key);
		const existing = data.dates;
		const filtered = existing.filter((item, index) => index !== ind);
		ref.update({ dates: filtered })
			.then(() => {
				message.success("Course date removed successfully");
			})
			.catch((err) => {
				message.error("Could not remove event");
			});
	};

	const sortDates = (a, b) => {
		const startA = Number(moment(a.startTime).format("YYYYMMDDHHmm"));
		const startB = Number(moment(b.startTime).format("YYYYMMDDHHmm"));

		let comparison = 0;
		if (startA > startB) {
			comparison = 1;
		} else if (startA < startB) {
			comparison = -1;
		}
		return comparison;
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Title level={5}>Course Dates</Title>
				<Switch checked={active} onChange={toggleActive} size="small" data-testid="switch" />
			</Row>
			<Divider style={{ margin: 0 }} />
			<motion.div initial={{ opacity: 0 }} animate={active ? "open" : "closed"} variants={animVariants}>
				<Row justify="start">
					<Button onClick={toggleModal} icon={<PlusOutlined />} type="link" data-testid="add-date">
						Add Date
					</Button>
				</Row>
			</motion.div>
			{!active && (
				<Row justify="center" align="middle" style={{ ...styles.row, height: 80 }} data-testid="anytime">
					<p style={{ margin: 0 }}>This course is available anytime</p>
				</Row>
			)}
			{active && (
				<Row
					style={{
						...styles.row,
						minHeight: 80,
						backgroundColor: colorsLight("lightgrey"),
					}}>
					<Col style={{ paddingLeft: 6, paddingRight: 6, paddingTop: 6 }} span={24}>
						{data.dates &&
							data.dates.map((item, index) => (
								<Row key={index} style={{ marginBottom: 12 }} justify="space-between" data-testid="date">
									<Col>
										<p style={{ margin: 0, fontSize: 14, fontWeight: 500 }}>
											{moment(item.startTime).format("ddd Do MMM")} - {moment(item.endTime).format("ddd Do MMM YYYY")}
										</p>
										<Row>
											<p style={{ margin: 0, fontSize: 12, fontWeight: 500 }}>Location: </p>
											<p
												style={{
													margin: 0,
													fontSize: 12,
													fontWeight: 400,
													marginLeft: 3,
												}}>
												{item.location}
											</p>
										</Row>
									</Col>
									<Row style={{ padding: 6 }}>
										<EditOutlined onClick={() => toggleEdit(index)} style={styles.icon} data-testid="date-edit" />
										<Popconfirm
											onConfirm={() => removeDate(index)}
											placement="topLeft"
											title="Are you sure you want to delete this event?">
											<DeleteOutlined style={{ ...styles.icon, marginLeft: 12 }} data-testid="date-delete" />
										</Popconfirm>
									</Row>
								</Row>
							))}
					</Col>
				</Row>
			)}
			{/* ADD COURSE MODAL */}
			<Modal
				destroyOnClose={true}
				title="Add Course Dates"
				visible={modal}
				onCancel={toggleModal}
				footer={
					<Row justify="end">
						<Button onClick={toggleModal} data-testid="add-cancel">
							Cancel
						</Button>
						<Button type="primary" htmlType="submit" form="myForm1" style={{ marginLeft: 12 }} data-testid="add-confirm">
							Add Course Date
						</Button>
					</Row>
				}>
				<Col span={24}>
					<Form id="myForm1" onFinish={addDate} layout="vertical">
						<Row>
							<Col span={24}>
								<Form.Item
									name="dates"
									label="Course Dates"
									rules={[{ required: true, message: "Please pick course dates!" }]}>
									<RangePicker
										style={{ width: "100%" }}
										showTime
										format="Do MMM YYYY h:mm a"
										data-testid="add-date-picker"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Form.Item
									name="location"
									label="Location"
									rules={[{ required: true, message: "Please pick course location!" }]}>
									<Select data-testid="add-location">
										<Select.Option value="Online">Online</Select.Option>
										<Select.Option value="Sydney">Sydney</Select.Option>
										<Select.Option value="Melbourne">Melbourne</Select.Option>
										<Select.Option value="Brisbane">Brisbane</Select.Option>
										<Select.Option value="Gold Coast">Gold Coast</Select.Option>
										<Select.Option value="Canberra">Canberra</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}></Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item name="details" label="Additional Info" rules={[{ required: false }]}>
									<TextArea placeholder="Please enter any additional info" data-testid="add-info" />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
			</Modal>
			{/* EDIT COURSE MODAL */}
			<Modal
				destroyOnClose={true}
				title="Edit Course Dates"
				visible={modal1}
				onCancel={toggleModal1}
				footer={
					<Row justify="end">
						<Button onClick={toggleModal1} data-testid="edit-cancel">
							Cancel
						</Button>
						<Button type="primary" htmlType="submit" form="myForm2" style={{ marginLeft: 12 }} data-testid="edit-confirm">
							Edit Course Date
						</Button>
					</Row>
				}>
				<Col span={24}>
					<Form id="myForm2" onFinish={editDate} layout="vertical" initialValues={editing}>
						<Row>
							<Col span={24}>
								<Form.Item
									name="dates"
									label="Course Dates"
									rules={[{ required: true, message: "Please pick course dates!" }]}>
									<RangePicker
										style={{ width: "100%" }}
										showTime
										format="Do MMM YYYY h:mm a"
										data-testid="edit-date-picker"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Form.Item
									name="location"
									label="Location"
									rules={[{ required: true, message: "Please pick course location!" }]}>
									<Select data-testid="edit-location">
										<Select.Option value="Online">Online</Select.Option>
										<Select.Option value="Sydney">Sydney</Select.Option>
										<Select.Option value="Melbourne">Melbourne</Select.Option>
										<Select.Option value="Brisbane">Brisbane</Select.Option>
										<Select.Option value="Gold Coast">Gold Coast</Select.Option>
										<Select.Option value="Canberra">Canberra</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}></Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item name="details" label="Additional Info" rules={[{ required: false }]}>
									<TextArea placeholder="Please enter any additional info" data-testid="edit-info" />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
			</Modal>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
	icon: {
		fontSize: 16,
		cursor: "pointer",
	},
};

export default CourseDatesModule;
