import { Row, Modal, Col, message, Upload, Form, Select, Button } from "antd";
import { storage, firestore } from "firebase";
import React, { useContext, useState } from "react";
import moment from "moment";
import UserContext from "../../Context/UserContext";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";

const ImageLibraryUploadModal = ({ visible, dismiss }) => {
	const [loading, setLoading] = useState(false);
	const { userInfo } = useContext(UserContext);
	const [photo, setPhoto] = useState("");
	const [fileRef, setFileRef] = useState("");
	const [progress, setprogress] = useState(0);
	const [category, setCategory] = useState("");
	const [fileName, setFileName] = useState("");

	const imageContainer = {
		backgroundImage: `url(${photo})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: "100%",
		width: "100%",
		backgroundColor: "grey",
	};

	const imageCategories = [
		"Finance",
		"Fitness",
		"Executive",
		"Parental",
		"Career",
		"Wellness",
		"Design",
		"Engineering",
		"Recruitment",
		"Job Search",
		"Property",
		"Abstract",
	];

	const uploadFile = async ({ onError, onSuccess, file }) => {
		setLoading(true);
		const reff = `imageLibrary/coverPics/${file.name}`;
		setFileRef(reff);
		let uploadTask = await storage().ref().child(reff).put(file);
		uploadTask.task.on(
			"state_changed",
			(snapshot) => {
				let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setprogress(Number(progress.toFixed(0)));
			},
			(err) => {
				onError();
				setLoading(false);
				setprogress(0);
				message.error("Something went wrong");
				console.log(err);
			},
			() => {
				uploadTask.task.snapshot.ref.getDownloadURL().then(async (downloadUrl) => {
					setFileName(file.name);
					onSuccess();
					setPhoto(downloadUrl);
					setLoading(false);
					setprogress(0);
					return;
				});
			}
		);
	};

	const deleteImage = () => {
		storage()
			.ref(fileRef)
			.delete()
			.then(() => {
				setPhoto("");
				setFileRef("");
				setFileName("");
				setCategory("");
			})
			.catch((err) => {
				console.log(err);
				message.error("Could not delete image, check log for more info");
			});
	};

	const DeleteButton = () => {
		return (
			<Row onClick={deleteImage} justify="center" align="middle" style={styles.delWrapper}>
				<DeleteOutlined />
			</Row>
		);
	};

	const cancel = () => {
		if (photo) {
			setPhoto("");
			setFileRef("");
			setFileName("");
			deleteImage();
			setCategory("");
			dismiss();
		} else {
			dismiss();
		}
	};

	const saveImage = () => {
		photo &&
			firestore()
				.collection("ImageLibrary")
				.add({
					fileName: fileName,
					url: photo,
					ref: fileRef,
					created: moment().format(),
					addedBy: userInfo.displayName,
					category: category || "Executive",
				})
				.then(() => {
					setPhoto("");
					setFileRef("");
					setFileName("");
					setCategory("");
					dismiss();
				})
				.catch((err) => {
					message.error("Could not save image, check log for more details");
					console.log(err);
				});
	};

	return (
		<Modal
			title="Upload new library photo"
			width={700}
			visible={visible}
			onCancel={cancel}
			footer={
				<Row justify="end" gutter={12}>
					<Col>
						<Button onClick={cancel}>Cancel</Button>
					</Col>
					<Col>
						<Button disabled={!photo} onClick={saveImage}>
							Save Image
						</Button>
					</Col>
				</Row>
			}
		>
			<Col span={24}>
				{!photo ? (
					<Upload.Dragger
						customRequest={uploadFile}
						showUploadList={false}
						style={{ padding: 24 }}
					>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
					</Upload.Dragger>
				) : (
					<Row style={styles.imageWrap}>
						<Row style={photo ? imageContainer : styles.empty}>
							<DeleteButton />
						</Row>
					</Row>
				)}
				<Row style={{ width: "100%", paddingTop: 24 }}>
					<Col span={12}>
						<p>Image Category:</p>
						<Select
							style={{ width: "100%" }}
							value={category}
							onChange={(val) => setCategory(val)}
						>
							{imageCategories.map((item, index) => (
								<Select.Option value={item}>{item}</Select.Option>
							))}
						</Select>
					</Col>
				</Row>
			</Col>
		</Modal>
	);
};

const styles = {
	imageWrap: {
		width: "100%",
		height: 200,
		padding: 6,
		backgroundColor: "white",
		boxShadow: "3px 3px 3px rgba(0,0,0,0.4)",
	},
	empty: {
		backgroundColor: "grey",
		height: "100%",
		width: "100%",
	},
	delWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		cursor: "pointer",
		position: "absolute",
		top: 12,
		right: 12,
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
	},
};

export default ImageLibraryUploadModal;
