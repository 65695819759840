import { Button, Col, Divider, Form, Input, Layout, message, Row, Typography } from "antd";
import React, { useContext, useState } from "react";
import Lottie from "react-lottie";
import { Redirect, useParams } from "react-router-dom";
import firebase from "../../API/firebase";
import loadAnim from "../../Assets/loadAnim.json";
import UserContext from "../../Context/UserContext";
import { colorsLight } from "../../Styles/Themes";
const { Content } = Layout;

const Activate = () => {
	const { params } = useParams();
	const [uid, email] = params.split("&");
	const [step, setStep] = useState(1);
	const { setUserInfo, setUid, setAuthd } = useContext(UserContext);
	const [loading, setloading] = useState(false);
	const [animPause] = useState(false);
	const [animStopped] = useState(false);
	const [nav, setNav] = useState(false);

	const animOptions = {
		loop: true,
		autoplay: true,
		animationData: loadAnim,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const createPassword = async (val) => {
		setloading(true);
		if (val.password1 === val.password2) {
			setStep(2);
			await firebase
				.app()
				.functions("asia-northeast1")
				.httpsCallable("CreatePassword")({
					type: "Admins",
					uid,
					password: val.password1,
				})
				.then(async () => {
					setStep(3);
					await firebase
						.auth()
						.signInWithEmailAndPassword(email, val.password1)
						.then(() => {
							return;
						});
				})
				.then(async () => {
					setloading(false);
					setStep(4);
					await firebase
						.firestore()
						.collection("Admins")
						.doc(uid)
						.get()
						.then((userData) => {
							setUserInfo({ ...userData.data(), key: userData.id });
							setUid(uid);
							setAuthd(true);
							return;
						});
				})
				.then(() => {
					setStep(5);
					return;
				})
				.catch((err) => {
					console.log(err);
					setStep(1);
					message.error("Something went wrong");
				});
		} else {
			message.error("Passwords do not match");
			setloading(false);
		}
	};

	return (
		<Layout className="App">
			<Content
				style={{
					backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/careerbase-development-2610e.appspot.com/o/websiteAssets%2Floginbg.jpg?alt=media&token=59ae6afe-5cbc-476a-9912-6164ae5c1387)`,
					backgroundSize: "cover",
				}}
				className="content"
			>
				<Col style={{ height: "100vh" }} span={24}>
					<Row justify="center" align="middle" style={{ height: "100%", width: "100%" }}>
						<Col
							style={{
								width: 400,
								pading: 12,
								paddingLeft: 24,
								paddingRight: 24,
								paddingBottom: 24,
							}}
							className="Card"
						>
							<Row>
								{step !== 5 && (
									<Typography.Title
										style={{
											color: colorsLight("darkGrey"),
											margin: 0,
											width: 250,
										}}
										level={1}
										data-testid="welcome-inactive"
									>
										Welcome to Careerbase!
									</Typography.Title>
								)}
								{step === 5 && (
									<Typography.Title
										style={{ color: colorsLight("darkGrey"), margin: 0 }}
										level={1}
										data-testid="welcome-active"
									>
										Welcome to the team.
									</Typography.Title>
								)}
							</Row>
							<Divider style={{ marginTop: 12, marginBottom: 12 }} />
							{step === 1 && (
								<>
									<Row style={{ width: 350, marginBottom: 24 }} justify="center">
										<p style={{ fontSize: 16 }}>
											You've been invited as an admin on the Careerbase
											platform. Let's get your password set up.
										</p>
									</Row>
									<Form
										style={{ justifyContent: "end" }}
										id="password"
										onFinish={createPassword}
									>
										<Form.Item name="password1">
											<Input
												type="password"
												placeholder="Please enter password"
												data-testid="password"
											/>
										</Form.Item>
										<Form.Item name="password2">
											<Input
												type="password"
												placeholder="Please verify password"
												data-testid="password-verify"
											/>
										</Form.Item>
									</Form>
									<Row justify="end" style={{ width: "100%" }}>
										<Button
											loading={loading}
											htmlType="submit"
											form="password"
											type="primary"
											data-testid="password-button"
										>
											Create Password
										</Button>
									</Row>
								</>
							)}
							{step !== 1 && step !== 5 && (
								<>
									<Row style={{ width: 350 }} justify="center">
										{step === 2 && (
											<p style={{ fontSize: 16 }}>Getting your details...</p>
										)}
										{step === 3 && (
											<p style={{ fontSize: 16 }}>
												Updating your password...
											</p>
										)}
										{step === 4 && (
											<p style={{ fontSize: 16 }}>Wrapping things up...</p>
										)}
									</Row>
									<Row style={{ marginBottom: 24 }} justify="center">
										<Lottie
											options={animOptions}
											height={100}
											width={100}
											isStopped={animStopped}
											isPaused={animPause}
										/>
									</Row>
								</>
							)}
							{step === 5 && (
								<>
									<Row style={{ width: 350 }} justify="center">
										<p style={{ fontSize: 16 }}>
											We're all ready to go...are you?
										</p>
									</Row>
									<Row style={{ marginBottom: 24 }} justify="center">
										<img
											alt="complete"
											src="https://media.tenor.com/images/8f92984c988b37c369f6be86993a497f/tenor.gif"
											style={{ width: "100%", height: 200 }}
										/>
									</Row>
									<Row justify="end" style={{ marginTop: 12 }}>
										<Button
											onClick={() => setNav(true)}
											style={{ marginRight: 12 }}
										>
											I guess so
										</Button>
										<Button onClick={() => setNav(true)} type="primary">
											Yep!
										</Button>
									</Row>
								</>
							)}
						</Col>
					</Row>
				</Col>
				{nav && <Redirect to="/" />}
			</Content>
		</Layout>
	);
};

export default Activate;
