import React, { useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, message } from "antd";
import { Redirect } from "react-router-dom";
import { firestore, app } from "firebase";
import moment from "moment";

const { Option } = Select;

const AddCoach = ({ visible, dismiss }) => {
	const [loading, setloading] = useState(false);
	const [navRef, setNavRef] = useState("");

	const createUser = async (val) => {
		const backEnd = app().functions("asia-northeast1");
		const payload = {
			userType: "coach",
			userData: val,
			perms: "standard",
		};
		const coachRec = {
			displayPic: "",
			dpFileRef: "",
			coverPic: "",
			cpFileRef: "",
			displayName: `${val.firstName} ${val.lastName}`,
			firstName: val.firstName,
			lastName: val.lastName,
			email: val.email,
			created: moment().format(),
			lastSignIn: "never",
			status: "inactive",
			previousStatus: "null",
			summary: "",
			details: "",
			availability: [
				{ day: "Sunday", active: false, start: "0900", finish: "1730" },
				{ day: "Monday", active: true, start: "0900", finish: "1730" },
				{ day: "Tuesday", active: true, start: "0900", finish: "1730" },
				{ day: "Wednesday", active: true, start: "0900", finish: "1730" },
				{ day: "Thursday", active: true, start: "0900", finish: "1730" },
				{ day: "Friday", active: true, start: "0900", finish: "1730" },
				{ day: "Saturday", active: false, start: "0900", finish: "1730" },
			],
			primaryCategory: val.primaryCategory,
		};
		let privateRecord = {
			alternateEmail: "",
			mobile: "-",
			addressLine1: "",
			addressLine2: "",
			suburb: "",
			city: "",
			state: "",
			postCode: "",
			businessName: "",
			abn: "",
			bankName: "",
			bankAccountName: "",
			bankAccountNumber: "",
			bankBSBNumber: "",
		};
		setloading(true);
		try {
			let req = await backEnd.httpsCallable("AuthCreation")(payload);
			const userId = req.data.uid;
			let promises = [
				firestore().collection("Coaches").doc(userId).set(coachRec),
				firestore()
					.collection("Commercials")
					.doc(userId)
					.set({ markup: 15, type: "Coaches" }),
				firestore().collection("UserRecords").doc(userId).set(privateRecord),
			];
			await Promise.all(promises);
			setloading(false);
			dismiss();
			setNavRef(`/Coach/${userId}`);
		} catch (err) {
			message.error("Could not create auth records");
			setloading(false);
			dismiss();
		}
	};

	return (
		<Drawer
			destroyOnClose={true}
			visible={visible}
			title="Add a new coach"
			width={600}
			onClose={dismiss}
			footer={
				<Row justify="end">
					<Button onClick={dismiss} data-testid="coach-cancel">
						Cancel
					</Button>
					<Button
						type="primary"
						form="myForm"
						loading={loading}
						key="submit"
						htmlType="submit"
						style={{ marginLeft: 12 }}
						data-testid="coach-create"
					>
						Create
					</Button>
				</Row>
			}
		>
			<Form id="myForm" onFinish={createUser} layout="vertical" hideRequiredMark>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="firstName"
							label="First Name"
							rules={[{ required: true, message: "Please enter user's first name" }]}
						>
							<Input
								placeholder="Please enter first name"
								data-testid="coach-fname"
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="lastName"
							label="Last Name"
							rules={[{ required: true, message: "Please enter user's last name" }]}
						>
							<Input placeholder="Please enter last name" data-testid="coach-lname" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="email"
							label="Email"
							rules={[
								{ required: true, message: "Please enter user's email address" },
							]}
						>
							<Input
								placeholder="Please enter email address"
								data-testid="coach-email"
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="primaryCategory"
							label="Primary Cateogory"
							rules={[{ required: true, message: "Please choose the category" }]}
						>
							<Select
								placeholder="Please choose category"
								data-testid="coach-category"
							>
								<Option value="Career Development">Career Development</Option>
								<Option value="Multidisciplinary Coach">
									Multidisciplinary Coach
								</Option>
								<Option value="Job Search">Job Search</Option>
								<Option value="Executive Coach">Executive Coach</Option>
								<Option value="Leadership Coach">Leadership Coach</Option>
								<Option value="Fitness Instructor">Fitness Instructor</Option>
								<Option value="Wellbeing Coach">Wellbeing Coach</Option>
								<Option value="Nutrition Coach">Nutrition Coach</Option>
								<Option value="Financial Coaching">Financial Coaching</Option>
								<Option value="Parental Leave">Parental Leave</Option>
								<Option value="Entrepreneurship Coach">
									Entrepreneurship Coach
								</Option>
								<Option value="Personal Branding Coach">
									Personal Branding Coach
								</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}></Col>
					<Col span={12}></Col>
				</Row>
			</Form>
			{navRef && <Redirect to={navRef} />}
		</Drawer>
	);
};

export default AddCoach;
