import { Col, Layout, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import MenuMain from "../../Components/MainMenu";
import BackButton from "../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";
import CalendarSyncChecker from "./Components/CalendarSyncChecker";
import CoachDataChecker from "./Components/CoachDataChecker";
import DemoAccountReset from "./Components/DemoAccountReset";
import ManualCalendarCheck from "./Components/ManualCalendarSync";

const { Header, Footer, Content } = Layout;

const AdminTools = ({ history }) => {
	const [data, setData] = useState({});

	useEffect(() => {
		//GET REQUIRED DATA
	}, []);

	//REQUIRES RE-IMPORTING colorsLight(), <BackButton />, maxContentWidth, <MyAccountIcons />, firebase, <MenuMain />

	//REMEMBER TO CHANGE TO MENU active PROPERTY TO CORRESPOND TO THIS PAGE

	return (
		<Layout className="App">
			<MenuMain active="v" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							padding: 24,
						}}
					>
						<Col span={24} style={{ maxWidth: maxContentWidth }}>
							<Row>
								<Typography.Title>Admin Tools</Typography.Title>
							</Row>
							<Row gutter={24}>
								<Col span={8}>
									<CalendarSyncChecker />
								</Col>
								<Col span={8}>
									<CoachDataChecker />
								</Col>
								<Col span={8}>
									<ManualCalendarCheck />
								</Col>
							</Row>
							<Row gutter={24}>
								<Col span={8}>
									<DemoAccountReset />
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default AdminTools;
