export const iDgen = (length) => {
	var result = "";
	var characters = "abcdefghijklmnopqrstuv0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const getImage = () => {
	let num = Math.floor(Math.random() * 2);
	let images = [
		"https://firebasestorage.googleapis.com/v0/b/careerhub-a50a2.appspot.com/o/defaultImages%2Fdefault1.jpg?alt=media&token=28a1fbc4-00e5-4ed3-9c75-de577aa65662",
		"https://firebasestorage.googleapis.com/v0/b/careerhub-a50a2.appspot.com/o/defaultImages%2Fdefault2.jpg?alt=media&token=ca80f5c7-515f-4bbc-9f42-e166281a433a",
	];
	return images[num];
};

export const filterData = (data, filters) => {
	//Cleans the object and gets it ready for filtering
	const filterKeys = Object.keys(filters);
	let activeFilters = {};
	let sort = { active: false };
	filterKeys.forEach((key) => {
		if (filters[key] === "asc" || filters[key] === "desc") {
			sort.active = true;
			sort.key = key;
			sort.direction = filters[key];
		} else if (filters[key]) {
			activeFilters[key] = filters[key];
		}
	});

	//Array of keys that are to be filtered
	const activeKeys = Object.keys(activeFilters);

	//Takes raw data, and filters it for each attribute
	let filtered = data;
	activeKeys.forEach((key) => {
		const attribute = activeFilters[key];
		const cleaned = filtered.filter((obj) => obj[key] === attribute);
		filtered = cleaned;
	});

	//Check if we need to sort the data by an attribute
	if (sort.active) {
		if (sort.direction === "asc") {
			let sorted = filtered.slice().sort((a, b) => a.price - b.price);
			return sorted;
		} else {
			let sorted = filtered.slice().sort((a, b) => b.price - a.price);
			return sorted;
		}
	} else {
		return filtered;
	}
};
