import { Breadcrumb, Col, Layout, message, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import firebase from "../../../../API/firebase";
import MyAccountIcons from "../../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../../Components/MainMenu";
import BackButton from "../../../../Components/Navigation/BackButton";
import StoreSyncStatus from "../../../../Components/StoreSyncStatus2";
import { getRealItemPrice } from "../../../../Functions/helpers";
import { colorsLight } from "../../../../Styles/Themes";
import ServiceCommercials from "./Components/ServiceCommercials";
import ServiceStoreStatus from "./Components/ServiceStoreStatus";
import ServiceViewHeader from "./Components/ServiceViewHeader";
import ServiceViewQuickActions from "./Components/ServiceViewQuickActions";

const { Header, Content } = Layout;

const ServiceView = () => {
	// const [addModal, setAddModal] = useState(false);
	const [data, setData] = useState({});
	// const [Services, setServices] = useState([]);
	const [costPrice, setCostPrice] = useState(0);
	const [commercials, setCommercials] = useState({});
	const { providerIdANDServiceId } = useParams();
	const [providerId, ServiceId] = providerIdANDServiceId.split("&");

	useEffect(() => {
		let listener = async () =>
			firebase
				.firestore()
				.collection("ServiceProviders")
				.doc(providerId)
				.collection("Services")
				.doc(ServiceId)
				.onSnapshot((doc) => {
					setData({ ...doc.data(), key: doc.id });
					setCostPrice(doc.data().costPrice);
				});

		let listener3 = async () =>
			firebase
				.firestore()
				.collection("Commercials")
				.doc(providerId)
				.onSnapshot((doc) => {
					setCommercials({ ...doc.data(), key: doc.id });
				});

		listener();
		listener3();
	}, [providerId, ServiceId]);

	const activateItem = () => {
		const db = firebase.firestore();
		db.collection("ServiceProviders")
			.doc(providerId)
			.collection("Services")
			.doc(ServiceId)
			.update({
				status: "active",
				lastStoreSync: moment().format(),
			})
			.then(async () => {
				let price = await getRealItemPrice(
					providerId,
					"ServiceProviders",
					ServiceId,
					"Services"
				);
				return price;
			})
			.then((price) => {
				let keys = Object.keys(data);
				let obj = {};
				keys.forEach((key) => {
					if (key !== "key" || key !== "costPrice") {
						obj = { ...obj, key: data[key] };
					}
				});
				obj.price = price;
				return db.collection("Services").doc(ServiceId).set(obj);
			})
			.then(() => {
				message.success("This Service it now live!");
			})
			.catch(() => {
				message.error("Something went wrong!");
			});
	};

	const disableItem = () => {
		const db = firebase.firestore();
		db.collection("ServiceProviders")
			.doc(providerId)
			.collection("Services")
			.doc(ServiceId)
			.update({
				status: "disabled",
				lastStoreSync: moment().format(),
			})
			.then(() => {
				return db.collection("Services").doc(ServiceId).delete();
			})
			.then(() => {
				message.warning("This Service is now longer on the store");
			})
			.catch(() => {
				message.error("Something went wrong!");
			});
	};

	return (
		<Layout className="App">
			<MenuMain active="5" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton to={`/Service-Provider/${providerId}`} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row style={{ marginBottom: 12 }}>
							<Breadcrumb>
								<Breadcrumb.Item>
									<Link to="/Service-Providers">Services</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>
									<Link to={`/Service-Provider/${data.owner}`}>
										{data.providerName}
									</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>{data.name}</Breadcrumb.Item>
							</Breadcrumb>
						</Row>
						<Row>
							<Col span={16}>
								<ServiceViewHeader data={data} />
							</Col>
							<Col style={{ paddingLeft: 24 }} span={8}>
								<ServiceViewQuickActions
									activate={activateItem}
									disable={disableItem}
									data={data}
									providerId={providerId}
									ServiceId={ServiceId}
								/>
								<StoreSyncStatus
									data={data}
									type="Services"
									markup={commercials.markup}
								/>
								{/* <ServiceStoreStatus data={data} markup={commercials.markup} /> */}
								<ServiceCommercials
									costPrice={costPrice}
									setCostPrice={setCostPrice}
									markup={commercials.markup}
									data={data}
								/>
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		paddingLeft: 22,
		paddingRight: 22,
		paddingTop: 12,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default ServiceView;
