import { Row, Col, Typography, Button, Modal, Input } from "antd";
import React, { useState } from "react";
import firebase from "../../../../API/firebase";
import { Redirect } from "react-router-dom";
const { Title } = Typography;

const CourseProviderQuickActions = ({ data, list }) => {
	const [modal, setModal] = useState(false);
	const [delPhrase, setDelPhrase] = useState("");
	const [loading, setloading] = useState(false);
	const [, setloading1] = useState(false);
	const [nav, setNav] = useState(false);

	const toggleModal = () => setModal(!modal);

	const activateAccount = async () => {
		setloading(true);
		const batch = firebase.firestore().batch();
		list.forEach((item) => {
			let ref = firebase
				.firestore()
				.collection("CourseProviders")
				.doc(data.key)
				.collection("Courses")
				.doc(item.key);
			item.status !== "inactive" && batch.update(ref, { status: "active" });
		});
		batch.update(firebase.firestore().collection("CourseProviders").doc(data.key), {
			status: "active",
		});
		await batch.commit();
		setloading(false);
	};

	const disableAccount = async () => {
		setloading(true);
		const batch = firebase.firestore().batch();
		list.forEach((item) => {
			let ref = firebase
				.firestore()
				.collection("CourseProviders")
				.doc(data.key)
				.collection("Courses")
				.doc(item.key);
			item.status !== "inactive" && batch.update(ref, { status: "disabled" });
		});
		batch.update(firebase.firestore().collection("CourseProviders").doc(data.key), {
			status: "disabled",
		});
		await batch.commit();
		setloading(false);
	};

	const deleteProvider = async () => {
		setloading1(true);
		const batch = firebase.firestore().batch();
		list.forEach((item) => {
			let ref = firebase
				.firestore()
				.collection("CourseProviders")
				.doc(data.key)
				.collection("Courses")
				.doc(item.key);
			batch.delete(ref);
		});
		batch.delete(firebase.firestore().collection("CourseProviders").doc(data.key));
		if (data.dpFileRef) {
			await firebase.storage().ref().child(data.dpFileRef).delete();
		}
		if (data.cpFileRef) {
			await firebase.storage().ref().child(data.cpFileRef).delete();
		}
		await batch.commit();
		setloading1(false);
		setNav(true);
	};

	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Quick Actions</Title>
			</Row>
			<Row style={styles.row}>
				<Col style={{ paddingRight: 6 }} span={12}>
					{data.status === "inactive" && (
						<Button
							onClick={activateAccount}
							loading={loading}
							style={styles.button}
							data-testid="activate"
						>
							Activate Provider
						</Button>
					)}
					{data.status === "disabled" && (
						<Button
							onClick={activateAccount}
							danger
							loading={loading}
							style={styles.button}
							data-testid="enable"
						>
							Enable Provider
						</Button>
					)}
					{data.status === "active" && (
						<Button
							danger
							onClick={disableAccount}
							loading={loading}
							style={styles.button}
							data-testid="disable"
						>
							Disable Provider
						</Button>
					)}
				</Col>
				<Col style={{ paddingLeft: 6 }} span={12}>
					<Button
						onClick={() => setModal(!modal)}
						type="danger"
						loading={loading}
						style={styles.button}
						data-testid="delete"
					>
						Delete Provider
					</Button>
				</Col>
			</Row>
			<Modal
				title="Confirm Deletion"
				visible={modal}
				onCancel={toggleModal}
				footer={
					<Row>
						<Button data-testid="cancel" onClick={toggleModal}>
							Cancel
						</Button>
						<Button
							disabled={delPhrase !== "DELETE-PROVIDER"}
							onClick={deleteProvider}
							type="primary"
							data-testid="delete-confirm"
						>
							Delete
						</Button>
					</Row>
				}
			>
				<Col span={24}>
					<Row>
						<p>Are you sure you want to delete this provider? This cannot be undone.</p>
					</Row>
					<Row>
						<p>To confirm, please type DELETE-PROVIDER to confirm.</p>
					</Row>
					<Input
						value={delPhrase}
						onChange={(val) => setDelPhrase(val.target.value)}
						placeholder="DELETE-PROVIDER"
						data-testid="delete-phrase"
					/>
				</Col>
			</Modal>
			{nav && <Redirect to={`/Course-Providers`} />}
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default CourseProviderQuickActions;
