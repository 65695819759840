import React, { useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select } from "antd";
import firebase from "../../../API/firebase";

const { Option } = Select;

const AddTeamMember = ({ visible, dismiss }) => {
	const [loading, setloading] = useState(false);

	const createUser = async (val) => {
		const payload = {
			userType: "admin",
			userData: val,
			perms: val.perms,
		};

		firebase
			.app()
			.functions("asia-northeast1")
			.httpsCallable("UserCreationService")(payload)
			.then(() => {
				setloading(false);
				dismiss();
			})
			.catch((err) => {
				console.log(err);
				setloading(false);
			});
	};

	return (
		<Drawer
			destroyOnClose={true}
			visible={visible}
			title="Add a new team member"
			width={450}
			onClose={dismiss}
			footer={
				<Row justify="end">
					<Button onClick={dismiss} data-testid="cancel">
						Cancel
					</Button>
					<Button
						type="primary"
						form="myForm"
						loading={loading}
						key="submit"
						htmlType="submit"
						style={{ marginLeft: 12 }}
						data-testid="add"
					>
						Add User
					</Button>
				</Row>
			}
		>
			<Form id="myForm" onFinish={createUser} layout="vertical" hideRequiredMark>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="firstName"
							label="First Name"
							rules={[{ required: true, message: "Please enter user's first name" }]}
						>
							<Input placeholder="Please enter first name" data-testid="fname" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="lastName"
							label="Last Name"
							rules={[{ required: true, message: "Please enter user's last name" }]}
						>
							<Input placeholder="Please enter last name" data-testid="lname" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="email"
							label="Email"
							rules={[
								{ required: true, message: "Please enter user's email address" },
							]}
						>
							<Input placeholder="Please enter email address" data-testid="email" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}></Col>
					<Col span={12}>
						<Form.Item
							name="perms"
							label="Permissions"
							rules={[{ required: true, message: "Please choose the approver" }]}
						>
							<Select
								placeholder="Please choose permissions"
								data-testid="permissions"
							>
								<Option value="all">Super Admin</Option>
								<Option value="standard">Standard</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
};

export default AddTeamMember;
