import { Col, Row, Typography, Avatar, Tag, Form, Input, Select, message } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { colorsLight } from "../../../../Styles/Themes";
const { Title } = Typography;

const MemberViewHeaderForm = ({ data, privateData, accountOwner, toggleEdit }) => {
	const [playlists, setPlaylists] = useState([]);

	const updateProfile = (val) => {
		const pubRec = {
			firstName: val.firstName || "",
			lastName: val.lastName || "",
			displayName: `${val.firstName} ${val.lastName}`,
			email: val.email,
			activePlaylist: val.activePlaylist,
		};
		const privRecord = {
			mobile: val.mobile || "",
			alternateEmail: val.alternateEmail || "",
			addressLine1: val.addressLine1 || "",
			addressLine2: val.addressLine2 || "",
			suburb: val.suburb || "",
			city: val.city || "",
			postCode: val.postCode || "",
			state: val.state || "",
		};
		firestore()
			.collection("Members")
			.doc(data.key)
			.update(pubRec)
			.then(() => {
				return firestore().collection("UserRecords").doc(data.key).update(privRecord);
			})
			.then(() => {
				toggleEdit();
			})
			.catch((err) => message.error(err));
	};

	useEffect(() => {
		let list = [];
		if (accountOwner.key) {
			firestore()
				.collection("Playlists")
				.where("type", "==", "Public")
				.get()
				.then((res) => {
					res.forEach((doc) => list.push({ ...doc.data(), key: doc.id }));
					return;
				})
				.then(() => {
					return firestore()
						.collection("Playlists")
						.where("owner", "==", accountOwner.key || "")
						.get();
				})
				.then((res) => {
					res.forEach((doc) => list.push({ ...doc.data(), key: doc.id }));
					setPlaylists(list);
				})
				.catch((err) => message.error(err));
		}
	}, [accountOwner]);

	return (
		<Form id="editMember" onFinish={updateProfile} initialValues={{ ...data, ...privateData }}>
			<Row style={{ padding: "12px 12px 0px 12px" }}>
				<Typography.Title level={5}>Public Info</Typography.Title>
			</Row>
			<Row style={{ padding: "0px 24px" }} gutter={24}>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">First Name</p>
						<Form.Item style={{ margin: "6px 0px" }} name="firstName">
							<Input placeholder="Please enter first name" />
						</Form.Item>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Email</p>
						<Form.Item style={{ margin: "6px 0px" }} name="email">
							<Input placeholder="Please enter email" />
						</Form.Item>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Last Name</p>
						<Form.Item style={{ margin: "6px 0px" }} name="lastName">
							<Input placeholder="Please enter last name" />
						</Form.Item>
					</Row>
				</Col>
			</Row>
			<Row style={{ padding: "12px 12px 0px 12px" }}>
				<Col span={12}>
					<Typography.Title level={5}>Private Info</Typography.Title>
				</Col>
			</Row>
			<Row gutter={24} style={{ padding: "0px 24px 12px 24px" }}>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Mobile</p>
						<Form.Item style={{ margin: "6px 0px" }} name="mobile">
							<Input placeholder="Please enter first name" />
						</Form.Item>
					</Row>
					<Row justify="space-between">
						<p className="Key">Alternate Email</p>
						<Form.Item style={{ margin: "6px 0px" }} name="alternateEmail">
							<Input placeholder="Please enter alternate email" />
						</Form.Item>
					</Row>
					<Row justify="space-between">
						<p className="Key">Calendar Service</p>
						<p className="Value">{privateData.calendarService || "-"}</p>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Address line 1</p>
						<Form.Item style={{ margin: "6px 0px" }} name="addressLine1">
							<Input placeholder="Please enter address" />
						</Form.Item>
					</Row>
					<Row justify="space-between">
						<p className="Key">Address line 2</p>
						<Form.Item style={{ margin: "6px 0px" }} name="addressLine2">
							<Input placeholder="Please enter address" />
						</Form.Item>
					</Row>
					<Row justify="space-between">
						<p className="Key">Suburb</p>
						<Form.Item style={{ margin: "6px 0px" }} name="suburb">
							<Input placeholder="Please enter suburb" />
						</Form.Item>
					</Row>
					<Row justify="space-between">
						<p className="Key">City</p>
						<Form.Item style={{ width: "43%", margin: "6px 0px" }} name="city">
							<Select placeholder="Please select city">
								<Select.Option>Sydney</Select.Option>
								<Select.Option>Melbourne</Select.Option>
								<Select.Option>Brisbane</Select.Option>
								<Select.Option>Canberra</Select.Option>
								<Select.Option>Perth</Select.Option>
								<Select.Option>Darwin</Select.Option>
								<Select.Option>Adelaide</Select.Option>
								<Select.Option>Launceston</Select.Option>
							</Select>
						</Form.Item>
					</Row>
					<Row justify="space-between">
						<p className="Key">Postcode</p>
						<Form.Item style={{ margin: "6px 0px" }} name="postCode">
							<Input placeholder="Please enter postcode" />
						</Form.Item>
					</Row>
					<Row justify="space-between">
						<p className="Key">State</p>
						<Form.Item style={{ width: "43%", margin: "6px 0px" }} name="state">
							<Select placeholder="Please select city">
								<Select.Option>New South Wales</Select.Option>
								<Select.Option>Queensland</Select.Option>
								<Select.Option>Victoria</Select.Option>
								<Select.Option>South Australia</Select.Option>
								<Select.Option>Tasmania</Select.Option>
								<Select.Option>Western Australia</Select.Option>
								<Select.Option>Northern Territory</Select.Option>
								<Select.Option>Australian Capital Territory</Select.Option>
							</Select>
						</Form.Item>
					</Row>
				</Col>
			</Row>
			<Row style={{ padding: "12px 12px 0px 12px" }}>
				<Col span={12}>
					<Typography.Title level={5}>Active Playlist</Typography.Title>
				</Col>
			</Row>
			<Row style={{ padding: "12px 24px" }}>
				<Col span={12}>
					<Row justify="space-between">
						<Form.Item style={{ width: "100%" }} name="activePlaylist">
							<Select placeholder="Please select active playlist">
								<Select.Option key="z" value="">
									None
								</Select.Option>
								{playlists.map((item, index) => (
									<Select.Option key={index} value={item.key}>
										{item.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Row>
				</Col>
			</Row>
			<Row style={{ padding: "12px 12px 0px 12px" }}>
				<Col span={12}>
					<Typography.Title level={5}>Parent Account</Typography.Title>
				</Col>
				<Col span={12}>
					<Typography.Title level={5}>Calendar Sync</Typography.Title>
				</Col>
			</Row>
			<Row gutter={24} style={{ padding: "12px 24px" }}>
				<Col span={12}>
					<Row
						style={{
							backgroundColor: colorsLight("lightgrey"),
							borderRadius: 12,
							padding: 6,
							boxShadow: "1px 1px 3px rgba(0,0,0,0.5)",
						}}
					>
						<Avatar
							style={{ borderStyle: "solid", borderColor: "grey", borderWidth: 1 }}
							src={accountOwner.displayPic}
							size={70}
						/>
						<Col style={{ paddingLeft: 12 }}>
							<p style={{ fontSize: 22, margin: 0 }} className="Value">
								{accountOwner.name}
							</p>
							<Link style={{ margin: 0 }} to={`/Account/${accountOwner.key}`}>
								View Account
							</Link>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Row
						justify="space-between"
						style={{
							height: 82,
							backgroundColor: colorsLight("lightgrey"),
							borderRadius: 12,
							padding: 6,
							boxShadow: "1px 1px 3px rgba(0,0,0,0.5)",
						}}
					>
						<Col>
							<p style={{ fontSize: 22, fontWeight: 300, margin: 0 }}>
								Calendar Integration
							</p>
							<p style={{ margin: 0 }}>
								{accountOwner.calendarService
									? accountOwner.calendarService
									: "Not yet synced"}
							</p>
						</Col>
						<Col>
							<Tag color={privateData.calendarSync ? "green" : "red"}>
								{privateData.calendarSync ? "ACTIVE" : "INACTIVE"}
							</Tag>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};

const styles = {
	imageWrapper: {
		height: 142,
		width: 142,
		borderRadius: 71,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginTop: -60,
		marginLeft: 24,
	},
	paddingHoro: {},
	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
	formField: {
		margin: "0px 0px 6px 0px",
	},
};

export default MemberViewHeaderForm;
