import { Row, Col, Divider, Button, notification, Avatar } from "antd";
import React, { useContext } from "react";
import { colorsLight } from "../../Styles/Themes";
import { Link } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import firebase from "../../API/firebase";

const MyAccountDropdown = () => {
	const { setAuthd, setUid, userInfo } = useContext(UserContext);

	const handleSignOut = () => {
		firebase
			.auth()
			.signOut()
			.then(() => {
				setAuthd(false);
			})
			.catch((err) => {
				console.log(err);
				openNotificationWithIcon("error");
			});
	};

	// const handleIntercom = () => {
	// 	console.log("hello");
	// };

	const openNotificationWithIcon = (type) => {
		notification[type]({
			message: "Something went wrong",
			description: "Please try again or contact support if error persists.",
		});
	};

	return (
		<Col style={styles.card} data-testid="settings-dropdown">
			<Row>
				<Col>
					<Avatar src={userInfo.displayPic} size={60} data-testid="settings-avatar" />
				</Col>
				<Col style={{ paddingLeft: 12 }}>
					<p style={styles.name} data-testid="settings-user">
						{userInfo.firstName} {userInfo.lastName}
					</p>
					<Link to="/My-Account" style={styles.link} data-testid="settings-edit">
						Edit your details
					</Link>
				</Col>
			</Row>
			<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			<Row>
				<Col span={12} style={{ paddingRight: 6 }}>
					<Link to="/My-Account">
						<Button style={{ width: "100%" }} data-testid="settings-help">
							My Account
						</Button>
					</Link>
				</Col>
				<Col span={12} style={{ paddingLeft: 6 }}>
					<Button
						onClick={handleSignOut}
						type="primary"
						style={{ width: "100%" }}
						data-testid="settings-logout"
					>
						Log out
					</Button>
				</Col>
			</Row>
		</Col>
	);
};

const styles = {
	card: {
		backgroundColor: "white",
		boxShadow: "3px 3px 3px rgba(0,0,0,0.15)",
		width: 300,
		padding: 12,
		borderWidth: 1,
		borderStyle: "solid",
		borderRadius: 12,
		borderColor: colorsLight("lightgrey"),
	},
	name: {
		fontSize: 22,
		fontWeight: 300,
		margin: 0,
	},
	link: {
		margin: 0,
		fontSize: 13,
	},
};

export default MyAccountDropdown;
