import { Breadcrumb, Col, Layout, message, Row } from "antd";
import { firestore } from "firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import firebase from "../../../../API/firebase";
import MyAccountIcons from "../../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../../Components/MainMenu";
import BackButton from "../../../../Components/Navigation/BackButton";
import StoreSyncStatus from "../../../../Components/StoreSyncStatus2";
import { getRealItemPrice } from "../../../../Functions/helpers";
import { colorsLight } from "../../../../Styles/Themes";
import CourseCommercials from "./Components/CourseCommercials";
import CourseDatesModule from "./Components/CourseDatesModule";
import CourseOverview from "./Components/CourseOverview";

import CourseViewHeader from "./Components/CourseViewHeader";
import CourseViewQuickActions from "./Components/CourseViewQuickActions";

const { Header, Content } = Layout;

const CourseView = () => {
	// const [addModal, setAddModal] = useState(false);
	const [providerData, setProviderData] = useState({});
	const [data, setData] = useState({});
	// const [courses, setCourses] = useState([]);
	const [costPrice, setCostPrice] = useState(0);
	const [commercials, setCommercials] = useState({});
	const { providerIdANDcourseId } = useParams();
	const [providerId, courseId] = providerIdANDcourseId.split("&");
	const history = useHistory();

	useEffect(() => {
		let listener = firestore()
			.collection("CourseProviders")
			.doc(providerId)
			.collection("Courses")
			.doc(courseId)
			.onSnapshot((doc) => {
				setData({ ...doc.data(), key: doc.id });
				setCostPrice(doc.data().costPrice || 0);
			});

		let listener2 = firestore()
			.collection("CourseProviders")
			.doc(providerId)
			.onSnapshot((doc) => {
				setProviderData({ ...doc.data(), key: doc.id });
			});

		let listener3 = firestore()
			.collection("Commercials")
			.doc(providerId)
			.onSnapshot((doc) => {
				setCommercials({ ...doc.data(), key: doc.id });
			});

		return () => {
			listener();
			listener2();
			listener3();
		};
	}, [courseId, providerId]);

	return (
		<Layout className="App">
			<MenuMain active="4" />
			<Layout>
				<Header className="Header">
					<Row className="Fill" justify="space-between" align="middle" style={styles.topRow}>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row style={{ marginBottom: 12 }}>
							<Breadcrumb>
								<Breadcrumb.Item>
									<Link to="/Course-Providers">Courses</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>
									<Link to={`/Course-Provider/${data.owner}`}>{data.providerName}</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>{data.name}</Breadcrumb.Item>
							</Breadcrumb>
						</Row>
						<Row>
							<Col span={16}>
								<CourseViewHeader data={data} />
							</Col>
							<Col style={{ paddingLeft: 24 }} span={8}>
								<CourseViewQuickActions data={data} markup={commercials.markup} providerData={providerData} />
								<CourseOverview data={data} providerData={providerData} />
								<CourseDatesModule data={data} />
								<StoreSyncStatus data={data} type="Courses" markup={commercials.markup} />
								<CourseCommercials
									costPrice={costPrice}
									setCostPrice={setCostPrice}
									markup={commercials.markup}
									data={data}
								/>
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		paddingLeft: 22,
		paddingRight: 22,
		paddingTop: 12,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default CourseView;
