import { Col, Divider, message, Tag, Typography, Row, Button } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { colorsLight } from "../Styles/Themes";
import { getSalePrice } from "../Functions/helpers";

const StoreSyncStatus = ({ data, type, markup }) => {
	const [loading1, setLoading1] = useState(false);
	const [status, setStatus] = useState("Loading");
	const [lastCheck, setLastCheck] = useState("");
	const [errors, setErrors] = useState([]);
	const [checks, setChecks] = useState("");

	useEffect(() => {
		checkStore();
	}, [data, markup]);

	const checkStore = () => {
		let result = "pass";
		let checkedFields = 0;
		let log = [];

		if (data.status === "active" && markup) {
			setLoading1(true);
			firestore()
				.collection(type)
				.doc(data.key)
				.get()
				.then((doc) => {
					if (doc.exists) {
						const listingData = doc.data();
						const keys = Object.keys(listingData);
						keys.forEach((key) => {
							if (key !== "price" && key !== "key") {
								if (listingData[key] !== data[key]) {
									if (Array.isArray(listingData[key])) {
										let dateCheck = 0;
										listingData[key].forEach((item, index) => {
											const dateKeys = Object.keys(item);
											let dateRes = "pass";
											dateKeys.forEach((dateKey) => {
												if (item[dateKey] !== data[key][index][dateKey]) {
													dateRes = "fail";
													log.push(`${key} field not synced`);
												}
											});
											if (dateRes === "fail") {
												result = "fail";
												log.push(`${key} field not synced`);
											} else {
												dateCheck++;
											}
										});
										//console.log(`Checked ${dateCheck} dates`);
									} else {
										result = "fail";
										log.push(`${key} field not synced`);
									}
								} else {
									checkedFields++;
								}
							} else {
								const salePrice = getSalePrice(data.costPrice, markup, "none");
								if (listingData.price === salePrice) {
									checkedFields++;
								} else {
									result = "fail";
									log.push(
										`Incorrect price. Should be ${salePrice} and is ${listingData.price}`
									);
								}
							}
						});
						setLastCheck(moment().format());
						return { result: result, checkedFields: checkedFields };
					} else {
						message.error("Could not find store listing, sync and try again");
						return { result: result, checkedFields: checkedFields };
					}
				})
				.then((res) => {
					if (res.result === "pass") {
						setStatus(res.result);
						setErrors(log);
						setChecks(`Checked ${res.checkedFields} fields, all looking good!`);
						setLoading1(false);
					} else {
						setStatus(res.result);
						setErrors(log);
						setChecks("");
						setLoading1(false);
					}
				})
				.catch((err) => {
					message.error("Could not get sync data, check console for more info.");
					console.log(err);
					setLoading1(false);
				});
		}
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Typography.Title level={5}>Store Listing Status</Typography.Title>
				<Button onClick={checkStore} size="small">
					Check
				</Button>
			</Row>
			<Divider style={{ margin: "6px 0px 6px 0px" }} />
			{data.status === "active" ? (
				<>
					<Row justify="space-between">
						<p className="Key">Sync status</p>
						<div>
							<Tag
								color={
									status === "pass"
										? colorsLight("green")
										: status === "Loading"
										? colorsLight("warning")
										: colorsLight("darkRed")
								}
							>
								{status.toUpperCase()}
							</Tag>
						</div>
					</Row>
					{errors.length > 0 && (
						<Row>
							<Col flex="100px">
								<p className="Key">Notes:</p>
							</Col>
							<Col style={{ justifyContent: "right" }} flex="auto">
								{errors.map((item) => (
									<p className="Value">{item}</p>
								))}
							</Col>
						</Row>
					)}
					{checks && (
						<Row justify="space-between">
							<p className="Key">Notes:</p>
							<p className="Value">{checks}</p>
						</Row>
					)}
				</>
			) : (
				<Row style={{ padding: "24px 0px 18px 0px" }} justify="center">
					<p style={{ margin: 0 }}>Item is not active in the store</p>
				</Row>
			)}
		</Col>
	);
};

export default StoreSyncStatus;
