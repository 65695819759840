import { Button, Col, Modal, Row, Upload, Progress, message } from "antd";
import firebase from "../API/firebase";
import React, { useEffect, useState } from "react";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { colorsLight } from "../Styles/Themes";
import CoverModalLibrary from "./CoverModalLibrary";

const CoverPhotoUploadModal = ({ visible, dismiss, userType, data, packLevel }) => {
	const [loading, setLoading] = useState(false);
	const [progress, setprogress] = useState(0);
	const [imageLibrary, setImageLibrary] = useState([]);
	const [libOpen, setLibOpen] = useState(false);
	const [photo, setPhoto] = useState("");
	const [fileRef, setFileRef] = useState("");
	const [cpType, setCpType] = useState("");

	const [changed, setChanged] = useState(false);

	const uploadFile = async ({ onError, onSuccess, file }) => {
		setLoading(true);
		const storage = firebase.storage();
		const reff = `coverPhotos/${data.key}/${file.name}`;
		let uploadTask = await storage.ref().child(reff).put(file);
		uploadTask.task.on(
			"state_changed",
			(snapshot) => {
				let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setprogress(Number(progress.toFixed(0)));
			},
			(err) => {
				onError();
				setLoading(false);
				setprogress(0);
				message.error("Something went wrong");
				console.log(err);
			},
			() => {
				uploadTask.task.snapshot.ref.getDownloadURL().then(async (downloadUrl) => {
					onSuccess();
					setPhoto(downloadUrl);
					setLoading(false);
					setCpType("own");
					setprogress(0);
					setChanged(true);
					return;
				});
			}
		);
	};

	useEffect(() => {
		if (data.coverPic) {
			setPhoto(data.coverPic);
		}
	}, [data, visible]);

	const deleteImage = async () => {
		if (changed && cpType === "own") {
			try {
				await firebase.storage().refFromURL(data.photo).delete();
				setPhoto("");
				setFileRef("");
				return;
			} catch (err) {
				message.error("Could not delete image");
				return;
			}
		} else {
			setPhoto("");
			setFileRef("");
			setChanged(true);
		}
	};

	const DeleteButton = () => {
		return (
			<Row onClick={deleteImage} justify="center" align="middle" style={styles.delWrapper}>
				<DeleteOutlined />
			</Row>
		);
	};

	const closeModal = async () => {
		if (data.coverPic !== photo) {
			if (photo && fileRef) {
				try {
					await firebase.storage().ref().child(fileRef).delete();
					dismiss();
					setChanged(false);
					setPhoto("");
					setFileRef("");
				} catch (err) {
					setPhoto("");
					setFileRef("");
					dismiss();
					setChanged(false);
				}
			} else {
				dismiss();
				setChanged(false);
			}
		} else {
			dismiss();
			setChanged(false);
		}
	};

	const saveChanges = async () => {
		if (userType === "CourseProviders" && packLevel) {
			if (data.coverPic !== photo) {
				if (data.cpType === "own") {
					let ref = firebase.storage().refFromURL(data.coverPic);
					await ref.delete();
				}
				await firebase
					.firestore()
					.collection(userType)
					.doc(data.owner)
					.collection("Courses")
					.doc(data.key)
					.update({ coverPic: photo, coverPicType: cpType });
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			} else {
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			}
		} else if (userType === "ServiceProviders" && packLevel) {
			if (data.coverPic !== photo) {
				console.log(cpType);
				if (data.cpType === "own") {
					let ref = firebase.storage().refFromURL(data.coverPic);
					await ref.delete();
				}
				await firebase
					.firestore()
					.collection(userType)
					.doc(data.owner)
					.collection("Services")
					.doc(data.key)
					.update({ coverPic: photo, coverPicType: cpType });
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			} else {
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			}
		} else if (userType === "BenefitsProviders" && packLevel) {
			if (data.coverPic !== photo) {
				if (data.cpType === "own") {
					let ref = firebase.storage().refFromURL(data.coverPic);
					await ref.delete();
				}
				await firebase
					.firestore()
					.collection(userType)
					.doc(data.owner)
					.collection("BenefitsProviders")
					.doc(data.key)
					.update({ coverPic: photo, coverPicType: cpType });
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			} else {
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			}
		} else if (userType === "CoachPackages" && packLevel) {
			if (data.coverPic !== photo) {
				if (data.cpType === "own") {
					let ref = firebase.storage().refFromURL(data.coverPic);
					await ref.delete();
				}
				await firebase
					.firestore()
					.collection("Coaches")
					.doc(data.coachId || data.owner)
					.collection("Packages")
					.doc(data.key)
					.update({ coverPic: photo, coverPicType: cpType });
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			} else {
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			}
		} else {
			if (data.coverPic !== photo) {
				if (data.cpType === "own") {
					let ref = firebase.storage().refFromURL(data.coverPic);
					await ref.delete();
				}
				await firebase
					.firestore()
					.collection(userType)
					.doc(data.key)
					.update({ coverPic: photo, coverPicType: cpType });
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			} else {
				setPhoto("");
				setFileRef("");
				dismiss();
				setChanged(false);
			}
		}
	};

	useEffect(() => {
		if (libOpen && !imageLibrary.length > 0) {
			firebase
				.firestore()
				.collection("ImageLibrary")
				.get()
				.then((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setImageLibrary(list);
				});
		}
	}, [libOpen, imageLibrary]);

	const changeImage = (data) => {
		setPhoto(data.url);
		setCpType("lib");
		setChanged(true);
		setLibOpen(false);
	};

	return (
		<Modal
			width={850}
			visible={visible}
			onCancel={closeModal}
			title="Edit Cover Photo"
			footer={
				<Row justify="end">
					<Button onClick={closeModal} data-testid="cancel">
						Cancel
					</Button>
					<Button
						type="primary"
						disabled={!changed}
						onClick={saveChanges}
						style={{ marginLeft: 12 }}
					>
						Done
					</Button>
				</Row>
			}
		>
			{!libOpen ? (
				<Col span={24}>
					<Row justify="center">
						<Row style={styles.imageWrapper}>
							{photo && (
								<img
									src={photo}
									alt="coverphoto"
									style={{ height: 200, width: 800, objectFit: "cover" }}
								/>
							)}
							{!photo && !loading && (
								<Upload
									customRequest={uploadFile}
									onRemove={deleteImage}
									showUploadList={false}
									data-testid="upload"
								>
									<Row
										justify="center"
										align="middle"
										style={{
											height: 200,
											width: 800,
											backgroundColor: colorsLight("grey"),
										}}
									>
										<Col>
											<Row justify="center" align="middle">
												<UserOutlined style={styles.icon} />
											</Row>
											<Row justify="center" align="middle">
												<p
													style={{
														fontSize: 18,
														textAlign: "center",
														color: colorsLight("darkGrey"),
													}}
												>
													Click or drag files to upload
												</p>
											</Row>
										</Col>
									</Row>
								</Upload>
							)}
							{loading && (
								<Row
									justify="center"
									align="middle"
									style={{
										height: 200,
										width: 800,
										backgroundColor: colorsLight("grey"),
									}}
								>
									<Col>
										<Row justify="center" align="middle">
											<Progress type="circle" percent={progress} width={80} />
										</Row>
										<Row justify="center" align="middle">
											<p
												style={{
													fontSize: 18,
													textAlign: "center",
													color: colorsLight("darkGrey"),
												}}
											>
												Click or drag files to upload
											</p>
										</Row>
									</Col>
								</Row>
							)}
							{photo && <DeleteButton />}
						</Row>
					</Row>
					<Row style={{ paddingTop: 24 }}>
						<Button onClick={() => setLibOpen(!libOpen)} type="primary">
							Image Library
						</Button>
					</Row>
				</Col>
			) : (
				<CoverModalLibrary changeImage={changeImage} data={imageLibrary} />
			)}
		</Modal>
	);
};

const styles = {
	imageWrapper: {
		padding: 12,
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		backgroundColor: "white",
	},
	delWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		cursor: "pointer",
		position: "absolute",
		top: 20,
		right: 12,
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
	},
	icon: {
		fontSize: 42,
		color: colorsLight("darkGrey"),
		marginBottom: 12,
	},
};

export default CoverPhotoUploadModal;
