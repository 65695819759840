import { Row, Col, Input, Button, Form, notification } from "antd";
import React, { useContext, useState } from "react";
import { colorsLight } from "../../Styles/Themes";
import logo from "../../Assets/logo-small-dark.png";
import firebase from "../../API/firebase";
import UserContext from "../../Context/UserContext";
import { Redirect } from "react-router-dom";

const Login = () => {
	const [loading, setloading] = useState(false);
	const { setUid, setAuthd, authd, setUserInfo } = useContext(UserContext);

	const login = (val) => {
		setloading(true);
		firebase
			.auth()
			.signInWithEmailAndPassword(val.email, val.password)
			.then(async (user) => {
				setUid(user.user.uid);
				let userData = await firebase
					.firestore()
					.collection("Admins")
					.doc(user.user.uid)
					.get();
				setUserInfo({ ...userData.data(), uid: userData.id });
				return userData;
			})
			.then(() => {
				setAuthd(true);
				setloading(false);
			})
			.catch((error) => {
				console.log(error);
				openNotificationWithIcon("error", error.code, error.message);
				setloading(false);
			});
	};

	const openNotificationWithIcon = (type, title, message) => {
		notification[type]({
			message: title,
			description: message,
		});
	};

	return !authd ? (
		<Row justify="center" align="middle" style={styles.main}>
			<Row style={styles.card}>
				<Col style={styles.image} span={12}></Col>
				<Col style={styles.padding} span={12}>
					<Row justify="center" align="middle">
						<img src={logo} style={styles.logo} alt="Careerbase logo" />
					</Row>
					<Form name="myForm" onFinish={login} required={true} data-testid="login">
						<Form.Item
							name="email"
							rules={[{ required: true, message: "Please enter email" }]}
						>
							<Input placeholder="Email" data-testid="login-email" />
						</Form.Item>
						<Form.Item
							name="password"
							rules={[{ required: true, message: "Please enter password" }]}
						>
							<Input.Password placeholder="Password" data-testid="login-password" />
						</Form.Item>
						<Form.Item>
							<Row justify="end">
								<Button
									loading={loading}
									type="primary"
									htmlType="submit"
									data-testid="login-button"
								>
									Login
								</Button>
							</Row>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Row>
	) : (
		<Redirect to="/Home" />
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
		backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/careerbase-development-2610e.appspot.com/o/websiteAssets%2Floginbg.jpg?alt=media&token=59ae6afe-5cbc-476a-9912-6164ae5c1387)`,
		backgroundSize: "cover",
	},
	padding: {
		padding: 24,
	},
	logo: {
		width: 220,
		marginBottom: 56,
	},
	card: {
		height: 300,
		width: 750,
		backgroundColor: "white",
		borderRadius: 3,
	},
	image: {
		height: "100%",
		width: "100%",
		backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/careerbase-development-2610e.appspot.com/o/websiteAssets%2FloginImage.jpg?alt=media&token=11d7c851-1738-4f2d-97e2-e5a71269eb0b)`,
		backgroundSize: "cover",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default Login;
