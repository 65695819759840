import { Row, Col, Avatar, Divider, Typography, Button, Form, Input, message, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getInitials } from "../../../../Functions/formatters";
import firebase from "../../../../API/firebase";
import ProfilePicUploadModal from "../../../../Components/ProfilePicUploadModal";
import { colorsLight } from "../../../../Styles/Themes";
const { Title } = Typography;

const TeamMemberHeader = ({ data }) => {
	const [editing, setEditing] = useState(false);
	const [loading, setloading] = useState(false);
	const [title, setTitle] = useState("");
	const [hoverProfilePic, setHoverProfilePic] = useState(false);
	const [editProfilePic, setEditProfilePic] = useState(false);
	// const [summary, setSummary] = useState("");

	const toggleEdit = () => setEditing(!editing);
	const toggleEdit1 = () => setEditProfilePic(!editProfilePic);

	useEffect(() => {
		setTitle(data.title);
	}, [data]);

	const editDetails = (val) => {
		setloading(true);
		firebase
			.firestore()
			.collection("Admins")
			.doc(data.key)
			.update({
				firstName: val.firstName,
				lastName: val.lastName,
				email: val.email,
				title,
			})
			.then(() => {
				message.success("Successfully updated profile!");
				setloading(false);
				setEditing(false);
			})
			.catch((err) => {
				message.error("Something went wrong");
				setloading(false);
			});
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Row>
					<Avatar src={data.displayPic} size={80} data-testid="avatar">
						{getInitials(data.displayName || "")}
					</Avatar>
					<Row
						justify="center"
						align="middle"
						onClick={() => editing && setEditProfilePic(true)}
						onMouseEnter={() => setHoverProfilePic(true)}
						onMouseLeave={() => setHoverProfilePic(false)}
						style={{
							height: 80,
							width: 80,
							backgroundColor:
								hoverProfilePic && editing ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)",
							position: "absolute",
							borderRadius: 40,
							cursor: hoverProfilePic && editing ? "pointer" : "initial",
						}}
						data-testid="edit-photo"
					>
						{hoverProfilePic && editing && (
							<p style={{ color: "white", fontWeight: 400, margin: 0 }}>Edit Photo</p>
						)}
					</Row>

					<Col style={{ marginLeft: 12, paddingTop: 6 }}>
						<Title style={{ margin: 0 }} level={2}>
							{data.displayName || "Loading"}
						</Title>
						{!editing ? (
							<p style={{ margin: 0, fontSize: 16, fontWeight: 300 }}>
								{data.title || "Loading"}
							</p>
						) : (
							<Input
								style={{ width: 300 }}
								value={title}
								onChange={(val) => setTitle(val.target.value)}
								data-testid="title"
							/>
						)}
					</Col>
				</Row>
				{!editing ? (
					<Button onClick={toggleEdit} size="small" data-testid="edit">
						Edit Info
					</Button>
				) : (
					<Row>
						<Button
							style={{ marginRight: 12 }}
							size="small"
							onClick={toggleEdit}
							data-testid="cancel"
						>
							Cancel
						</Button>
						<Button
							loading={loading}
							form="editAdmin"
							htmlType="submit"
							size="small"
							type="primary"
							key="submit"
							data-testid="update"
						>
							Update
						</Button>
					</Row>
				)}
			</Row>
			<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			<Typography.Title level={4}>Integrations</Typography.Title>
			{!editing ? (
				<Row>
					<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
						<Row justify="space-between">
							<p className="Key">First Name:</p>
							<p className="Value" data-testid="fname">
								{data.firstName}
							</p>
						</Row>
						<Row justify="space-between">
							<p className="Key">Last Name:</p>
							<p className="Value" data-testid="lname">
								{data.lastName}
							</p>
						</Row>
						<Row justify="space-between">
							<p className="Key">Email:</p>
							<p className="Value" data-testid="email">
								{data.email}
							</p>
						</Row>
					</Col>
					<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
						<Row justify="space-between">
							<p className="Key">Last sign in:</p>
							<p className="Value" data-testid="lastSignIn">
								{data.lastSignIn !== "never"
									? moment(data.lastSignIn).fromNow()
									: "never"}
							</p>
						</Row>
					</Col>
				</Row>
			) : (
				<>
					<Form id="editAdmin" onFinish={editDetails} initialValues={data}>
						<Row>
							<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
								<Row justify="space-between">
									<p className="Key">First Name:</p>
									<Form.Item
										style={{ marginTop: 0, marginBottom: 6 }}
										required={true}
										name="firstName"
									>
										<Input
											style={{ textAlign: "end" }}
											placeholder="Please enter first name"
											data-testid="fname"
										/>
									</Form.Item>
								</Row>
								<Row justify="space-between">
									<p className="Key">Last Name:</p>
									<Form.Item
										style={{ marginTop: 0, marginBottom: 6 }}
										required={true}
										name="lastName"
									>
										<Input
											style={{ textAlign: "end" }}
											placeholder="Please enter surname"
											data-testid="lname"
										/>
									</Form.Item>
								</Row>
								<Row justify="space-between">
									<p className="Key">Email:</p>
									<Form.Item
										style={{ marginTop: 0, marginBottom: 6 }}
										required={true}
										name="email"
									>
										<Input
											style={{ textAlign: "end" }}
											placeholder="Please enter email"
											data-testid="email"
										/>
									</Form.Item>
								</Row>
							</Col>
							<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
								<Row justify="space-between">
									<p className="Key">Last sign in:</p>
									<p className="Value" data-testid="lastSignIn">
										{data.lastSignIn !== "never"
											? moment(data.lastSignIn).fromNow()
											: "never"}
									</p>
								</Row>
							</Col>
						</Row>
					</Form>
				</>
			)}
			<Typography.Title level={4}>Integrations</Typography.Title>
			<Row style={{ marginBottom: 6 }}>
				<Col
					style={{
						borderWidth: 1,
						borderColor: colorsLight("lightgrey"),
						borderRadius: 9,
						borderStyle: "solid",
						padding: 12,
						boxShadow: "1px 2px 2px rgba(0,0,0,0.2)",
					}}
					span={12}
				>
					<Row justify="space-between">
						<p style={{ fontSize: 18, fontWeight: 300, margin: 0 }}>
							Calendar Availability Sync
						</p>
						<div>
							{data.calendarSync ? (
								<Tag style={{ margin: 0 }} color="success" data-testid="active">
									ACTIVE
								</Tag>
							) : (
								<Tag style={{ margin: 0 }} color="error" data-testid="inactive">
									INACTIVE
								</Tag>
							)}
						</div>
					</Row>
					<Row>
						<p style={{ marginRight: 3, marginBottom: 12 }}>Most recent sync count:</p>
						<p style={{ fontWeight: 500, marginBottom: 12 }}>
							{data.calSyncCount || 0}
						</p>
					</Row>
				</Col>
			</Row>
			<ProfilePicUploadModal
				visible={editProfilePic}
				dismiss={toggleEdit1}
				userType="Admins"
				data={data}
			/>
		</Col>
	);
};

export default TeamMemberHeader;
