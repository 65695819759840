import { Avatar, Button, Col, Row, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { getInitials, statusTagFormatter } from "../../../Functions/formatters";
import { Link } from "react-router-dom";

const CoachesTable = ({ data }) => {
	const [filters, setFilters] = useState([]);

	//GETS LIST OF POSSIBLE CATEGORIES FROM DATA
	useEffect(() => {
		let list = [];
		data.forEach((item) => {
			const cat = item.primaryCategory;
			if (!list.includes(cat)) {
				list.push(cat);
			}
		});
		let filts = [];
		list.forEach((item) => {
			filts.push({ text: item, value: item });
		});
		setFilters(filts);
	}, [data]);

	const columns = [
		{
			title: "Photo",
			dataIndex: "displayPic",
			key: "displayPic",
			render: (text, record) => (
				<Avatar size={40} src={text} data-testid="coach-avatar">
					{getInitials(record.displayName)}
				</Avatar>
			),
		},
		{
			title: "First Name",
			dataIndex: "firstName",
			key: "firstName",
		},
		{
			title: "Last Name",
			dataIndex: "lastName",
			key: "lastName",
			sortDirections: ["descend", "ascend"],
			sorter: (a, b) => a.lastName.localeCompare(b.lastName),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Category",
			dataIndex: "primaryCategory",
			key: "primaryCategory",
			filters: filters,
			onFilter: (value, record) => record.primaryCategory.indexOf(value) === 0,
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => (
				<Tag color={statusTagFormatter(text)} data-testid="coach-status">
					{text.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Last Sign-in",
			dataIndex: "lastSignIn",
			key: "lastSignIn",
			render: (text) => (
				<p data-testid="coach-signIn">
					{text !== "never" ? moment(text).fromNow() : "never"}{" "}
				</p>
			),
		},
		{
			title: "Action",
			render: (record) => (
				<Link to={`/Coach/${record.key}`} data-testid="coach-view">
					<Button>View</Button>
				</Link>
			),
		},
	];

	return (
		<Col className="Card" style={styles.card}>
			<Row style={styles.row}>
				<Table
					pagination={{ defaultPageSize: 7 }}
					style={styles.table}
					columns={columns}
					dataSource={data}
				/>
			</Row>
		</Col>
	);
};

const styles = {
	card: {},
	row: {
		width: "100%",
	},
	table: {
		width: "100%",
	},
};

export default CoachesTable;
