import { Row } from "antd";
import React from "react";

const AccountDisabledAlert = () => {
	return (
		<Row style={styles.banner} justify="center" align="middle">
			<h3 style={styles.text} data-testid="account-disabled">
				THIS USER'S ACCOUNT HAS BEEN DISABLED!
			</h3>
		</Row>
	);
};

const styles = {
	banner: {
		backgroundColor: "#e04f4f",
		width: "100%",
		marginBottom: 12,
		marginTop: -12,
		borderRadius: 4,
	},
	text: {
		color: "white",
		margin: 0,
	},
};

export default AccountDisabledAlert;
