import { Row, Col, Modal, Form, Input, Button } from "antd";
import React, { useState } from "react";
import firebase from "../../../API/firebase";
import moment from "moment";

const AddAccount = ({ visible, dismiss }) => {
	const [, setloading] = useState(false);

	const addAcc = (val) => {
		setloading(true);
		const payload = {
			name: val.companyName,
			websiteUrl: val.websiteUrl,
			primaryContactName: "",
			primaryContactEmail: "",
			primaryContactMobile: "",
			created: moment().format(),
			status: "inactive",
		};
		firebase
			.firestore()
			.collection("Accounts")
			.add(payload)
			.then(() => {
				setloading(false);
				dismiss();
			})
			.catch((err) => {
				setloading(false);
			});
	};

	return (
		<Modal
			visible={visible}
			onCancel={dismiss}
			title="Add a new account"
			footer={
				<Row justify="end">
					<Button onClick={dismiss} data-testid="account-cancel">
						Cancel
					</Button>
					<Button
						style={{ marginLeft: 12 }}
						type="primary"
						htmlType="submit"
						form="addAccount"
						data-testid="account-create"
					>
						Create
					</Button>
				</Row>
			}
		>
			<Col span={24}>
				<Row style={{ width: "100%" }}>
					<Form
						style={{ width: "100%" }}
						layout="vertical"
						id="addAccount"
						onFinish={addAcc}
					>
						<Form.Item name="companyName" label="Company Name">
							<Input
								style={{ width: "100%" }}
								placeholder="Enter company name"
								data-testid="account-company"
							/>
						</Form.Item>
						<Form.Item name="websiteUrl" label="Company Website">
							<Input
								style={{ width: "100%" }}
								placeholder="Please enter company website URL"
								data-testid="account-website"
							/>
						</Form.Item>
					</Form>
				</Row>
			</Col>
		</Modal>
	);
};

export default AddAccount;
