import { Button, Divider, Row, Typography, Col, message, Popconfirm } from "antd";
import { app, database } from "firebase";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { priceFormatter } from "../../../../Functions/formatters";

const MemberQuickActions = ({ data }) => {
	const [loading, setLoading] = useState(false);
	const [loading1, setloading1] = useState(false);
	const [loading2, setloading2] = useState(false);
	const history = useHistory();

	const inviteMember = async () => {
		setLoading(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("InviteMember")({
				name: data.firstName,
				email: data.email,
				uid: data.key,
				env:
					process.env.REACT_APP_PROJECT_ID === "careerbase-development-2610e"
						? "development"
						: "production",
			})
			.then((res) => {
				setLoading(false);
				console.log(res);
				message.success("Successfully invited member");
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};

	const activateLedger = () => {
		setloading1(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("ActivateLedger")({ uid: data.key })
			.then((res) => {
				setloading1(false);
				console.log(res);
			})
			.catch((err) => message.error(err));
	};

	const deleteMember = () => {
		setloading2(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("DeleteUser")({ uid: data.key, type: "Members" })
			.then((res) => {
				message.success("This member is no more...");
				console.log(res.data);
				setloading2(false);
				history.goBack();
			})
			.catch((err) => {
				console.log(err);
				message.error("Something went wrong, check log");
				setloading2(false);
			});
	};

	return (
		<Col className="Card">
			<Typography.Title level={4}>Quick Actions</Typography.Title>
			<Divider style={{ margin: "0px 0px 12px 0px" }} />
			<Row gutter={12}>
				<Col span={12}>
					<Button onClick={inviteMember} loading={loading} style={{ width: "100%" }}>
						Invite
					</Button>
				</Col>
				<Col span={12}>
					<Popconfirm
						title="Are you sure you want to delete this member? This cannot be undone!"
						onConfirm={deleteMember}
					>
						<Button loading={loading2} type="danger" style={{ width: "100%" }}>
							Delete
						</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Row gutter={12} style={{ ...styles.row, paddingTop: 12 }}>
				<Col span={12}>
					<Button
						onClick={activateLedger}
						loading={loading1}
						style={{ width: "100%" }}
						data-testid="edit"
					>
						Activate Ledger
					</Button>
				</Col>
				<Col span={12}></Col>
			</Row>
		</Col>
	);
};

const styles = {};

export default MemberQuickActions;
