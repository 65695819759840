import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Layout, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import MenuMain from "../../Components/MainMenu";
import BackButton from "../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";
import AddPlaylistModal from "./Components/AddPlaylistModal";
import PlaylistItem from "./Components/PlaylistItem";

const { Header, Content } = Layout;

const PlaylistsPage = ({ history }) => {
	const [items, setItems] = useState([]);
	const [modal, setModal] = useState(false);

	useEffect(() => {
		let listener = firestore()
			.collection("Playlists")
			.onSnapshot((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setItems(list);
			});

		return listener;
	}, []);

	const toggleModal = () => setModal(!modal);

	return (
		<Layout className="App">
			<MenuMain active="c" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							padding: 24,
						}}
					>
						<Col span={24} style={{ maxWidth: maxContentWidth }}>
							<Row>
								<Col span={24}>
									<Row justify="space-between" align="middle">
										<Typography.Title level={1}>Playlists</Typography.Title>
										<Button
											onClick={toggleModal}
											icon={<PlusOutlined />}
											type="primary"
										>
											Create New Playlist
										</Button>
									</Row>
									<Divider style={{ margin: 0 }} />
									<Row style={{ marginTop: 24 }} gutter={24}>
										{items.map((item, index) => (
											<Col key={index} span={8}>
												<PlaylistItem data={item} />
											</Col>
										))}
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
			<AddPlaylistModal visible={modal} dismiss={toggleModal} />
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default PlaylistsPage;
