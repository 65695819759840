import { Row, Col, Layout } from "antd";
import React, { useEffect, useState } from "react";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import { colorsLight } from "../../../Styles/Themes";
import { useParams } from "react-router-dom";
import BackButton from "../../../Components/Navigation/BackButton";
import AccountDisabledAlert from "../../../Components/Tools/UserDisabledAlert";
import AccountViewHeader from "./Components/AccountView-Header";
import AccountViewQuickActions from "./Components/AccountViewQuickActions";
import AccountsAccountLedger from "./Components/AccountsAccLedger";
import { firestore } from "firebase";
import AccountRiskManagementPanel from "./Components/AccountRiskManagementPanel";

const { Header, Content } = Layout;

const AccountViewPage = () => {
	const [data, setData] = useState({});
	const { accountId } = useParams();

	useEffect(() => {
		const listener = firestore()
			.collection("Accounts")
			.doc(accountId)
			.onSnapshot((doc) => {
				setData({ ...doc.data(), key: doc.id });
			});

		return () => {
			listener();
		};
	}, [accountId]);

	return (
		<Layout className="App">
			<MenuMain active="9" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton to="/Accounts" />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						{data.status === "disabled" && <AccountDisabledAlert />}
						<Row style={styles.row}>
							<Col span={16}>
								<AccountViewHeader data={data} />
								<AccountsAccountLedger data={data} />
							</Col>
							<Col style={{ paddingLeft: 24 }} span={8}>
								<AccountViewQuickActions data={data} />
								<AccountRiskManagementPanel accountId={accountId} />
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 22,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default AccountViewPage;
