import { Row, Col, Avatar, Divider, Typography, Button } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { colorsLight } from "../../../../Styles/Themes";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import ProfilePicUploadModal from "../../../../Components/ProfilePicUploadModal";
import CoverPhotoUploadModal from "../../../../Components/CoverPhotoUploadModal";
const { Title } = Typography;

const AccountViewHeader = ({ data, privateData }) => {
	const [hoverProfilePic, setHoverProfilePic] = useState(false);
	// const [loading, setloading] = useState(false);
	const [editProfilePic, setEditProfilePic] = useState(false);
	const [editCover, setEditCover] = useState(false);
	const [editing, setEditing] = useState(false);

	const imageBackground = {
		backgroundImage: `url(${data.coverPic})`,
		backgroundColor: colorsLight("grey"),
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: 130,
	};

	const toggleEdit1 = () => {
		setEditProfilePic(false);
	};

	const toggleEdit2 = () => {
		setEditCover(false);
	};

	return (
		<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
			<Row justify="end" align="top" style={imageBackground}>
				<Row
					onClick={() => setEditCover(true)}
					justify="center"
					align="middle"
					style={styles.editIconWrapper}
					data-testid="account-edit">
					<EditOutlined style={styles.editIcon} />
				</Row>
			</Row>
			<Row justify="space-between">
				<Row>
					<Row justify="center" align="middle" style={styles.imageWrapper}>
						<Avatar src={data.displayPic} size={130} icon={<UserOutlined />} data-testid="account-avatar" />
						<Row
							justify="center"
							align="middle"
							onClick={() => setEditProfilePic(true)}
							onMouseEnter={() => setHoverProfilePic(true)}
							onMouseLeave={() => setHoverProfilePic(false)}
							style={{
								height: 130,
								width: 130,
								backgroundColor: hoverProfilePic ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)",
								position: "absolute",
								borderRadius: 65,
								cursor: "pointer",
							}}
							data-testid="account-editP">
							{hoverProfilePic && <p style={{ color: "white", fontWeight: "bold", margin: 0 }}>Edit Photo</p>}
						</Row>
					</Row>
					<Col style={{ paddingLeft: 12, marginTop: 6 }}>
						<Title style={{ margin: 0 }} level={1} data-testid="account-name">
							{data.name}
						</Title>
						<Title style={{ margin: 0, fontWeight: 300, marginLeft: 2 }} level={4} data-testid="account-category">
							{data.primaryCategory}
						</Title>
					</Col>
				</Row>
				<Row style={{ padding: 12 }}>
					<Button>Edit Details</Button>
				</Row>
			</Row>

			<Row style={styles.paddingHoro}>
				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			</Row>
			<Title style={{ marginLeft: 24 }} level={4}>
				Basic Info:
			</Title>
			<Row style={styles.paddingHoro}>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
					<Row justify="space-between">
						<p className="Key">First Name:</p>
						<p className="Value" data-testid="account-fname">
							{data.firstName}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Last Name:</p>
						<p className="Value" data-testid="account-lname">
							{data.lastName}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Email:</p>
						<p className="Value" data-testid="account-email">
							{data.email}
						</p>
					</Row>
				</Col>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
					<Row justify="space-between">
						<p className="Key">Last Seen:</p>
						<p className="Value" data-testid="account-seen">
							{data.lastSignIn === "never" ? "Never" : moment(data.lastSignIn).fromNow()}
						</p>
					</Row>

					<Row justify="space-between">
						<p className="Key">Member Since:</p>
						<p className="Value" data-testid="account-date">
							{moment(data.created).format("Do MMMM YYYY")}
						</p>
					</Row>
				</Col>
			</Row>
			<Row style={styles.paddingHoro}>
				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			</Row>
			<Title style={{ marginLeft: 24 }} level={4} data-testid="account-business">
				Business Details:
			</Title>

			<ProfilePicUploadModal visible={editProfilePic} dismiss={toggleEdit1} userType="Accounts" data={data} />
			<CoverPhotoUploadModal visible={editCover} dismiss={toggleEdit2} userType="Accounts" data={data} />
		</Col>
	);
};

const styles = {
	imageWrapper: {
		height: 142,
		width: 142,
		borderRadius: 71,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginTop: -60,
		marginLeft: 24,
	},
	paddingHoro: {
		paddingLeft: 24,
		paddingRight: 24,
	},
	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
};

export default AccountViewHeader;
