/* eslint-disable no-unused-vars */
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
	Button,
	Col,
	Divider,
	Drawer,
	Form,
	Input,
	InputNumber,
	notification,
	Row,
	Select,
	Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "../../../../Styles/quill.snow.css";
import firebase from "../../../../API/firebase";
const { Text } = Typography;
const { Option } = Select;

const AddCourse = ({ dismiss, visible, providerId, data }) => {
	const [navForward, setNavForward] = useState(false);
	const [loading, setLoading] = useState(false);
	const [description, setDescription] = useState("");

	const [pid, setPid] = useState("");

	const now = new Date();

	const openNotificationWithIcon = (type, error) => {
		notification[type]({
			message: "Something has gone wrong.",
			description: error,
		});
	};

	const createPackage = (val) => {
		setLoading(true);
		let obj = {
			details: description || "missing data",
			owner: providerId || "missing data",
			created: moment(now).format() || "missing data",
			updated: moment(now).format() || "missing data",
			displayPic: data.displayPic,
			providerName: data.name,
			coverPic: "",
			cpFileRef: "",
			status: "pending",
			availability: "anytime",
			dates: [],
		};
		firebase
			.firestore()
			.collection("CourseProviders")
			.doc(providerId)
			.collection("Courses")
			.add({ ...obj, ...val })
			.then((res) => {
				setPid(res.id);
				setLoading(false);
				dismiss();
			})
			.catch((error) => {
				openNotificationWithIcon("error", error);
			});
	};

	return (
		<Drawer
			destroyOnClose={true}
			title="Add New Course"
			width={720}
			onClose={dismiss}
			visible={visible}
			bodyStyle={{ paddingBottom: 80 }}
			footer={
				<div
					style={{
						textAlign: "right",
					}}
				>
					<Button onClick={dismiss} style={{ marginRight: 8 }} data-testid="cancel">
						Cancel
					</Button>
					<Button
						type="primary"
						form="myForm"
						loading={loading}
						key="submit"
						htmlType="submit"
						data-testid="create"
					>
						Create
					</Button>
				</div>
			}
		>
			<Form
				id="myForm"
				layout="vertical"
				hideRequiredMark
				onFinish={createPackage}
				initialValues={{ sessions: [{}], commRate: 25 }}
			>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="name"
							label="Course Name:"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: "Please enter course name",
								},
							]}
						>
							<Input
								allowClear={true}
								placeholder="Please enter course name"
								data-testid="name"
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="costPrice"
							label="Cost Price:"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: "Please enter package pricing",
								},
							]}
						>
							<InputNumber
								style={{ width: "100%" }}
								allowClear={true}
								placeholder="Please enter package pricing"
								formatter={(value) =>
									`$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
								data-testid="price"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					{data.courseAttributes &&
						data.courseAttributes.map((item, index) => (
							<Col key={index} span={12}>
								{item.type === "Text Input" && (
									<Form.Item
										name={item.ref}
										label={`${item.label}:`}
										style={{ width: "100%" }}
									>
										<Input
											allowClear={true}
											placeholder={`Please enter ${item.label.toLowerCase()}`}
											data-testid={item.ref}
										/>
									</Form.Item>
								)}
								{item.type === "Selection" && (
									<Form.Item
										name={item.ref}
										label={`${item.label}:`}
										style={{ width: "100%" }}
									>
										<Select
											allowClear={true}
											placeholder={`Please select ${item.label.toLowerCase()}`}
											data-testid={item.ref}
										>
											{item.options.map((item, index) => (
												<Select.Option key={index} value={item}>
													{item}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								)}
							</Col>
						))}
				</Row>

				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="summary"
							label="Course Summary:"
							style={{ width: "100%" }}
							rules={[
								{
									required: true,
									message: "Please enter course summary",
								},
							]}
						>
							<TextArea
								style={{ width: "100%" }}
								placeholder="Please enter summary"
								data-testid="summary"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<p>Course Details:</p>
						<ReactQuill
							value={description}
							onChange={setDescription}
							placeholder="Pleast enter course details"
							theme="snow"
							style={{ width: "100%" }}
							data-testid="details"
						/>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
};

export default AddCourse;
