import { Col, Divider, Row, Typography } from "antd";
import moment from "moment";
import React from "react";

const ScheduleItem = (input) => {
	const item = input.input;
	return (
		<Col style={{ marginBottom: 24 }}>
			<Row>
				<p style={{ margin: 0 }}>{moment(item.startTime).format("Do MMMM @ h:mma")}</p>
			</Row>
			<Row>
				<Typography.Title style={{ margin: 0 }} level={5}>
					{item.memberTitle || item.name}
				</Typography.Title>
			</Row>
		</Col>
	);
};

const ProgramUpcomingEvents = ({ events = [] }) => {
	return (
		<Col className="Card">
			<Row>
				<Typography.Title level={4}>Upcoming Events</Typography.Title>
			</Row>
			<Divider style={{ margin: "0px 0px 12px 0px" }} />
			{events.map((item, index) => (
				<ScheduleItem input={item} key={index} />
			))}
		</Col>
	);
};

export default ProgramUpcomingEvents;
