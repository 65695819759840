import { Row, Modal, Col, message, Form, Input, Select, Button } from "antd";

import { firestore, functions, app } from "firebase";

import React, { useEffect, useState } from "react";

const AddMemberModal = ({ visible, dismiss }) => {
	const [accounts, setAccounts] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		firestore()
			.collection("Accounts")
			.get()
			.then((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setAccounts(list);
			})
			.catch((err) => {
				message.error(err);
			});
	}, []);

	const createMember = (val) => {
		setLoading(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("createMember")({ userData: val })
			.then((res) => {
				setLoading(false);
				console.log(res);
				dismiss();
			})
			.catch((err) => {
				setLoading(false);
				message.error(err);
			});
	};

	return (
		<Modal
			visible={visible}
			onCancel={dismiss}
			title="Add new member"
			footer={
				<Row justify="end" gutter={12}>
					<Col>
						<Button>Cancel</Button>
					</Col>
					<Col>
						<Button loading={loading} htmlType="submit" form="addMember" type="primary">
							Create
						</Button>
					</Col>
				</Row>
			}>
			<Col span={24}>
				<Form onFinish={createMember} id="addMember" layout="vertical">
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item label="First Name" name="firstName">
								<Input placeholder="Please enter first name" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Last Name" name="lastName">
								<Input placeholder="Please enter last name" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item label="Email" name="email">
								<Input placeholder="Please enter email" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Parent Account" name="parentAccount">
								<Select placeholder="Please select parent account">
									{accounts.map((item, index) => (
										<Select.Option key={index} value={item.key}>
											{item.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>
		</Modal>
	);
};

export default AddMemberModal;
