import { Row, Col, Typography, Button, message } from "antd";
import { app } from "firebase";
import React, { useState } from "react";
import EditAccountBasicDetails from "./EditAccountBasicDetails";

const { Title } = Typography;

const AccountViewQuickActions = ({ data }) => {
	const [edit, setedit] = useState(false);
	// const [editBankDetails, setEditBankDetails] = useState(false);
	const [loading] = useState(false);
	const [loading1, setloading1] = useState(false);
	// const [editComms, setEditComms] = useState(false);

	const dismissEdit = () => {
		setedit(false);
	};

	// const dismissEdit2 = () => {
	// 	setEditComms(false);
	// };

	// const dismissEdit3 = () => {
	// 	setEditBankDetails(false);
	// };

	// const openNotificationWithIcon = (type, title, message) => {
	// 	notification[type]({
	// 		message: title,
	// 		description: message,
	// 	});
	// };

	const activateLedger = () => {
		setloading1(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("ActivateLedger")({ uid: data.key })
			.then((res) => {
				setloading1(false);
				console.log(res);
			})
			.catch((err) => message.err);
	};

	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Quick Actions</Title>
			</Row>
			<Row gutter={12} style={styles.row}>
				<Col span={12}>
					<Button style={styles.button} data-testid="edit">
						Disable Account
					</Button>
				</Col>
				<Col span={12}>
					<Button type="danger" loading={loading} style={styles.button} data-testid="reset">
						Delete Account
					</Button>
				</Col>
			</Row>
			<Row gutter={12} style={{ ...styles.row, paddingTop: 12 }}>
				<Col span={12}>
					<Button onClick={activateLedger} loading={loading1} style={styles.button} data-testid="edit">
						Activate Ledger
					</Button>
				</Col>
			</Row>

			<EditAccountBasicDetails visible={edit} dismiss={dismissEdit} data={data} />
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default AccountViewQuickActions;
