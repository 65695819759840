import { EllipsisOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Divider, Dropdown, Menu, message, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { colorsLight } from "../../../Styles/Themes";
import moment from "moment";
import EditPlaylistModal from "./EditPlaylistModal";

const PlaylistItem = ({ data }) => {
	const [modal, setModal] = useState(false);
	const duplicatePlaylist = () => {
		firestore()
			.collection("Playlists")
			.add({
				coverPic: data.coverPic,
				coverPicOwner: false,
				packages: data.packages,
				courses: data.courses,
				services: data.services,
				summary: data.summary,
				name: data.name,
				type: data.type,
				owner: data.owner || "",
				ownerName: data.ownerName || "",
				created: moment().format(),
			})
			.then(() => {
				message.success("Successfully duplicated playlist");
			})
			.catch((err) => message.error(err));
	};

	const deletePlaylist = () => {
		firestore().collection("Playlists").doc(data.key).delete();
	};

	const toggleModal = () => setModal(!modal);

	const menu = (
		<Menu>
			<Menu.Item onClick={toggleModal}>Edit</Menu.Item>
			<Menu.Item onClick={duplicatePlaylist}>Duplicate</Menu.Item>
			<Menu.Item onClick={deletePlaylist}>Delete</Menu.Item>
		</Menu>
	);

	return (
		<Badge.Ribbon
			color={data.type === "Public" ? "green" : "red"}
			placement="start"
			text={data.type.toUpperCase()}
		>
			<Col className="Card" style={{ padding: 0, overflow: "hidden" }} span={24}>
				<Row
					style={{
						backgroundImage: `url(${data.coverPic})`,
						backgroundPosition: "center",
						backgroundSize: "cover",
						height: "100%",
						width: "100%",
						padding: 0,
						overflow: "hidden",
					}}
				>
					<Row
						justify="start"
						align="top"
						style={{
							height: "100%",
							width: "100%",
							backgroundColor: colorsLight("cardCover"),
							padding: 12,
						}}
					>
						<Typography.Title style={{ color: "white", marginTop: 20 }} level={3}>
							{data.name}
						</Typography.Title>
						<Dropdown overlay={menu} trigger={["click"]}>
							<Row
								style={{
									position: "absolute",
									top: 6,
									right: 12,
									cursor: "pointer",
								}}
							>
								<EllipsisOutlined style={{ color: "white", fontSize: 24 }} />
							</Row>
						</Dropdown>
					</Row>
				</Row>
				<Row style={{ padding: "6px 0px 6px 0px" }}>
					<Row style={{ width: "100%" }}>
						<Col span={8}>
							<Row justify="center" align="middle">
								<p style={{ margin: 0 }} className="Key">
									Coaches
								</p>
							</Row>

							<Row justify="center" align="middle">
								<p style={{ margin: 0 }} className="Value">
									{data.packages.length || 0}
								</p>
							</Row>
						</Col>
						<Col span={8}>
							<Row justify="center" align="middle">
								<p style={{ margin: 0 }} className="Key">
									Courses
								</p>
							</Row>

							<Row justify="center" align="middle">
								<p style={{ margin: 0 }} className="Value">
									{data.courses.length || 0}
								</p>
							</Row>
						</Col>
						<Col span={8}>
							<Row justify="center" align="middle">
								<p style={{ margin: 0 }} className="Key">
									Services
								</p>
							</Row>

							<Row justify="center" align="middle">
								<p style={{ margin: 0 }} className="Value">
									{data.services.length || 0}
								</p>
							</Row>
						</Col>
					</Row>
					<Col style={{ padding: 12 }} span={24}>
						<Divider style={{ margin: "0px 0px 6px 0px" }} />
						<p className="Key">Summary</p>
						<Typography.Paragraph
							style={{ height: 44 }}
							ellipsis={{
								rows: 3,
								expandable: false,
							}}
						>
							{data.summary}
						</Typography.Paragraph>
						<Row>
							<Col span={12}>
								<p className="Key">Created</p>
								<p style={{ height: 44 }} className="Value">
									{moment(data.created).format("Do MMM YYYY @ h:mm a")}
								</p>
							</Col>
							{data.ownerName && (
								<Col span={12}>
									<p className="Key">Owner</p>
									<p className="Value">{data.ownerName}</p>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
				<Row gutter={12} justify="end" style={{ padding: 12 }}>
					<Button onClick={toggleModal} style={{ marginRight: 12 }}>
						Edit
					</Button>

					<Link to={`/View-Playlist/${data.key}`}>
						<Button>View Playlist</Button>
					</Link>
				</Row>
				<EditPlaylistModal visible={modal} dismiss={toggleModal} data={data} />
			</Col>
		</Badge.Ribbon>
	);
};

export default PlaylistItem;
