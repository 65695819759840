/* eslint-disable jsx-a11y/anchor-is-valid */
import { Row, Col, Divider, Typography, message, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { markupCalculator } from "../../../../../Functions/formatters";
import firebase from "../../../../../API/firebase";
import moment from "moment";
const { Title } = Typography;

const ServiceStoreStatus = ({ data, markup }) => {
	// const [modal, setModal] = useState(false);
	const [activeInStore, setActiveInStore] = useState("checking");
	const [storeData, setStoreData] = useState("checking");
	const [priceCheck, setPriceCheck] = useState("checking");
	const [syncStat, setSyncStat] = useState("checking");
	const [, setloading] = useState(false);

	useEffect(() => {
		if (data.key && markup) {
			firebase
				.firestore()
				.collection("Services")
				.doc(data.key)
				.get()
				.then((doc) => {
					if (doc.exists) {
						const docData = { ...doc.data(), key: doc.id };
						setActiveInStore("inStore");
						setStoreData(docData);
						markup &&
							data.costPrice &&
							priceChecker(data.costPrice, doc.data().price, markup);
						!markup && console.log("Missing markup data is store status checker");
						!data.costPrice &&
							console.log("Missing cost price data is store status checker");
						return docData;
					} else {
						setActiveInStore("notFound");
						setStoreData("notFound");
						setPriceCheck("notFound");
						return;
					}
				})
				.then((dat) => {
					data && dat && syncStatus(data, dat);
				})
				.catch((err) => {
					setActiveInStore("notFound");
					setStoreData("notFound");
					setPriceCheck("notFound");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, markup]);

	const checkStore = () => {
		if (data.key && markup) {
			firebase
				.firestore()
				.collection("Services")
				.doc(data.key)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setActiveInStore("inStore");
						setStoreData({ ...doc.data(), key: doc.id });
						markup &&
							data.costPrice &&
							priceChecker(data.costPrice, doc.data().price, markup);
						!markup && console.log("Missing markup data is store status checker");
						!data.costPrice &&
							console.log("Missing cost price data is store status checker");
						message.success("Checked store!");
					} else {
						setActiveInStore("notFound");
						setStoreData("notFound");
						setPriceCheck("notFound");
						setSyncStat("notFound");
					}
				})
				.catch((err) => {
					message.error("Could not check store");
				});
		}
	};

	const priceChecker = (realPrice, storePrice, markupRate) => {
		let markupRatePerc = markupRate / 100;
		let markupAmount = realPrice * markupRatePerc;

		let actual = realPrice + markupAmount;
		let desiredPrice = Number(actual.toFixed(2));
		let checkPrice = Number(storePrice.toFixed(2));
		if (desiredPrice === checkPrice) {
			setPriceCheck("pass");
		} else {
			console.log(desiredPrice);
			console.log(checkPrice);
			console.log(`Fail: price should be ${desiredPrice} and is ${actual} `);
			setPriceCheck("fail");
		}
	};

	const dateChecker = (a, b) => {
		let check = b.dates.length;
		let test = 0;
		a.dates.forEach((item, index) => {
			const bDate = b.dates[index] || "";

			if (
				item.startTime === bDate.startTime &&
				item.endTime === bDate.endTime &&
				item.location === bDate.location &&
				item.details === bDate.details
			) {
				test++;
			} else {
				return false;
			}
		});

		if (test === check) {
			return true;
		} else {
			return false;
		}
	};

	const syncStatus = (a, b) => {
		if (a && b) {
			let summary = a.summary === b.summary;
			let details = a.details === b.details;
			let availability = a.availability === b.availability;
			let dates = dateChecker(a, b);
			let coverPic = a.coverPic === b.coverPic;
			if ((summary, details, availability, coverPic, dates)) {
				setSyncStat("pass");
			} else {
				const errors = {
					summary: summary,
					details: details,
					availability: availability,
					dates: dates,
					coverPic: coverPic,
				};
				console.log(errors);
				setSyncStat("fail");
			}
		} else {
			setSyncStat("fail");
		}
	};

	//SYNCS THE SOURCE OF TRUTH RECORD WITH THE STORE FRONT RECORD
	const syncFunction = () => {
		setloading(true);
		const db = firebase.firestore();
		if (data.status === "inactive" && storeData.key) {
			db.collection("Services")
				.doc(storeData.key)
				.delete()
				.then(() => {
					message.success("We fixed a problem for you!");
					return db
						.collection("ServiceProviders")
						.doc(data.owner)
						.collection("Services")
						.doc(data.key)
						.update({ lastStoreSync: moment().format() });
				})
				.then(() => {
					setloading(false);
				})
				.catch(() => {
					message.error("Could not perform sync");
				});
		} else if (data.status === "pending" && storeData.key) {
			db.collection("Services")
				.doc(storeData.key)
				.delete()
				.then(() => {
					message.success("We fixed a problem for you!");
					return db
						.collection("ServiceProviders")
						.doc(data.owner)
						.collection("Services")
						.doc(data.key)
						.update({ lastStoreSync: moment().format() });
				})
				.then(() => {
					setloading(false);
				})
				.catch(() => {
					message.error("Could not perform sync");
				});
		} else if (data.status === "disabled" && storeData.key) {
			db.collection("Services")
				.doc(storeData.key)
				.delete()
				.then(() => {
					return db
						.collection("ServiceProviders")
						.doc(data.owner)
						.collection("Services")
						.doc(data.key)
						.update({ lastStoreSync: moment().format() });
				})
				.then(() => {
					setloading(false);
				})
				.catch(() => {
					message.error("Could not perform sync");
				});
		} else if (data.status === "active" && storeData.key) {
			let price1 = markupCalculator(data.costPrice, markup);

			db.collection("Services")
				.doc(storeData.key)
				.update({
					name: data.name || "missing data",
					price: price1 || "missing data",
					summary: data.summary,
					details: data.details || "missing data",
					owner: data.owner || "missing data",
					updated: moment().format() || "missing data",
					displayPic: data.displayPic,
					providerName: data.providerName,
					coverPic: data.coverPic,
					cpFileRef: data.cpFileRef,
					availability: data.availability,
					dates: data.dates,
					status: "active",
					qualificationCode: data.qualificationCode || "",
					duration: data.duration || "",
					ServiceStructure: data.ServiceStructure || "",
					delivery: data.delivery || "",
					entryRequirements: data.entryRequirements || "",
					awardedBy: data.awardedBy || "",
					ServiceType: data.ServiceType || "",
				})
				.then(() => {
					return db
						.collection("ServiceProviders")
						.doc(data.owner)
						.collection("Services")
						.doc(data.key)
						.update({ lastStoreSync: moment().format() });
				})
				.then(() => {
					setloading(false);
				})
				.catch(() => {
					message.error("Could not perform sync");
				});
		} else if (data.status === "pending") {
			setloading(false);
			message.info("Please activate this Service before syncing");
		} else {
			message.error("why though?");
		}
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Title level={5}>Store Status Checks</Title>
				<Row>
					<a
						onClick={checkStore}
						style={{ marginTop: 1.5, marginRight: 12 }}
						data-testid="check"
					>
						Check
					</a>
					<a
						onClick={syncFunction}
						style={{ marginTop: 1.5, marginRight: 12 }}
						data-testid="sync"
					>
						Sync
					</a>
				</Row>
			</Row>
			<Divider style={{ marginTop: 0, marginBottom: 12 }} />
			<Row justify="space-between" style={styles.row}>
				<p className="Key">Presence:</p>
				<Col data-testid="presence">
					{activeInStore === "checking" && <Tag color="processing">CHECKING</Tag>}
					{activeInStore === "notFound" && <Tag color="warning">NOT ACTIVE</Tag>}
					{activeInStore === "inStore" && <Tag color="success">PASS</Tag>}
				</Col>
			</Row>
			<Row justify="space-between" style={{ ...styles.row, marginTop: 12 }}>
				<p className="Key">Price check:</p>
				<Col data-testid="price-check">
					{priceCheck === "checking" && <Tag color="processing">CHECKING</Tag>}
					{priceCheck === "notFound" && <Tag color="warning">NOT ACTIVE</Tag>}
					{priceCheck === "fail" && <Tag color="error">FAIL</Tag>}
					{priceCheck === "pass" && <Tag color="success">PASS</Tag>}
				</Col>
			</Row>
			<Row justify="space-between" style={{ ...styles.row, marginTop: 12 }}>
				<p className="Key">Sync status:</p>
				<Col data-testid="sync-status">
					{syncStat === "checking" && <Tag color="processing">CHECKING</Tag>}
					{syncStat === "notFound" && <Tag color="warning">NOT ACTIVE</Tag>}
					{syncStat === "fail" && <Tag color="error">FAIL</Tag>}
					{syncStat === "pass" && <Tag color="success">PASS</Tag>}
				</Col>
			</Row>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default ServiceStoreStatus;
