import React, { useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select } from "antd";

const { Option } = Select;

const EditAccountBasicDetails = ({ visible, dismiss, data }) => {
	const [loading] = useState(false);

	const editUser = (val) => {};

	// const openNotificationWithIcon = (type) => {
	// 	notification[type]({
	// 		message: "Something went wrong",
	// 		description: "Please try again or contact support if error persists.",
	// 	});
	// };

	return (
		<Drawer
			destroyOnClose={true}
			visible={visible}
			title="Edit account details"
			width={600}
			onClose={dismiss}
			footer={
				<Row justify="end">
					<Button onCancel={dismiss} data-testid="cancel">
						Cancel
					</Button>
					<Button
						type="primary"
						form="myForm"
						loading={loading}
						key="submit"
						htmlType="submit"
						style={{ marginLeft: 12 }}
						data-testid="update"
					>
						Update
					</Button>
				</Row>
			}
		>
			<Form
				id="myForm"
				onFinish={editUser}
				layout="vertical"
				initialValues={data}
				hideRequiredMark
			>
				{/* <ProfilePicUploader userType="Accounts" data={data} /> */}
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="firstName"
							label="First Name"
							rules={[{ required: true, message: "Please enter user's first name" }]}
						>
							<Input placeholder="Please enter first name" data-testid="fname" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="lastName"
							label="Last Name"
							rules={[{ required: true, message: "Please enter user's last name" }]}
						>
							<Input placeholder="Please enter last name" data-testid="lname" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="email"
							label="Email"
							rules={[
								{ required: true, message: "Please enter user's email address" },
							]}
						>
							<Input placeholder="Please enter email address" data-testid="email" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="primaryCategory"
							label="Primary Cateogory"
							rules={[{ required: true, message: "Please choose the approver" }]}
						>
							<Select placeholder="Please choose permissions" data-testid="category">
								<Option value="Career Development">Career Development</Option>
								<Option value="Multidisciplinary Coach">
									Multidisciplinary Coach
								</Option>
								<Option value="Job Search">Job Search</Option>
								<Option value="Executive Coach">Executive Coach</Option>
								<Option value="Fitness Instructor">Fitness Instructor</Option>
								<Option value="Wellbeing Coach">Wellbeing Coach</Option>
								<Option value="Nutrition Coach">Nutrition Coach</Option>
								<Option value="Financial Coaching">Financial Coaching</Option>
								<Option value="Parental Leave">Parental Leave</Option>
								<Option value="Entrepreneurship Coach">
									Entrepreneurship Coach
								</Option>
								<Option value="Personal Branding Coach">
									Personal Branding Coach
								</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
};

export default EditAccountBasicDetails;
