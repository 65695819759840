import { Row, Col } from "antd";
import React from "react";
import { colorsLight } from "../Styles/Themes";

const MobileLandingPage = () => {
	return (
		<Col style={{ height: "100vh", backgroundColor: colorsLight("primary") }} span={24}>
			<Row justify="center" align="middle" style={{ height: "100%", width: "100%" }}>
				<p style={{ color: "white", fontWeight: "bold", fontSize: 18 }}>Careerbase is best experienced on a larger screen.</p>
			</Row>
		</Col>
	);
};

export default MobileLandingPage;
