import React from "react";
import { Col, Row, Avatar } from "antd";
import { getInitials } from "../../../Functions/formatters";
import { getImage } from "../../../Functions/utilities";
import { Link } from "react-router-dom";

const ServiceProviderCard = ({ data = {}, loading }) => {
	const styles = {
		headerImage: {
			backgroundImage: `url(${data.coverPic ? data.coverPic : getImage()})`,
			backgroundSize: "cover",
			height: 150,
			borderRadius: 9,
			margin: "0px 36px 36px 10px",
			width: 380,
			cursor: "pointer",
		},
		name: {
			fontSize: 18,
			color: "white",
			fontWeight: "bold",
			lineHeight: 1.5,
		},
		imageOverlay: {
			backgroundColor: loading ? "rgb(216 216 216)" : "rgba(2, 48, 71,0.6)",
			height: "100%",
			width: "100%",
			borderRadius: 9,
			margin: 0,
		},
	};

	return (
		<Link to={`/Store-Service-Provider/${data.key}`} data-testid="service-card">
			<Row style={styles.headerImage} data-testid="service-header">
				<div style={styles.imageOverlay} data-testid="service-overlay">
					<Row
						justify="space-between"
						align="middle"
						style={{ height: "100%", width: 380, padding: "0px 17px" }}
					>
						<Col span={16} style={styles.name} data-testid="service-name">
							{data.name || "Loading"}
						</Col>
						<Col span={8}>
							<Row justify="end" align="middle">
								<Row
									style={{
										padding: 4,
										backgroundColor: "white",
										boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
										borderRadius: 62,
										marginTop: 0,
									}}
								>
									<Avatar
										src={data.displayPic}
										size={90}
										data-testid="service-provider"
									>
										{getInitials(data.name || "")}
									</Avatar>
								</Row>
							</Row>
						</Col>
					</Row>
				</div>
			</Row>
		</Link>
	);
};

export default ServiceProviderCard;
