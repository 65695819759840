import {
	Row,
	Modal,
	Col,
	Form,
	Input,
	Select,
	message,
	Upload,
	Progress,
	Button,
	Popconfirm,
} from "antd";
import { firestore, storage } from "firebase";
import React, { useEffect, useState } from "react";
import { colorsLight } from "../../../Styles/Themes";
import { FileImageOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

const EditPlaylistModal = ({ visible, dismiss, data }) => {
	const [privatePlaylist, setPrivatePlaylist] = useState();
	const [accounts, setAccounts] = useState([]);
	const [coverPic, setCoverPic] = useState("");
	const [cpStat, setCPStat] = useState(false);
	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		data.coverPic && setCoverPic(data.coverPic);
		data.type === "Private" && setPrivatePlaylist(true);
		firestore()
			.collection("Accounts")
			.get()
			.then((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setAccounts(list);
			})
			.catch((err) => message.error(err));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uploadFile = async ({ onError, onSuccess, file }) => {
		setLoading(true);
		const reff = `playlistPics/${file.name}`;
		let uploadTask = await storage().ref().child(reff).put(file);
		uploadTask.task.on(
			"state_changed",
			(snapshot) => {
				let progress1 = (snapshot.task.bytesTransferred / snapshot.task.totalBytes) * 100;
				setProgress(Number(progress1.toFixed(0)));
			},
			(err) => {
				onError();
				setLoading(false);
				setProgress(0);
				message.error("Something went wrong");
				console.log(err);
			},
			() => {
				uploadTask.task.snapshot.ref.getDownloadURL().then(async (downloadUrl) => {
					onSuccess();
					setCoverPic(downloadUrl);
					setCPStat(true);
					setLoading(false);
					setProgress(0);
					return;
				});
			}
		);
	};

	const deleteImage = async () => {
		if (coverPic && data.coverPicOwner) {
			try {
				await storage().refFromURL(coverPic).delete();
				setCoverPic("");
				return;
			} catch (err) {
				message.error("Could not delete image");
				return;
			}
		} else {
			setCoverPic("");
			return;
		}
	};

	const closeModal = () => {
		if (coverPic) {
			dismiss();
		} else {
			dismiss();
		}
	};

	const getIndex = (arr, key, value) => {
		console.log(arr);
		let res = arr
			.map((x) => {
				return x[key];
			})
			.indexOf(value);
		console.log(res);
		return res;
	};

	const handleTypeChange = (val) =>
		val === "Private" ? setPrivatePlaylist(true) : setPrivatePlaylist(false);

	const styles = {
		imageHeader: {
			backgroundImage: `url(${coverPic})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			height: "100%",
			width: "100%",
		},
		delWrapper: {
			height: 30,
			width: 30,
			borderRadius: 15,
			backgroundColor: "white",
			cursor: "pointer",
			position: "absolute",
			top: 50,
			right: 24,
			boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		},
	};

	const savePlaylist = (val) => {
		firestore()
			.collection("Playlists")
			.doc(data.key)
			.update({
				coverPic: coverPic,
				name: val.name,
				type: val.type,
				owner: accounts[getIndex(accounts, "name", val.owner)].key,
				ownerName: val.owner,
				updated: moment().format(),
				coverPicOwner: cpStat,
			})
			.then(() => {
				dismiss();
				return;
			})
			.catch((err) => message.error(err));
	};

	const DeleteButton = () => {
		return (
			<Row onClick={deleteImage} justify="center" align="middle" style={styles.delWrapper}>
				<DeleteOutlined />
			</Row>
		);
	};

	const deletePlaylist = () => {
		firestore()
			.collection("Playlists")
			.doc(data.key)
			.delete()
			.then(() => {
				dismiss();
			})
			.catch((err) => {
				message.error("Could not delete playlist, check log for more info.");
				console.log(err);
			});
	};

	return (
		<Modal
			width={600}
			title="Edit Playlist"
			visible={visible}
			onCancel={closeModal}
			footer={[
				<Button key="cancel" onClick={closeModal}>
					Cancel
				</Button>,
				<Button key="submit" htmlType="submit" form="playlistForm" type="primary">
					Create
				</Button>,
			]}
		>
			<Col>
				<Row justify="end">
					<Popconfirm
						title="Are you sure you want to delete this playlist?"
						onConfirm={deletePlaylist}
					>
						<Button type="danger">Delete Playlist</Button>
					</Popconfirm>
				</Row>
				<Row style={{ width: "100%", height: 180, paddingBottom: 24, marginTop: 12 }}>
					{coverPic && (
						<Row justify="end" align="top" style={styles.imageHeader}>
							<DeleteButton />
						</Row>
					)}
					{!coverPic && (
						<Row
							justify="center"
							align="middle"
							style={{
								backgroundColor: colorsLight("grey"),
								height: "100%",
								width: "100%",
							}}
						>
							<Upload
								style={{ height: "100%", width: "100%" }}
								customRequest={uploadFile}
								onRemove={deleteImage}
								showUploadList={false}
							>
								<Col>
									{!loading ? (
										<>
											<Row justify="center" align="middle">
												<FileImageOutlined
													style={{
														fontSize: 32,
														marginBottom: 12,
														color: colorsLight("darkGrey"),
													}}
												/>
											</Row>
											<Row justify="center" align="middle">
												<p
													style={{
														fontSize: 18,
														color: colorsLight("darkGrey"),
													}}
												>
													Upload playlist cover photo
												</p>
											</Row>
										</>
									) : (
										<Row justify="center" align="middle">
											<Progress type="circle" percent={progress} width={80} />
										</Row>
									)}
								</Col>
							</Upload>
						</Row>
					)}
				</Row>
				<Form
					id="playlistForm"
					onFinish={savePlaylist}
					initialValues={{
						name: data.name,
						type: data.type,
						owner: data.ownerName,
						summary: data.summary,
					}}
					layout="vertical"
				>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item label="Playlist name" name="name" required={true}>
								<Input placeholder="Please enter playlist name" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Playlist type" name="type" required={true}>
								<Select onChange={(val) => handleTypeChange(val)}>
									<Select.Option value="Public">Public</Select.Option>
									<Select.Option value="Private">Private</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					{privatePlaylist && (
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Account" name="owner" required={true}>
									<Select>
										{accounts.map((item, index) => (
											<Select.Option key={index} value={item.name}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}></Col>
						</Row>
					)}
					<Row>
						<Col span={24}>
							<Form.Item label="Description" name="summary" required={true}>
								<Input.TextArea placeholder="Please enter description of playlist" />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>
		</Modal>
	);
};

export default EditPlaylistModal;
