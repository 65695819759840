import { Col, Divider, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { colorsLight } from "../../../../Styles/Themes";
const { Title } = Typography;

const CoachViewHeaderData = ({ data, privateData, toggleEdit, editing }) => {
	return (
		<>
			<Title level={5}>Basic Info:</Title>
			<Row style={styles.paddingHoro}>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
					<Row justify="space-between">
						<Col span={12}>
							<p className="Key">First Name:</p>
						</Col>
						<Col span={12}>
							<Row justify="end">
								<p className="Value" data-testid="header-fname">
									{data.firstName}
								</p>
							</Row>
						</Col>
					</Row>
					<Row justify="space-between">
						<p className="Key">Last Name:</p>
						<p className="Value" data-testid="header-lname">
							{data.lastName}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Email:</p>
						<p className="Value" data-testid="header-email">
							{data.email}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Secondary Email:</p>
						<p className="Value" data-testid="header-alternateEmail">
							{privateData.alternateEmail || "-"}
						</p>
					</Row>
				</Col>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
					<Row justify="space-between">
						<p className="Key">Primary Category:</p>
						<p className="Value" data-testid="header-category">
							{data.primaryCategory}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Contact Number:</p>
						<p className="Value" data-testid="header-mobile">
							{privateData.mobile || "-"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Member Since:</p>
						<p className="Value" data-testid="header-memberSince">
							{moment(data.created).format("Do MMMM YYYY")}
						</p>
					</Row>
				</Col>
			</Row>
			<Row style={styles.paddingHoro}>
				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			</Row>
			<Title level={5}>Business Details:</Title>
			<Row style={styles.paddingHoro}>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
					<Row justify="space-between">
						<Col span={12}>
							<p className="Key">Business Name:</p>
						</Col>
						<Col span={12}>
							<Row justify="end">
								<p className="Value" data-testid="header-business">
									{privateData.businessName || "-"}
								</p>
							</Row>
						</Col>
					</Row>
					<Row justify="space-between">
						<p className="Key">ABN Number:</p>
						<p className="Value" data-testid="header-abn">
							{privateData.abn || "-"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">City:</p>
						<p className="Value" data-testid="header-city">
							{privateData.city || "-"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">State:</p>
						<p className="Value" data-testid="header-state">
							{privateData.state || "-"}
						</p>
					</Row>
				</Col>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
					<Row justify="space-between">
						<p className="Key">Address Line 1:</p>
						<p className="Value" data-testid="header-address1">
							{privateData.addressLine1 || "-"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Address Line 2:</p>
						<p className="Value" data-testid="header-address2">
							{privateData.addressLine2 || "-"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Suburb:</p>
						<p className="Value" data-testid="header-suburb">
							{privateData.suburb || "-"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Postcode:</p>
						<p className="Value" data-testid="header-postcode">
							{privateData.postCode || "-"}
						</p>
					</Row>
				</Col>
			</Row>
			<Row style={styles.paddingHoro}>
				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			</Row>
			<Title level={5}>Payment Details:</Title>
			<Row style={{ ...styles.paddingHoro, marginBottom: 24 }}>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
					<Row justify="space-between">
						<p className="Key">Bank Name:</p>
						<p className="Value" data-testid="header-bank">
							{privateData.bankName || "-"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Account Name:</p>
						<p className="Value" data-testid="header-accountName">
							{privateData.bankAccountName || "-"}
						</p>
					</Row>
				</Col>
				<Col style={{ paddingLeft: 12, paddingRight: 12 }} span={12}>
					<Row justify="space-between">
						<p className="Key">Account Number:</p>
						<p className="Value" data-testid="header-accountNumber">
							{privateData.bankAccountNumber || "-"}
						</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">BSB Number:</p>
						<p className="Value" data-testid="header-bsb">
							{privateData.bankBSBNumber || "-"}
						</p>
					</Row>
				</Col>
			</Row>
		</>
	);
};

const styles = {
	imageWrapper: {
		height: 142,
		width: 142,
		borderRadius: 71,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginTop: -60,
		marginLeft: 24,
	},
	paddingHoro: {},
	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
	formField: {
		margin: "0px 0px 6px 0px",
	},
};

export default CoachViewHeaderData;
