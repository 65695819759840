import * as firebase from "firebase";
import "firebase/analytics";
import "firebase/storage";
import "firebase/firestore";
import "firebase/database";

let firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// window.location.host.includes("localhost") &&
// 	firebase.app().functions("asia-northeast1").useFunctionsEmulator("http://localhost:5001");

firebase.analytics
	.isSupported()
	.then((isSupported) => {
		if (isSupported) {
			firebase.analytics();
		}
	})
	.catch((e) => {
		return e;
	});

export default firebase;
