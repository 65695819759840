import { UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Col, Divider, Row, Typography } from "antd";
import React from "react";
import { coachTagFormatter } from "../../../../Functions/formatters";
import { colorsLight } from "../../../../Styles/Themes";

const { Title } = Typography;

const CoachProfileHeader = ({ data = {} }) => {
	const imageBackground = {
		backgroundImage: `url(${data.coverPic})`,
		backgroundColor: colorsLight("grey"),
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: 130,
	};

	return (
		<Badge.Ribbon
			color={coachTagFormatter(data.primaryCategory)}
			text={
				<span data-testid="coach-category">
					{data.primaryCategory ? data.primaryCategory.toUpperCase() : "LOADING"}
				</span>
			}
			placement="start"
		>
			<Col className="Card" style={{ padding: 0, overflow: "hidden", marginTop: 24 }}>
				<Row justify="end" align="top" style={imageBackground} />
				<Row justify="space-between">
					<Row>
						<Row justify="center" align="middle" style={styles.imageWrapper}>
							<Avatar
								src={data.displayPic}
								size={130}
								icon={<UserOutlined />}
								data-testid="coach-avatar"
							/>
						</Row>
						<Col style={{ paddingLeft: 12, marginTop: 6 }}>
							<Title style={{ margin: 0 }} level={1} data-testid="coach-name">
								{data.displayName}
							</Title>
							<Title
								style={{ margin: 0, fontWeight: 300, marginLeft: 2 }}
								level={4}
								data-testid="coach-title"
							>
								{data.title}
							</Title>
						</Col>
					</Row>
					<Row style={{ paddingRight: 12, paddingTop: 12 }}></Row>
				</Row>

				<Row style={{ paddingLeft: 20, paddingRight: 20 }}>
					<Divider style={{ marginTop: 12, marginBottom: 12 }} />
				</Row>
				<Row style={styles.paddingHoro}>
					<Title level={3}>Summary</Title>
				</Row>

				<Row style={styles.paddingHoro}>
					<p data-testid="coach-summary">{data.summary}</p>
				</Row>

				<Row style={{ ...styles.paddingHoro, marginTop: 42 }}>
					<Title level={3}>Coach Bio</Title>
				</Row>

				<Row style={{ ...styles.paddingHoro, marginBottom: 42 }}>
					<div
						dangerouslySetInnerHTML={{ __html: data.details }}
						data-testid="coach-biography"
					/>
				</Row>
			</Col>
		</Badge.Ribbon>
	);
};

const styles = {
	imageWrapper: {
		height: 142,
		width: 142,
		borderRadius: 71,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginTop: -60,
		marginLeft: 24,
	},
	paddingHoro: {
		paddingLeft: 32,
		paddingRight: 32,
	},
	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
};

export default CoachProfileHeader;
