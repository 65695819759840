import { Button, Form, Col, Divider, InputNumber, Row, Typography } from "antd";
import firebase from "../../../../API/firebase";
import React, { useState } from "react";
import { colorsLight } from "../../../../Styles/Themes";

const CoachCommercialsPanel = ({ commercials, coachId }) => {
	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);

	const updateCommercials = (val) => {
		setLoading(true);
		firebase
			.firestore()
			.collection("Commercials")
			.doc(coachId)
			.update({
				markup: val.markup,
			})
			.then(() => {
				setLoading(false);
				setEditing(false);
			})
			.catch(() => {
				setLoading(false);
				setEditing(false);
			});
	};

	return (
		<Col style={{ borderRadius: 6 }} className="Card">
			<Row justify="space-between">
				<Typography.Title level={5}>Commercials</Typography.Title>
				{!editing ? (
					<Button onClick={() => setEditing(!editing)} size="small" data-testid="edit">
						Edit
					</Button>
				) : (
					<Row>
						<Row>
							<Button
								onClick={() => setEditing(!editing)}
								size="small"
								data-testid="cancel"
							>
								Cancel
							</Button>
							<Button
								form="commercialsForm"
								loading={loading}
								key="submit"
								htmlType="submit"
								style={{ marginLeft: 12 }}
								type="primary"
								size="small"
								data-testid="update"
							>
								Update
							</Button>
						</Row>
					</Row>
				)}
			</Row>
			<Divider style={{ marginTop: 6, marginBottom: 24 }} />
			<Row align="middle" justify="space-between" style={{ width: "100%" }}>
				<p style={styles.label}>Coach package markup</p>
				{!editing ? (
					<p style={styles.markup} data-testid="markup">
						{commercials.markup}%
					</p>
				) : (
					<Form
						onFinish={updateCommercials}
						initialValues={commercials}
						id="commercialsForm"
					>
						<Form.Item
							style={{ margin: 0 }}
							name="markup"
							rules={[{ required: true, message: "Please enter markup rate" }]}
						>
							<InputNumber
								min={0}
								max={100}
								formatter={(value) => `${value}%`}
								parser={(value) => value.replace("%", "")}
								style={{ margin: 0 }}
								data-testid="markup-input"
							/>
						</Form.Item>
					</Form>
				)}
			</Row>
		</Col>
	);
};

const styles = {
	markup: {
		fontSize: 22,
		fontWeight: "bold",
		color: colorsLight("lightBlue"),
		margin: 0,
	},
	label: {
		margin: 0,
	},
};

export default CoachCommercialsPanel;
