import { Row, Col, Button, Typography, Divider, Avatar } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { colorsLight } from "../../../../Styles/Themes";

const CoachDetailsPanel = ({ data = {} }) => {
	const styles = {
		name: {
			marginLeft: 12,
			marginTop: 12,
			marginBottom: 0,
			fontSize: 24,
			fontWeight: 400,
			lineHeight: 1,
		},
		title: {
			marginLeft: 12,
			marginTop: 0,
			marginBottom: 0,
			fontSize: 18,
			fontWeight: 300,
			color: colorsLight("grey"),
		},
		label: {
			fontSize: 18,
			fontWeight: 400,
			margin: 0,
		},
		about: {
			fontFamily: "'SF UI Display', sans-serif",
			fontSize: 14,
			margin: 0,
		},
		avatar: {
			padding: 5,
			backgroundColor: "white",
			boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.3)",
			borderRadius: "50%",
			margin: "0px 0px 10px 0px",
			height: 80,
		},
	};

	return (
		<Col className="Card">
			<Typography.Title level={4}>Coach Details</Typography.Title>
			<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			<Row>
				<div style={styles.avatar}>
					<Avatar src={data.displayPic} size={70} data-testid="coach-avatar" />
				</div>
				<div>
					<p style={styles.name} data-testid="coach-name">
						{data.displayName || "Loading"}
					</p>
					<p style={styles.title} data-testid="coach-type">
						{data.title || "Loading"}
					</p>
				</div>
			</Row>
			{data.summary && (
				<>
					<Row style={{ marginTop: 12 }}>
						<Typography.Title level={5}>About</Typography.Title>
					</Row>
					<Row>
						<p style={styles.about} data-testid="coach-about">
							{data.summary || "This coach has no summary"}
						</p>
					</Row>
				</>
			)}
			<Row style={{ marginTop: 12 }}>
				<Col style={{ paddingRight: 12 }} span={12}>
					<Button style={{ width: "100%" }} data-testid="coach-contact">
						Contact
					</Button>
				</Col>
				<Col style={{ paddingLeft: 12 }} span={12}>
					<Link to={`/Coach/${data.key}`} data-testid="coach-profile">
						<Button style={{ width: "100%" }}>View Profile</Button>
					</Link>
				</Col>
			</Row>
		</Col>
	);
};

export default CoachDetailsPanel;
