import { Col, Row, Typography, Avatar } from "antd";
import React from "react";
import { colorsLight } from "../../../../Styles/Themes";

const StoreServiceProviderProfileHeader = ({ data = {} }) => {
	const styles = {
		headerImage: {
			backgroundImage: `url(${data.coverPic})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			height: 150,
			width: "100%",
		},
		imageCover: {
			backgroundColor: colorsLight("cardCover"),
			height: "100%",
			width: "100%",
			padding: 12,
		},
	};

	return (
		<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
			<Row style={styles.headerImage}>
				<Row justify="start" align="middle" style={styles.imageCover}>
					<Avatar src={data.displayPic} size={90} data-testid="avatar" />
					<Typography.Title
						style={{ color: "white", marginLeft: 16, marginBottom: 0, marginTop: 0 }}
						level={1}
						data-testid="name"
					>
						{data.name}
					</Typography.Title>
				</Row>
			</Row>
			<Row style={{ padding: 12 }}>
				<Col span={24}>
					<Row>
						<Typography.Title level={4}>Summary</Typography.Title>
					</Row>
					<Row>
						<Typography.Paragraph style={{ width: "100%" }} data-testid="summary">
							{data.summary}
						</Typography.Paragraph>
					</Row>
					<Row>
						<Typography.Title level={4}>Details</Typography.Title>
					</Row>
					<Row>
						<div
							dangerouslySetInnerHTML={{ __html: data.details }}
							data-testid="details"
						/>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default StoreServiceProviderProfileHeader;
