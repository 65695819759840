import { Row, Modal, message, Col, Button } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";

const AddProviderToPlaylistModal = ({ visible, dismiss, providerData, courses }) => {
	const [playlists, setPlaylists] = useState([]);
	const [loading, setloading] = useState(false);

	useEffect(() => {
		firestore()
			.collection("Playlists")
			.get()
			.then((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setPlaylists(list);
			})
			.catch((err) => {
				message.error("Could not get playlists, check log for more info.");
				console.log(err);
			});
	}, [visible]);

	const AddProvider = async (pid, index) => {
		setloading(index);
		let itemIds = [];
		let itemCount = 0;
		courses.forEach((item) => {
			itemCount++;
			itemIds.push(item.key);
		});

		let promises = [];

		itemIds.forEach((id) => {
			promises.push(
				firestore()
					.collection("Playlists")
					.doc(pid)
					.update({
						courses: firestore.FieldValue.arrayUnion({
							key: id,
							provider: providerData.key,
						}),
					})
			);
		});

		await Promise.all(promises);
		setloading(false);
		dismiss();
		message.success(`Added ${itemCount} courses to playlist`);
	};

	const removeProvider = async (pid, index) => {
		setloading(index);
		let itemIds = [];
		let itemCount = 0;
		courses.forEach((item) => {
			itemCount++;
			itemIds.push(item.key);
		});

		let promises = [];

		itemIds.forEach((id) => {
			promises.push(
				firestore()
					.collection("Playlists")
					.doc(pid)
					.update({
						courses: firestore.FieldValue.arrayRemove({
							key: id,
							provider: providerData.key,
						}),
					})
			);
		});

		await Promise.all(promises);
		setloading(false);
		dismiss();
		message.info(`Removed ${itemCount} courses to playlist`);
	};

	return (
		<Modal width={700} title="Add Provider to playlist" visible={visible} onCancel={dismiss}>
			<Row>
				<Col span={10}>
					<p className="Key">Name</p>
				</Col>
				<Col span={4}>
					<p className="Key">Type</p>
				</Col>
				<Col span={6}>
					<p className="Key">Owner</p>
				</Col>
				<Col span={4}></Col>
			</Row>
			{playlists.map((item, index) => (
				<Row style={{ marginBottom: 9 }} key={index}>
					<Col span={10}>
						<p className="Value">{item.name}</p>
					</Col>
					<Col span={4}>
						<p className="Value">{item.type}</p>
					</Col>
					<Col span={6}>
						<p className="Value">{item.ownerName}</p>
					</Col>
					<Col span={4}>
						{item.providers && providerData && (
							<>
								{item.providers.includes(providerData.key) ? (
									<Button
										loading={loading === index}
										onClick={() => removeProvider(item.key, index)}
										size="small"
									>
										Remove
									</Button>
								) : (
									<Button
										loading={loading === index}
										onClick={() => AddProvider(item.key, index)}
										size="small"
									>
										Add
									</Button>
								)}
							</>
						)}
					</Col>
				</Row>
			))}
		</Modal>
	);
};

export default AddProviderToPlaylistModal;
