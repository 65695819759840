import { Button, Col, Row, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { priceFormatter } from "../../../../Functions/formatters";
import { colorsLight } from "../../../../Styles/Themes";
import AddToPlaylistModal from "../../../Playlists/Components/AddToPlaylistModal";

const CourseCardLite = ({ data = {} }) => {
	const [modal1, setModal1] = useState(false);

	const toggleModal = () => setModal1(!modal1);

	const styles = {
		card: {
			padding: 0,
			overflow: "hidden",
		},
		imageHeader: {
			backgroundImage: `url(${data.coverPic})`,
			backgroundSize: "cover",
			width: "100%",
			height: 120,
		},
		imageCover: {
			backgroundColor: colorsLight("cardCover"),
			height: "100%",
			width: "100%",
			padding: 12,
		},
	};

	return (
		<Col className="Card" style={styles.card}>
			<Row style={styles.imageHeader}>
				<Row justify="start" align="bottom" style={styles.imageCover}>
					<Typography.Title
						style={{ width: "66%", color: "white" }}
						level={4}
						data-testid="course-name"
					>
						{data.name}
					</Typography.Title>
				</Row>
			</Row>
			<Row style={{ backgroundColor: "white", padding: "12px 0px 0px 12px" }}>
				<Col span={8}>
					<p className="Key">Type</p>
					<p className="Value" data-testid="course-type">
						{data.courseType || "-"}
					</p>
				</Col>
				<Col span={8}>
					<p className="Key">Delivery</p>
					<p className="Value" data-testid="course-delivery">
						{data.courseDelivery || "-"}
					</p>
				</Col>
				<Col span={8}>
					<p className="Key">Price</p>
					<p className="Value" data-testid="course-price">
						{priceFormatter(data.price, "dollars") || "-"}
					</p>
				</Col>
			</Row>
			<Row style={{ padding: 12 }}>
				<Col span={24}>
					<p className="Key">Summary</p>
					<Typography.Paragraph
						ellipsis={{
							rows: 3,
							expandable: false,
							symbol: "...",
							suffix: (
								<a href={`/Store-Course-View/${data.owner}&${data.key}`}>Read on</a>
							),
						}}
						data-testid="course-summary"
					>
						{data.summary}
					</Typography.Paragraph>
				</Col>
			</Row>
			<Row style={{ padding: "0px 12px 12px 0px" }} justify="end">
				<Button
					onClick={toggleModal}
					style={{ marginRight: 12 }}
					data-testid="course-playlist"
				>
					Add to playlist
				</Button>
				<Link to={`/Store-Course-View/${data.owner}&${data.key}`} data-testid="course-view">
					<Button>View Course</Button>
				</Link>
			</Row>
			<AddToPlaylistModal visible={modal1} dismiss={toggleModal} type="Courses" data={data} />
		</Col>
	);
};

export default CourseCardLite;
