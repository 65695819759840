import { Avatar, Button, Col, Row, Table } from "antd";
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const MembersTable = ({ data }) => {
	const columns = [
		{
			title: "Photo",
			dataIndex: "displayPic",
			key: "displayPic",
			render: (text) => <Avatar size={40} src={text} data-testid="account-avatar"></Avatar>,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Website",
			dataIndex: "websiteUrl",
			key: "websiteUrl",
		},
		{
			title: "Created",
			dataIndex: "created",
			key: "created",
			render: (text) => <p style={{ margin: 0 }}>{moment(text).format("Do MMMM YYYY")}</p>,
		},

		{
			title: "Action",
			render: (record) => (
				<Link to={`/Account/${record.key}`}>
					<Button size="small" data-testid="account-button">
						View
					</Button>
				</Link>
			),
		},
	];

	return (
		<Col className="Card" style={styles.card}>
			<Row style={styles.row}>
				<Table style={styles.table} columns={columns} dataSource={data} />
			</Row>
		</Col>
	);
};

const styles = {
	card: {},
	row: {
		width: "100%",
	},
	table: {
		width: "100%",
	},
};

export default MembersTable;
