import { Row, Col, Typography, Button, message, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import firebase from "../../../../API/firebase";
import { colorsLight } from "../../../../Styles/Themes";
const { Title } = Typography;

const CourseProviderCommercials = ({ data, commercials, editing, toggleEditComms }) => {
	const [markup, setMarkup] = useState(0);

	useEffect(() => {
		if (commercials.markup) {
			setMarkup(commercials.markup);
		}
	}, [commercials]);

	const updateCommercials = () => {
		firebase
			.firestore()
			.collection("Commercials")
			.doc(commercials.key)
			.update({ markup })
			.then(() => {
				toggleEditComms();
				message.success("Commercials updated!");
			})
			.catch((err) => {
				console.log(err);
				message.error("Could not update commercials");
			});
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Title level={5}>Commercials</Title>
				<Button size="small" type="link" onClick={toggleEditComms} data-testid="edit">
					Edit
				</Button>
			</Row>
			<Row justify="space-between" style={{ ...styles.row, marginTop: 24 }}>
				<p style={{ marginTop: 6 }} className="Key">
					Markup rate:
				</p>
				{!editing && (
					<p style={styles.value} data-testid="markup">
						{commercials.markup || 0}%
					</p>
				)}
				{editing && (
					<InputNumber
						value={markup}
						style={styles.value}
						min={0}
						max={100}
						formatter={(value) => `${value}%`}
						parser={(value) => value.replace("%", "")}
						onChange={setMarkup}
						data-testid="markup"
					/>
				)}
			</Row>
			{editing && (
				<Row style={{ marginTop: 24 }} justify="end">
					<Button onClick={toggleEditComms} data-testid="cancel">
						Cancel
					</Button>
					<Button
						disabled={markup === commercials.markup}
						onClick={updateCommercials}
						style={{ marginLeft: 12 }}
						type="primary"
						data-testid="update"
					>
						Update
					</Button>
				</Row>
			)}
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
	value: {
		fontSize: 24,
		color: colorsLight("lightBlue"),
		fontWeight: "bold",
		margin: 0,
	},
};

export default CourseProviderCommercials;
