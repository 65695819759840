import { Col, Layout, Row } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MyAccountIcons from "../../../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../../../Components/MainMenu";
import BackButton from "../../../../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../../../../Styles/Themes";
import ViewProgramMain from "./Components/ViewProgramMain";
import ViewProgramSchedule from "./Components/ViewProgramSchedule";

const { Header, Content } = Layout;

const ViewProgramPage = () => {
	const [data, setData] = useState({});
	const { programId } = useParams();
	const history = useHistory();

	useEffect(() => {
		firestore()
			.collection("Programs")
			.doc(programId)
			.get()
			.then((doc) => {
				if (doc.exists) {
					setData({ ...doc.data(), key: doc.id });
				}
			});
	}, []);

	return (
		<Layout className="App">
			<MenuMain active="6" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							padding: 24,
						}}
					>
						<Col span={24} style={{ maxWidth: maxContentWidth }}>
							<Row gutter={24}>
								<Col span={16}>
									<ViewProgramMain data={data} />
								</Col>
								<Col span={8}>
									<ViewProgramSchedule timeline={data.timeline} />
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default ViewProgramPage;
