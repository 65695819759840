import { Col, Layout, message, Row } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import BackButton from "../../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../../Styles/Themes";
import MemberAccountLedger from "./Components/MemberAccountLedger";
import MemberPrograms from "./Components/MemberPrograms";
import MemberQuickActions from "./Components/MemberQuickActions";
import MemberViewHeader from "./Components/ViewMemberHeader";

const { Header, Footer, Content } = Layout;

const ViewMemberPage = () => {
	const [data, setData] = useState({});
	const [privData, setPrivData] = useState({});
	const [editing, setEditing] = useState(false);
	const { memberId } = useParams();
	const history = useHistory();
	const [accountOwner, setAccountOwner] = useState({});
	const [playlistData, setPlaylistData] = useState({});
	const [programs, setPrograms] = useState([]);

	useEffect(() => {
		let listener1 = firestore()
			.collection("Members")
			.doc(memberId)
			.onSnapshot((doc) => {
				if (doc.exists) {
					setData({ ...doc.data(), key: doc.id });
				}
			});

		let listener2 = firestore()
			.collection("UserRecords")
			.doc(memberId)
			.onSnapshot((doc) => {
				if (doc.exists) {
					setPrivData({ ...doc.data(), key: doc.id });
				}
			});

		let listener3 = firestore()
			.collection("Programs")
			.where("member", "==", memberId)
			.onSnapshot((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setPrograms(list);
			});

		return () => {
			listener1();
			listener2();
			listener3();
		};
	}, []);

	useEffect(() => {
		data.parentAccount &&
			firestore()
				.collection("Accounts")
				.doc(data.parentAccount)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setAccountOwner({ ...doc.data(), key: doc.id });
					}
				})
				.catch((err) => message.error(err));

		data.activePlaylist &&
			firestore()
				.collection("Playlists")
				.doc(data.activePlaylist)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setPlaylistData({ ...doc.data(), key: doc.id });
					}
				})
				.catch((err) => message.error(err));
	}, [data]);

	const toggleEdit = () => setEditing(!editing);

	return (
		<Layout className="App">
			<MenuMain active="6" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							padding: 24,
						}}
					>
						<Col span={24} style={{ maxWidth: maxContentWidth }}>
							<Row gutter={24}>
								<Col span={16}>
									<MemberViewHeader
										data={data}
										privateData={privData}
										toggleEdit={toggleEdit}
										editing={editing}
										accountOwner={accountOwner}
										playlistData={playlistData}
									/>
									<MemberPrograms programs={programs} data={data} />
									<MemberAccountLedger data={data} />
								</Col>
								<Col span={8}>
									<MemberQuickActions data={data} />
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default ViewMemberPage;
