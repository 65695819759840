import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Col, Input, Row, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import firebase from "../../../../API/firebase";
import CoverPhotoUploadModal from "../../../../Components/CoverPhotoUploadModal";
import ProfilePicUploadModal from "../../../../Components/ProfilePicUploadModal";
import { getImage } from "../../../../Functions/utilities";
import { colorsLight } from "../../../../Styles/Themes";
import MemberViewHeaderData from "./MemberHeaderData";
import MemberViewHeaderForm from "./MemberHeaderForm";

const { Title } = Typography;

const MemberViewHeader = ({ data, privateData, toggleEdit, editing, accountOwner, playlistData }) => {
	const [hoverProfilePic, setHoverProfilePic] = useState(false);
	const [loading, setloading] = useState(false);
	const [editProfilePic, setEditProfilePic] = useState(false);
	const [editCover, setEditCover] = useState(false);
	const [summary, setSummary] = useState("");
	const [details, setDetails] = useState("");
	const [title, setTitle] = useState("");
	const [coverPic, setCoverPic] = useState("");

	const imageBackground = {
		backgroundImage: `url(${coverPic})`,
		backgroundColor: colorsLight("grey"),
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: 130,
	};

	useEffect(() => {
		setCoverPic(getImage());
	}, []);

	useEffect(() => {
		setDetails(data.details);
		setSummary(data.summary);
		setTitle(data.title);
	}, [data]);

	const toggleEdit1 = () => {
		setEditProfilePic(false);
	};

	const toggleEdit2 = () => {
		setEditCover(false);
	};

	const editUser = (val) => {
		setloading(true);
		let publicRecord = {
			displayName: `${val.firstName} ${val.lastName}`,
			firstName: val.firstName || "",
			lastName: val.lastName || "",
			email: val.email || "",
			summary: summary || "",
			details: details || "",
			primaryCategory: val.primaryCategory || "Coach",
			title: title || "Experienced Coach",
		};
		let privateRecord = {
			alternateEmail: val.alternateEmail || "",
			mobile: val.mobile || "",
			addressLine1: val.addressLine1 || "",
			addressLine2: val.addressLine2 || "",
			suburb: val.suburb || "",
			city: val.city || "",
			state: val.state || "",
			postCode: val.postCode || "",
			businessName: val.businessName || "",
			abn: val.abn || "",
			bankName: val.bankName || "",
			bankAccountName: val.bankAccountName || "",
			bankAccountNumber: val.bankAccountNumber || "",
			bankBSBNumber: val.bankBSBNumber || "",
		};
		const db = firebase.firestore();
		let batch = db.batch();
		let publicRef = db.collection("Coaches").doc(data.key);
		let privateRef = db.collection("UserRecords").doc(data.key);
		batch.update(publicRef, publicRecord);
		batch.update(privateRef, privateRecord);
		batch
			.commit()
			.then(() => {
				setloading(false);
				toggleEdit();
			})
			.catch((err) => {
				setloading(false);
				console.log(err);
			});
	};

	return (
		<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
			<Row justify="end" align="top" style={imageBackground}>
				<Row
					onClick={() => setEditCover(true)}
					justify="center"
					align="middle"
					style={styles.editIconWrapper}
					data-testid="edit-icon">
					<EditOutlined style={styles.editIcon} />
				</Row>
			</Row>
			<Row justify="space-between">
				<Row>
					<Row justify="center" align="middle" style={styles.imageWrapper}>
						<Avatar src={data.displayPic} size={130} icon={<UserOutlined />} />
						<Row
							justify="center"
							align="middle"
							onClick={() => setEditProfilePic(true)}
							onMouseEnter={() => setHoverProfilePic(true)}
							onMouseLeave={() => setHoverProfilePic(false)}
							style={{
								height: 130,
								width: 130,
								backgroundColor: hoverProfilePic ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)",
								position: "absolute",
								borderRadius: 65,
								cursor: "pointer",
							}}
							data-testid="edit-photo">
							{hoverProfilePic && <p style={{ color: "white", fontWeight: "bold", margin: 0 }}>Edit Photo</p>}
						</Row>
					</Row>
					<Col style={{ paddingLeft: 12, marginTop: 6 }}>
						<Title style={{ margin: 0 }} level={1} data-testid="name">
							{data.displayName || "Loading"}
						</Title>
					</Col>
				</Row>
				<Row style={{ paddingTop: 12, paddingRight: 24 }}>
					{!editing && (
						<Button onClick={toggleEdit} data-testid="edit">
							Edit Details
						</Button>
					)}
					{editing && (
						<Button onClick={toggleEdit} data-testid="cancel">
							Cancel
						</Button>
					)}
					{editing && (
						<Button
							type="primary"
							form="editMember"
							loading={loading}
							key="submit"
							htmlType="submit"
							style={{ marginLeft: 12 }}
							data-testid="save">
							Save Changes
						</Button>
					)}
				</Row>
			</Row>
			{!editing ? (
				<MemberViewHeaderData data={data} privateData={privateData} accountOwner={accountOwner} playlistData={playlistData} />
			) : (
				<MemberViewHeaderForm data={data} privateData={privateData} accountOwner={accountOwner} toggleEdit={toggleEdit} />
			)}
			<ProfilePicUploadModal visible={editProfilePic} dismiss={toggleEdit1} userType="Members" data={data} />
			<CoverPhotoUploadModal visible={editCover} dismiss={toggleEdit2} userType="Members" data={data} />
		</Col>
	);
};

const styles = {
	imageWrapper: {
		height: 142,
		width: 142,
		borderRadius: 71,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginTop: -60,
		marginLeft: 24,
	},
	paddingHoro: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
	formField: {
		margin: "0px 0px 6px 0px",
	},
};

export default MemberViewHeader;
