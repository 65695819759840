import { Col, Row, Avatar, Typography, Divider } from "antd";
import React from "react";
import { colorsLight } from "../../../../../../Styles/Themes";
import moment from "moment";
import { priceFormatter } from "../../../../../../Functions/formatters";

const ViewProgramMain = ({ data }) => {
	const styles = {
		imageBackground: {
			height: 150,
			width: "100%",
			backgroundImage: `url(${data.coverPic})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
		},
		imageCover: {
			height: "100%",
			width: "100%",
			backgroundColor: colorsLight("cardCover"),
			padding: 24,
		},
		avatar: {
			borderStyle: "solid",
			borderWidth: 3,
			borderColor: "white",
			top: 50,
			boxShadow: "2px 3px 3px rgba(0,0,0,0.4)",
		},
	};

	return (
		<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
			<Row style={styles.imageBackground}>
				<Row align="bottom" style={styles.imageCover}>
					<Avatar src={data.displayPic} size={140} style={styles.avatar} />
				</Row>
			</Row>
			<Row style={{ paddingLeft: 176, marginTop: 9 }}>
				<Col>
					<Typography.Title style={{ margin: 0 }} level={3}>
						{data.name || "Loading"}
					</Typography.Title>
					<p style={{ margin: 0 }}>{data.coachName || data.providerName || "Loading"}</p>
				</Col>
			</Row>
			<Divider style={{ margin: "24px 0px" }} />
			<Row style={{ padding: "0px 24px" }} gutter={24}>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Created</p>
						<p className="Value">{moment(data.created).format("Do MMMM @ h:mma")}</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Price</p>
						<p className="Value">{priceFormatter(data.price, "dollars")}</p>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Member Email</p>
						<p className="Value">{data.memberEmail}</p>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default ViewProgramMain;
