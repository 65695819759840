import { Col, Layout, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import FulfilmentPanel from "../../Components/Fulfilment/FulfilmentMainPanel";
import MenuMain from "../../Components/MainMenu";
import BackButton from "../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../Styles/Themes";

const { Header, Footer, Content } = Layout;

const FulfilmentPage = ({ history }) => {
	const [data, setData] = useState({});
	const [programData, setProgramData] = useState({});
	const { programId } = useParams();

	useEffect(() => {
		let listener = firestore()
			.collection("Fulfilment")
			.doc(programId)
			.onSnapshot((doc) => {
				if (doc.exists) {
					console.log(doc.data());
					setData({ ...doc.data(), key: doc.id });
				}
			});

		return () => {
			listener();
		};
	}, []);

	useEffect(() => {
		if (data.name) {
			let listener = firestore()
				.collection("Programs")
				.doc(data.programId)
				.onSnapshot((doc) => {
					console.log(doc.data());
					setProgramData({ ...doc.data(), key: doc.id });
				});

			return () => {
				listener();
			};
		}
	}, [data]);

	return (
		<Layout className="App">
			<MenuMain active="1" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							padding: 24,
						}}
					>
						<Col span={24} style={{ maxWidth: maxContentWidth }}>
							<Row>
								<Col span={16}>
									<FulfilmentPanel data={data} programData={programData} />
								</Col>
								<Col span={8}></Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default FulfilmentPage;
