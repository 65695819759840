import React, { useState, useEffect } from "react";
import firebase from "../API/firebase";
import moment from "moment";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
	const [authd, setAuthd] = useState(true);
	const [uid, setUid] = useState("");
	const [userInfo, setUserInfo] = useState({});
	const [collapsed, setCollapsed] = useState(false);
	const [loading, setLoading] = useState(true);
	//POINTS CONVERSION RATE
	const conversionRate = 3.142;

	useEffect(() => {
		setLoading(true);
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				setAuthd(true);
				setUid(user.uid);
			} else {
				setAuthd(false);
			}
			setLoading(false);
		});

		return;
	}, []);

	useEffect(() => {
		if (uid && authd) {
			firebase
				.firestore()
				.collection("Admins")
				.doc(uid)
				.onSnapshot((doc) => {
					setUserInfo({ ...doc.data(), key: doc.id });
				});
		}
	}, [uid, authd]);

	useEffect(() => {
		if (userInfo.firstName) {
			firebase
				.firestore()
				.collection("Admins")
				.doc(uid)
				.update({ status: "active", lastSignIn: moment().format() });
		}
	}, [userInfo, uid]);

	return (
		<UserContext.Provider
			value={{
				authd,
				setAuthd,
				uid,
				setUid,
				userInfo,
				setUserInfo,
				conversionRate,
				collapsed,
				setCollapsed,
				loading,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export default UserContext;
