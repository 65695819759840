import { Row, Col, Layout, Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import { colorsLight } from "../../../Styles/Themes";
import firebase from "../../../API/firebase";
import CourseProviderViewHeader from "./Components/CourseProviderViewHeader";
import { useParams, Link, useHistory } from "react-router-dom";
import BackButton from "../../../Components/Navigation/BackButton";
import CourseProviderCourseList from "./Components/CourseProviderCourseList";
import CourseProviderQuickActions from "./Components/CourseProviderQuickActions";
import CourseProviderCommercials from "./Components/CourseProviderCommercials";

const { Header, Content } = Layout;

const CourseProviderView = () => {
	// const [addModal, setAddModal] = useState(false);
	const [data, setData] = useState({});
	const [courses, setCourses] = useState([]);
	const [commercials, setCommercials] = useState({});
	const [editComms, setEditComms] = useState(false);
	const { providerId } = useParams();
	const history = useHistory();

	// const dismissModal = () => setAddModal(false);

	const toggleEditComms = () => setEditComms(!editComms);

	useEffect(() => {
		let listener = () =>
			firebase
				.firestore()
				.collection("CourseProviders")
				.doc(providerId)
				.onSnapshot((doc) => {
					setData({ ...doc.data(), key: doc.id });
				});

		let listener2 = () =>
			firebase
				.firestore()
				.collection("CourseProviders")
				.doc(providerId)
				.collection("Courses")
				.onSnapshot((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					setCourses(list);
				});

		let listener3 = () =>
			firebase
				.firestore()
				.collection("Commercials")
				.doc(providerId)
				.onSnapshot((doc) => {
					setCommercials({ ...doc.data(), key: doc.id });
				});

		listener();
		listener2();
		listener3();
	}, [providerId]);

	return (
		<Layout className="App">
			<MenuMain active="4" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row style={{ marginBottom: 12 }}>
							<Breadcrumb>
								<Breadcrumb.Item>
									<Link to="/Course-Providers">Courses</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>{data.name}</Breadcrumb.Item>
							</Breadcrumb>
						</Row>
						<Row>
							<Col span={16}>
								<CourseProviderViewHeader data={data} />
								<CourseProviderCourseList
									data={courses}
									providerId={providerId}
									providerData={data}
									markup={commercials.markup}
								/>
							</Col>
							<Col style={{ paddingLeft: 24 }} span={8}>
								<CourseProviderQuickActions data={data} list={courses} />
								<CourseProviderCommercials
									commercials={commercials}
									editing={editComms}
									toggleEditComms={toggleEditComms}
								/>
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		paddingLeft: 22,
		paddingRight: 22,
		paddingTop: 12,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default CourseProviderView;
