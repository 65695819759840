import { Col, Layout, Row, Typography, List, Button, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import BackButton from "../../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../../Styles/Themes";
import firebase from "../../../API/firebase";
import { Link, useHistory } from "react-router-dom";
const { Header, Content } = Layout;

const StoreViewServiceProviders = () => {
	const [items, setItems] = useState([]);
	const history = useHistory();

	useEffect(() => {
		firebase
			.firestore()
			.collection("ServiceProviders")
			.get()
			.then((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setItems(list);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<Layout className="App">
			<MenuMain active="2" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							padding: 24,
						}}
					>
						<Col span={24} style={{ maxWidth: maxContentWidth }}>
							<Row>
								<Col span={24}>
									<Row>
										<Typography.Title>
											View All Service Providers
										</Typography.Title>
									</Row>

									<List
										data-testid="service-list"
										grid={{ gutter: 16, column: 3 }}
										dataSource={items}
										renderItem={(item) => (
											<List.Item>
												<Col
													className="Card"
													style={{ padding: 0, overflow: "hidden" }}
												>
													<Row
														style={{
															backgroundImage: `url(${item.coverPic})`,
															backgroundSize: "cover",
															backgroundPosition: "center",
															height: "100%",
															width: "100%",
														}}
													>
														<Row
															justify="space-between"
															align="middle"
															style={{
																backgroundColor: colorsLight(
																	"cardCover"
																),
																height: "100%",
																width: "100%",
																padding: 12,
															}}
														>
															<Col span={17}>
																<Typography.Title
																	style={{ color: "white" }}
																	level={3}
																	data-testid="service-name"
																>
																	{item.name}
																</Typography.Title>
															</Col>
															<Col span={7}>
																<Avatar
																	size={90}
																	src={item.displayPic}
																	data-testid="service-avatar"
																/>
															</Col>
														</Row>
													</Row>
													<Col style={{ padding: 12 }}>
														<Row>
															<p className="Key">About</p>
														</Row>
														<Row>
															<Typography.Paragraph
																className="Value"
																ellipsis={{
																	rows: 3,
																	expandable: false,
																	symbol: "...",
																	suffix: (
																		<a
																			href={`/Store-Service-Provider/${item.key}`}
																		>
																			Read on
																		</a>
																	),
																}}
																data-testid="service-summary"
															>
																{item.summary}
															</Typography.Paragraph>
														</Row>
													</Col>
													<Row style={{ margin: 12 }} justify="end">
														<Button
															style={{ marginRight: 12 }}
															data-testid="service-playlist"
														>
															Add to playlist
														</Button>

														<Link
															to={`/Store-Service-Provider/${item.key}`}
															data-testid="service-view"
														>
															<Button>View Profile</Button>
														</Link>
													</Row>
												</Col>
											</List.Item>
										)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default StoreViewServiceProviders;
