import { Button, Col, Input, message, Modal, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useState } from "react";
import moment from "moment";
import { getRealItemPrice } from "../../../../../Functions/helpers";
const { Title } = Typography;

const ServiceViewQuickActions = ({ data, providerId, ServiceId }) => {
	const [modal, setModal] = useState(false);
	const [delPhrase, setDelPhrase] = useState("");
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	// const [loading1, setloading1] = useState(false);

	// const openNotificationWithIcon = (type, title, message) => {
	// 	notification[type]({
	// 		message: title,
	// 		description: message,
	// 	});
	// };

	const toggleModal = () => setModal(!modal);

	const storeSync = async () => {
		setLoading2(true);
		const keys = Object.keys(data);
		let obj = {};
		let count = 1;
		keys.forEach((key) => {
			if (key !== "key" && key !== "costPrice") {
				obj = { ...obj, [key]: data[key] };
				count++;
			}
		});
		try {
			const price = await getRealItemPrice(
				providerId,
				"ServiceProviders",
				ServiceId,
				"Services"
			);
			obj.price = price;
			console.log(obj);
			await firestore().collection("Services").doc(data.key).update(obj);

			setLoading2(false);
			message.success(`${count} fields added to service record`);
		} catch (err) {
			message.error(err);
			setLoading2(false);
		}
	};

	const activate = () => {
		setLoading(true);
		const now = moment().format();
		firestore()
			.collection("ServiceProviders")
			.doc(providerId)
			.collection("Services")
			.doc(ServiceId)
			.update({ status: "active", lastStoreSync: now })
			.then(async () => {
				let price = await getRealItemPrice(
					providerId,
					"ServiceProviders",
					ServiceId,
					"Services"
				);
				return price;
			})
			.then((price) => {
				let keys = Object.keys(data);
				let obj = {};
				keys.forEach((key) => {
					if (
						key !== "key" &&
						key !== "costPrice" &&
						key !== "status" &&
						key !== "lastStoreSync"
					) {
						obj = { ...obj, [key]: data[key] };
					}
				});
				obj.status = "active";
				obj.lastStoreSync = now;
				obj.price = price;

				return firestore().collection("Services").doc(ServiceId).set(obj);
			})
			.then(() => {
				setLoading(false);
				message.success("Service is now active!");
			})
			.catch((err) => {
				setLoading(false);
				message.error("Could not activate service");
				console.log(err);
			});
	};

	const disable = () => {
		setLoading(true);
		firestore()
			.collection("ServiceProviders")
			.doc(providerId)
			.collection("Services")
			.doc(ServiceId)
			.update({ status: "inactive", lastStoreSync: moment().format() })
			.then(() => {
				return firestore().collection("Services").doc(ServiceId).delete();
			})
			.then(() => {
				setLoading(false);
				message.info("Service is no longer active in store");
			})
			.catch((err) => {
				setLoading(false);
				message.error("Could not disable service");
				console.log(err);
			});
	};

	return (
		<Col className="Card">
			<Row>
				<Title level={5}>Quick Actions</Title>
			</Row>
			<Row gutter={12} style={styles.row}>
				<Col span={12}>
					{data.status !== "active" && (
						<Button
							type="primary"
							onClick={activate}
							loading={loading}
							style={styles.button}
							data-testid="enable"
						>
							Enable Store Listing
						</Button>
					)}
					{data.status === "active" && (
						<Button
							onClick={disable}
							loading={loading}
							style={styles.button}
							data-testid="disable"
						>
							Disable Store Listing
						</Button>
					)}
				</Col>
				<Col span={12}>
					<Button
						onClick={toggleModal}
						danger
						loading={loading}
						style={styles.button}
						data-testid="delete"
					>
						Delete Service
					</Button>
				</Col>
			</Row>
			<Row gutter={12} style={{ ...styles.row, paddingTop: 12 }}>
				<Col span={12}>
					<Button onClick={storeSync} loading={loading2} style={styles.button}>
						Sync with store
					</Button>
				</Col>
				<Col span={12}></Col>
			</Row>
			<Modal
				title="Confirm Deletion"
				visible={modal}
				onCancel={toggleModal}
				footer={
					<Row>
						<Button onClick={toggleModal} data-testid="close-modal">
							Cancel
						</Button>
						<Button
							disabled={delPhrase !== "DELETE-PROVIDER"}
							onClick={() => console.log("yeet")}
							type="primary"
						>
							Delete
						</Button>
					</Row>
				}
			>
				<Col span={24}>
					<Row>
						<p>Are you sure you want to delete this provider? This cannot be undone.</p>
					</Row>
					<Row>
						<p>To confirm, please type DELETE-PROVIDER to confirm.</p>
					</Row>
					<Input
						value={delPhrase}
						onChange={(val) => setDelPhrase(val.target.value)}
						placeholder="DELETE-PROVIDER"
						data-testid="del-phrase"
					/>
				</Col>
			</Modal>
		</Col>
	);
};

const styles = {
	button: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
};

export default ServiceViewQuickActions;
