import {
	Button,
	Col,
	Divider,
	Row,
	Table,
	Typography,
	Modal,
	Form,
	Input,
	Select,
	InputNumber,
	message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { priceFormatter } from "../../../../Functions/formatters";
import moment from "moment";
import { Link } from "react-router-dom";
import { colorsLight } from "../../../../Styles/Themes";
import { app, database } from "firebase";
import UserContext from "../../../../Context/UserContext";

const AccountsAccountLedger = ({ data }) => {
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [balance, setBalance] = useState(0);
	const [spend, setSpend] = useState(0);
	const [transactions, setTransactions] = useState([]);
	const { userInfo } = useContext(UserContext);

	useEffect(() => {
		if (data.key) {
			database()
				.ref(`Ledger/Accounts/${data.key}/balance`)
				.on("value", (val) => {
					console.log(val.val());
					setBalance(val.val());
				});

			database()
				.ref(`Ledger/Accounts/${data.key}/spend`)
				.on("value", (val) => setSpend(val.val()));

			database()
				.ref(`Ledger/Accounts/${data.key}/transactions`)
				.on("value", (snapshot) => {
					const dat = snapshot.val() ? snapshot.val() : {};
					let keys = Object.keys(dat);
					let list = [];
					keys.forEach((item) => {
						list.push({ ...dat[item], key: item });
					});
					console.log(list);
					setTransactions(list);
				});
		}
	}, [data]);

	const toggleModal = () => setModal(!modal);

	const columns = [
		{
			title: "Date",
			dataIndex: "created",
			key: "created",
			render: (text) => <p style={{ margin: 0 }}>{moment(text).format("D/M/YY HH:mm")}</p>,
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
			render: (text, record) => (
				<p
					style={{
						margin: 0,
						color: record.type === "credit" ? "green" : "red",
						fontWeight: "bold",
					}}
				>
					{record.type === "credit"
						? `+${priceFormatter(Number(text), "dollars")}`
						: `-${priceFormatter(Number(text), "dollars")}`}
				</p>
			),
		},
		{
			title: "Last Sign-in",
			dataIndex: "lastSignIn",
			key: "lastSignIn",
			render: (text) => (
				<p data-testid="members-lastSignIn">
					{text !== "never" ? moment(text).fromNow() : "never"}{" "}
				</p>
			),
		},
		{
			title: "Action",
			render: (record) => (
				<Link to={`/Member/${record.key}`}>
					<Button size="small" data-testid="members-view">
						View
					</Button>
				</Link>
			),
		},
	];

	const transactionHandler = (val) => {
		setLoading(true);
		app()
			.functions("asia-northeast1")
			.httpsCallable("TransactionHandler")({
				account: data.key,
				amount: val.amount,
				type: val.type,
				description: val.description,
				initiatorName: userInfo.displayName,
				reason: val.reason,
			})
			.then(() => {
				toggleModal();
				setLoading(false);
				message.success("Transaction handled");
			})
			.catch((err) => message.error(err));
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Typography.Title level={3}>Account Balance</Typography.Title>
				<Button onClick={toggleModal}>New Transaction</Button>
			</Row>
			<Divider style={{ margin: "6px 0px" }} />
			<Row>
				<Col span={8}>
					<Row>
						<p className="Key">Balance</p>
					</Row>
					<Row>
						<p style={styles.price}>
							{balance
								? priceFormatter(balance, "dollars")
								: priceFormatter(0, "dollars")}
						</p>
					</Row>
				</Col>
				<Col span={8}>
					<Row>
						<p className="Key">Total Spend</p>
					</Row>
					<Row>
						<p style={styles.price}>
							{spend
								? priceFormatter(spend, "dollars")
								: priceFormatter(0, "dollars")}
						</p>
					</Row>
				</Col>
				<Col span={8}></Col>
			</Row>
			<Row>
				<Table columns={columns} dataSource={transactions} style={{ width: "100%" }} />
			</Row>
			<Modal
				visible={modal}
				onCancel={toggleModal}
				title="Create new transaction"
				footer={[
					<Row gutter={12}>
						<Col>
							<Button key="Cancel" onClick={toggleModal}>
								Cancel
							</Button>
						</Col>
						<Col>
							<Button
								loading={loading}
								htmlType="submit"
								key="submit"
								form="transactionForm"
								type="primary"
							>
								Confirm
							</Button>
						</Col>
					</Row>,
				]}
			>
				<Form onFinish={transactionHandler} id="transactionForm" layout="vertical">
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item name="type" label="Type">
								<Select placeholder="Please select transaction type">
									<Select.Option value="credit">Credit</Select.Option>
									<Select.Option value="debit">Debit</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="amount" label="Amount">
								<InputNumber
									formatter={(value) =>
										`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									}
									parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
									style={{ width: "100%" }}
									placeholder="Enter transaction  amount"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<Form.Item name="reason" label="Reason">
								<Select placeholder="Please select transaction reason">
									<Select.Option value="account load">
										Account loadup
									</Select.Option>
									<Select.Option value="refund">Refund</Select.Option>
									<Select.Option value="adjustment">Adjustment</Select.Option>
									<Select.Option value="points return">
										Points return
									</Select.Option>
									<Select.Option value="manual loadup">
										Manual load up
									</Select.Option>
									<Select.Option value="other">Other</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item name="description" label="Description">
								<Input.TextArea placeholder="Enter transaction description" />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</Col>
	);
};

const styles = {
	price: {
		fontSize: 22,
		fontWeight: "bold",
		color: colorsLight("lightBlue"),
	},
};

export default AccountsAccountLedger;
