import { Layout, message, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import BackButton from "../../../Components/Navigation/BackButton";
import { colorsLight } from "../../../Styles/Themes";
import CoachCard from "../../Browse/Components/CoachPackage";

const { Header, Content } = Layout;

const ViewPlaylistPage = () => {
	const [playlistData, setPlaylistData] = useState([]);
	const history = useHistory();
	const { playlistId } = useParams();
	const [packages, setPackages] = useState([]);
	const [courses, setCourses] = useState([]);
	const [courseProviders, setCourseProviders] = useState([]);
	const [services, setServices] = useState([]);
	const [serviceProviders, setServiceProviders] = useState([]);

	useEffect(() => {
		firestore()
			.collection("Playlists")
			.doc(playlistId)
			.get()
			.then((doc) => {
				if (doc.exists) {
					setPlaylistData({ ...doc.data(), key: doc.id });
					return doc.data();
				} else {
					message.error("Playlist doesn't exist!");
				}
			})
			.then(async (playlist) => {
				const serviceProviderIds = getProviders(playlist.services);
				const courseProviderIds = getProviders(playlist.courses);

				let courseProviderPromises = [];
				let coursePromises = [];

				let serviceProviderPromises = [];
				let servicePromises = [];

				let packagePromises = [];

				//SETS UP COACH PACKAGE PROMISES
				playlist.packages.forEach((item) => {
					packagePromises.push(firestore().collection("Packages").doc(item.key).get());
				});

				//SETS UP COURSE PROVIDER PROMISES
				courseProviderIds.forEach((item) => {
					courseProviderPromises.push(
						firestore().collection("CourseProviders").doc(item).get()
					);
				});

				//SETS UP SERVICE PROVIDER PROMISES
				serviceProviderIds.forEach((item) => {
					serviceProviderPromises.push(
						firestore().collection("ServiceProviders").doc(item).get()
					);
				});

				//SETS UP COURSE PROMISES
				playlist.courses.forEach((item) => {
					coursePromises.push(firestore().collection("Courses").doc(item.key).get());
				});

				//SETS UP SERVICE PROMISES
				playlist.services.forEach((item) => {
					servicePromises.push(firestore().collection("Services").doc(item.key).get());
				});

				try {
					//GETS THE COACHING PACKINGS
					await Promise.all(packagePromises)
						.then((docs) => {
							let list = [];
							docs.forEach((doc) => {
								list.push({ ...doc.data(), key: doc.id });
							});
							setPackages(list);
						})
						.catch((err) => {
							console.log("Error resolving coaching packages");
						});

					//GETS THE COURSE PROVIDERS
					await Promise.all(courseProviderPromises)
						.then((docs) => {
							let list = [];
							docs.forEach((doc) => {
								list.push({ ...doc.data(), key: doc.id });
							});
							setCourseProviders(list);
						})
						.catch((err) => {
							console.log("Error resolving course providers");
						});

					//GETS THE SERVICE PROVIDERS
					await Promise.all(serviceProviderPromises)
						.then((docs) => {
							let list = [];
							docs.forEach((doc) => {
								list.push({ ...doc.data(), key: doc.id });
							});
							setServiceProviders(list);
						})
						.catch((err) => {
							console.log("Error resolving service providers");
						});

					//GETS THE COURSES
					await Promise.all(coursePromises)
						.then((docs) => {
							let list = [];
							docs.forEach((doc) => {
								list.push({ ...doc.data(), key: doc.id });
							});
							setCourses(list);
						})
						.catch((err) => {
							console.log("Error resolving courses");
						});

					//GETS THE SERVICES
					await Promise.all(servicePromises)
						.then((docs) => {
							let list = [];
							docs.forEach((doc) => {
								list.push({ ...doc.data(), key: doc.id });
							});
							setServices(list);
						})
						.catch((err) => {
							console.log("Error resolving services");
						});
				} catch (err) {
					message.error("Something went wrong resolving the promises");
					console.log(err);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getProviders = (array) => {
		let providers = [];
		array.forEach((item) => {
			if (!providers.includes(item.provider)) {
				providers.push(item.provider);
			}
		});
		return providers;
	};

	return (
		<Layout className="App">
			<MenuMain active="c" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Typography.Title style={{ margin: 24 }}>{playlistData.name}</Typography.Title>

					<Typography.Title style={{ margin: 24 }} level={3}>
						Coaching Packages
					</Typography.Title>
					<Row>
						<div style={styles.itemRow} data-testid="coach-list">
							{packages.map((item, index) => (
								<div key={index}>
									<CoachCard
										buttonLabel="View Package"
										buttonLink={`/Store-Package-View/${item.key}&Browse`}
										index={index}
										data={item}
										loading={item.loading}
									/>
								</div>
							))}
						</div>
					</Row>

					<Typography.Title style={{ margin: 24 }} level={3}>
						Courses
					</Typography.Title>
					<Row>
						<div style={styles.itemRow} data-testid="coach-list">
							{courses.map((item, index) => (
								<div key={index}>
									<CoachCard
										buttonLabel="View Package"
										buttonLink={`/Store-Package-View/${item.key}&Browse`}
										index={index}
										data={item}
										loading={item.loading}
									/>
								</div>
							))}
						</div>
					</Row>

					<Typography.Title style={{ margin: 24 }} level={3}>
						Services
					</Typography.Title>
					<Row>
						<div style={styles.itemRow} data-testid="coach-list">
							{services.map((item, index) => (
								<div key={index}>
									<CoachCard
										buttonLabel="View Package"
										buttonLink={`/Store-Package-View/${item.key}&Browse`}
										index={index}
										data={item}
										loading={item.loading}
									/>
								</div>
							))}
						</div>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default ViewPlaylistPage;
