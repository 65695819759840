import { Avatar, Button, Col, Row, Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { getInitials } from "../../../Functions/formatters";

const ManagersTable = ({ data }) => {
	const columns = [
		{
			title: "Photo",
			dataIndex: "displayPic",
			key: "displayPic",
			render: (text, record) => (
				<Avatar size={40} src={text} data-testid="managers-avatar">
					{getInitials(record.displayName)}
				</Avatar>
			),
		},
		{
			title: "First Name",
			dataIndex: "firstName",
			key: "firstName",
		},
		{
			title: "Last Name",
			dataIndex: "lastName",
			key: "lastName",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => (
				<Tag color={text === "inactive" ? "red" : "green"} data-testid="managers-status">
					{text.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Last Sign-in",
			dataIndex: "lastSignIn",
			key: "lastSignIn",
			render: (text) => (
				<p data-testid="managers-lastSignIn">
					{text !== "never" ? moment(text).fromNow() : "never"}{" "}
				</p>
			),
		},
		{
			title: "Action",
			render: (record) => (
				<Link to={`/Team-member/${record.key}`}>
					<Button size="small" data-testid="managers-view">
						View
					</Button>
				</Link>
			),
		},
	];

	return (
		<Col className="Card" style={styles.card}>
			<Row style={styles.row}>
				<Table style={styles.table} columns={columns} dataSource={data} />
			</Row>
		</Col>
	);
};

const styles = {
	card: {},
	row: {
		width: "100%",
	},
	table: {
		width: "100%",
	},
};

export default ManagersTable;
