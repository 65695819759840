import { Row, Avatar, Dropdown, Badge, Spin } from "antd";
import React from "react";
import { useContext } from "react";
import UserContext from "../../Context/UserContext";
import { getInitials } from "../../Functions/formatters";
import { LoadingOutlined, BellOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { colorsLight } from "../../Styles/Themes";
import NotificationsDropdown from "./NotificationsDropdown";
import MyAccountDropdown from "./AccountSettingsDropdown";

const MyAccountIcons = () => {
	const { userInfo } = useContext(UserContext);
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

	// const menu = (
	// 	<Menu style={{ marginTop: 3, width: 150, textAlign: "right" }}>
	// 		<Menu.Item>
	// 			<a rel="noopener noreferrer" href="" onClick={() => handleSignOut()}>
	// 				Sign Out
	// 			</a>
	// 		</Menu.Item>
	// 	</Menu>
	// );

	return (
		<Row justify="space-between" style={{ width: 100 }}>
			<div style={{ height: "100%", paddingLeft: 12 }}>
				<Row style={{ paddingTop: 22, height: "100%" }} justify="center" align="middle">
					<QuestionCircleOutlined
						// onClick={handleIntercom}
						style={{ fontSize: 20, color: colorsLight("darkGrey"), cursor: "pointer" }}
						data-testid="menu-help"
					/>
				</Row>
			</div>
			{/* <div>
				<Dropdown
					overlay={<NotificationsDropdown />}
					placement="bottomRight"
					trigger={["click"]}
				>
					<Row
						style={{ height: "100%", paddingLeft: 12, paddingRight: 24 }}
						align="middle"
						justify="center"
					>
						<Badge count={6}>
							<BellOutlined
								style={{
									fontSize: 22,
									color: colorsLight("darkGrey"),
									cursor: "pointer",
								}}
								data-testid="menu-notification"
							/>
						</Badge>
					</Row>
				</Dropdown>
			</div> */}
			<div>
				<Dropdown
					overlay={<MyAccountDropdown />}
					placement="bottomRight"
					trigger={["click"]}
					data-testid="menu-account"
				>
					<Avatar
						src={userInfo.displayPic}
						style={{ cursor: "pointer" }}
						size={50}
						data-testid="menu-avatar"
					>
						{userInfo.firstName !== " " ? (
							getInitials(`${userInfo.firstName} ${userInfo.lastName}`)
						) : (
							<div data-testid="menu-spinner">
								<Spin indicator={antIcon} />
							</div>
						)}
					</Avatar>
				</Dropdown>
			</div>
		</Row>
	);
};

export default MyAccountIcons;
