import { Row, Avatar, Col, Typography, Button, Statistic } from "antd";
import React from "react";
import { colorsLight } from "../../../../../Styles/Themes";
import moment from "moment";
import { priceFormatter } from "../../../../../Functions/formatters";
import { Link } from "react-router-dom";

const ProgramListItem = ({ data, index }) => {
	const styles = {
		imagebackground: {
			backgroundImage: `url(${data.coverPic})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			height: "100%",
			width: "100%",
		},
		bgInner: {
			height: "100%",
			width: "100%",
			backgroundColor: colorsLight("cardCover"),
			padding: 24,
		},
		dpWrapper: {
			height: 86,
			width: 86,
			borderRadius: 43,
			borderStyle: "solid",
			borderColor: "white",
			borderWidth: 3,
			boxShadow: "2px 3px 4px rgba(0,0,0,0.5)",
		},
	};

	return (
		<Row key={index} className="Card" style={{ padding: 0, overflow: "hidden" }}>
			<Col style={styles.imagebackground} flex="150px">
				<Row style={styles.bgInner} justify="center" align="middle">
					<Row style={styles.dpWrapper}>
						<Avatar src={data.displayPic} size={80} />
					</Row>
				</Row>
			</Col>
			<Col style={{ padding: "12px 0px 12px 12px" }} flex="auto">
				<Row>
					<Typography.Title style={{ width: 400 }} level={4}>
						{data.name}
					</Typography.Title>
				</Row>
				<Row gutter={24}>
					<Col>
						<Statistic
							title="Purchase date"
							value={moment(data.created).format("Do MMM YYYY @ h:mma")}
							valueStyle={{ fontSize: 16 }}
						/>
					</Col>
					<Col>
						<Statistic
							title="Price"
							value={priceFormatter(data.price, "dollars")}
							valueStyle={{ fontSize: 16 }}
						/>
					</Col>
				</Row>
			</Col>
			<Col style={{ padding: 12 }} flex="150px">
				<Row style={{ height: "100%" }} justify="end" align="bottom">
					<Link to={`/ViewProgram/${data.key}`}>
						<Button>View Program</Button>
					</Link>
				</Row>
			</Col>
		</Row>
	);
};

export default ProgramListItem;
