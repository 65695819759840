import { Row, Col, Typography, Avatar } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { colorsLight } from "../../Styles/Themes";

const NotificationsDropdown = () => {
	const notifications = [
		{
			title: "New course added to playlist!",
			message: "Interested in moving your career into the artificial intelligence space?",
			image:
				"https://scontent-syd2-1.xx.fbcdn.net/v/t1.0-9/1611011_10153271373023172_8114151324394260492_n.jpg?_nc_cat=110&ccb=2&_nc_sid=09cbfe&_nc_ohc=C7IWWeOwhXcAX9teHHO&_nc_ht=scontent-syd2-1.xx&oh=364837ae19a9931cf140046a9dc7c9cb&oe=5FFF4D77",
		},
		{
			title: "You have an upcoming session",
			message: "Your session with Gabrielle Gleeson is coming up within the next 24 hours...",
			image:
				"https://firebasestorage.googleapis.com/v0/b/careerhub-a50a2.appspot.com/o/displayPictures%2FBtGZtIm0?alt=media&token=d6df0045-fe62-42f7-aa7c-f7bd4d316c4f",
		},
	];

	return (
		<Col style={styles.card} data-testid="notification-dropdown">
			<Row>
				<Typography.Title level={4}>Notifications</Typography.Title>
			</Row>

			{notifications.map((item, index) => (
				<Row
					key={index}
					style={{ width: "100%", marginTop: 12 }}
					data-testid="notification-item"
				>
					<Avatar size={60} src={item.image} style={{ marginTop: 3 }} />
					<div style={{ paddingLeft: 12 }}>
						<Link style={{ fontSize: 16, margin: 0, width: 250, fontWeight: 500 }}>
							{item.title}
						</Link>
						<p style={{ fontSize: 12, margin: 0, width: 250 }}>{item.message}</p>
					</div>
				</Row>
			))}
		</Col>
	);
};

const styles = {
	card: {
		backgroundColor: "white",
		boxShadow: "3px 3px 3px rgba(0,0,0,0.15)",
		width: 350,
		padding: 12,
		borderWidth: 1,
		borderStyle: "solid",
		borderRadius: 12,
		borderColor: colorsLight("lightgrey"),
		marginTop: -12,
	},
};

export default NotificationsDropdown;
