import { Col, Row, Table, Typography, Button, message, Popconfirm } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { priceFormatter } from "../../Functions/formatters";
import { app, firestore } from "firebase";
import { FolderViewOutlined, DeleteOutlined } from "@ant-design/icons";

const FulfilmentTable = ({ data }) => {
	const [loading, setLoading] = useState(false);

	const deleteRecord = (recordId) => {
		setLoading(true);
		firestore()
			.collection("Fulfilment")
			.doc(recordId)
			.delete()
			.then((res) => {
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				message.error("Could not delete record, check log for more info");
				setLoading(false);
			});
	};

	const columns = [
		{
			title: "Member Photo",
			dataIndex: "memberDisplayPic",
			key: "memberDisplayPic",
			render: (text) => <img src={text} style={styles.memberImage} />,
		},
		{
			title: "Member",
			dataIndex: "memberName",
			key: "memberName",
			render: (text, record) => <p style={styles.memberName}>{text}</p>,
		},
		{
			title: "Item",
			dataIndex: "name",
			key: "name",
			render: (text) => <p style={styles.memberName}>{text}</p>,
		},
		{
			title: "Provider",
			dataIndex: "providerName",
			key: "providerName",
			render: (text) => <p style={styles.memberName}>{text}</p>,
		},
		{
			title: "Created",
			dataIndex: "created",
			key: "created",
			render: (text) => (
				<p style={styles.memberName}>{moment(text).format("Do MMM YY @ h:mma")}</p>
			),
			defaultSortOrder: "descend",
			sorter: {
				compare: (a, b) =>
					moment(a.created).format("YYYYMMDDHHmm") -
					moment(b.created).format("YYYYMMDDHHmm"),
			},
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
			render: (text) => <p style={styles.memberName}>{priceFormatter(text, "dollars")}</p>,
		},
		{
			title: "Profit",
			dataIndex: "profit",
			key: "profit",
			render: (text) => <p style={styles.memberName}>{priceFormatter(text, "dollars")}</p>,
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => <p style={styles.memberName}>{text}</p>,
		},
		{
			title: "Action",
			render: (record) => (
				<Row>
					<Link to={`/Fulfilment/${record.key}`}>
						<Button style={{ margin: 3 }} icon={<FolderViewOutlined />} />
					</Link>
					<Popconfirm title="Are you sure?" onConfirm={() => deleteRecord(record.key)}>
						<Button
							style={{ margin: 3 }}
							loading={loading}
							type="danger"
							icon={<DeleteOutlined />}
						/>
					</Popconfirm>
				</Row>
			),
		},
	];

	return (
		<Col className="Card">
			<Row>
				<Typography.Title level={3}>Fulfilment Queue</Typography.Title>
			</Row>
			<Row style={{ width: "100%" }}>
				<Table style={{ width: "100%" }} columns={columns} dataSource={data} />
			</Row>
		</Col>
	);
};

const styles = {
	memberImage: {
		height: 50,
		width: 50,
		borderRadius: 25,
		boxShadow: "1px 1px 2px rgba(0,0,0,0.5)",
	},
	memberName: {
		margin: 0,
	},
};

export default FulfilmentTable;
