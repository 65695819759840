import { Col, Layout, message, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../../../API/firebase";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import BackButton from "../../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../../Styles/Themes";
import CoachCard from "../Components/CoachPackage";
import CoachDetailsPanel from "./Components/CoachDetailsPanel";
import StorePackageDetails from "./Components/StorePackageDetails";
const { Header, Content } = Layout;

const StoreViewCoachPackage = () => {
	const [packData, setPackData] = useState({});
	const [coachData, setCoachData] = useState({});
	const [otherPacks, setOtherPacks] = useState([]);
	const { packageIdANDref } = useParams();
	const [packageId] = packageIdANDref.split("&");
	const scrollRef = React.createRef();
	const history = useHistory();

	useEffect(() => {
		packData.name && scrollRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packageIdANDref]);

	//PACK DATA LISTENER
	useEffect(() => {
		packData.name &&
			setPackData({
				name: "Loading",
				delivery: "Loading",
				price: 0,
				summary: "",
				details: "",
			});
		let packListener = async () =>
			await firebase
				.firestore()
				.collection("Packages")
				.doc(packageId)
				.onSnapshot((doc) => {
					doc.exists && setPackData({ ...doc.data(), key: doc.id });
				});

		packListener();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packageIdANDref]);

	//SINGLE GET COACH DATA REQUEST ON LOAD
	useEffect(() => {
		if (packData.owner) {
			firebase
				.firestore()
				.collection("Coaches")
				.doc(packData.owner)
				.get()
				.then((doc) => {
					doc.exists && setCoachData({ ...doc.data(), key: doc.id });
				});
			firebase
				.firestore()
				.collection("Packages")
				.where("owner", "==", packData.owner)
				.get()
				.then(async (snap) => {
					if (snap.size > 1) {
						let list = [];
						snap.forEach((doc) => {
							doc.id !== packageId && list.push({ ...doc.data(), key: doc.id });
						});
						setOtherPacks(list);
					} else {
						let req = await firebase
							.firestore()
							.collection("Packages")
							.where("owner", "!=", packData.owner)
							.limit(2)
							.get();
						let list = [];
						req.forEach((doc) => {
							list.push({ ...doc.data(), key: doc.id });
						});
						setOtherPacks(list);
					}
				})
				.catch((err) => {
					message.error(err.message);
				});
		}
	}, [packData, packageId]);

	return (
		<Layout className="App">
			<MenuMain />
			<Layout>
				<Header className="Header">
					<Row
						ref={scrollRef}
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col
						style={{
							backgroundColor: colorsLight("lightgrey"),
							height: "100%",
							padding: 24,
						}}
					>
						<Row justify="center" style={{ width: "100%" }}>
							<Row style={{ maxWidth: maxContentWidth, width: "100%" }}>
								<Row style={{ width: "100%" }}>
									<Col span={16} data-testid="package-data">
										<StorePackageDetails data={packData} />
									</Col>
									<Col
										style={{ paddingLeft: 24 }}
										span={8}
										data-testid="package-coach"
									>
										<CoachDetailsPanel data={coachData} />
									</Col>
								</Row>
								<Row justify="start" style={{ width: "100%", marginTop: 42 }}>
									<Typography.Title level={2}>
										You might also like...
									</Typography.Title>
								</Row>
								<Row style={{ width: "100%", overflow: "visible" }} justify="start">
									<div
										style={{
											padding: 12,
											display: "flex",
											flexDirection: "row",
											width: "100%",
											marginLeft: -24,
											overflow: "scroll",
										}}
										data-testid="package-list"
									>
										{otherPacks.map((item, index) => (
											<CoachCard
												buttonLabel="View Package"
												buttonLink={`/Store-Package-View/${item.key}&Browse`}
												index={index}
												data={item}
											/>
										))}
									</div>
								</Row>
							</Row>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default StoreViewCoachPackage;
