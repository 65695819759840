import { Row, Col, Layout, Typography, Button } from "antd";
import React, { useEffect, useState } from "react";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import MenuMain from "../../Components/MainMenu";
import { colorsLight } from "../../Styles/Themes";
import { PlusOutlined } from "@ant-design/icons";
import AccountsTable from "./Components/AccountsTable";
import AddAccount from "./Components/AddAccount";
import { firestore } from "firebase";

const { Header, Content } = Layout;
const { Title } = Typography;

const AccountsPage = () => {
	const [addModal, setAddModal] = useState(false);
	const [accounts, setAccounts] = useState([]);

	const dismissModal = () => setAddModal(false);

	useEffect(() => {
		let listener = firestore()
			.collection("Accounts")
			.onSnapshot((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setAccounts(list);
				console.log(list);
			});

		return () => {
			listener();
		};
	}, []);

	return (
		<Layout className="App">
			<MenuMain active="7" />
			<Layout>
				<Header className="Header">
					<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row justify="space-between">
							<Title data-testid="account-title">Accounts</Title>
							<Button
								onClick={() => setAddModal(!addModal)}
								type="primary"
								style={{ marginTop: 12 }}
								data-testid="account-button"
							>
								<PlusOutlined /> New Account
							</Button>
						</Row>

						<Row style={styles.row}>
							<Col span={24} data-testid="account-table">
								<AccountsTable data={accounts} />
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
			<AddAccount visible={addModal} dismiss={dismissModal} />
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 22,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default AccountsPage;
