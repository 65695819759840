import { Col, Layout, Row } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import MyAccountIcons from "../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../Components/MainMenu";
import BackButton from "../../../Components/Navigation/BackButton";
import AccountDisabledAlert from "../../../Components/Tools/UserDisabledAlert";
import { colorsLight } from "../../../Styles/Themes";
import CoachAvailabilityTable from "./Components/CoachAvailability-Table";
import CoachCommercialsPanel from "./Components/CoachCommercialsPanel";
import CoachPackages from "./Components/CoachPackages";
import CoachViewHeader from "./Components/CoachView-Header";
import CoachViewQuickActions from "./Components/CoachViewQuickActions";

const { Header, Content } = Layout;

const CoachViewPage = () => {
	const [data, setData] = useState({});
	const [privateData, setPrivateData] = useState({});
	const [packages, setPackages] = useState([]);
	const [commercials, setCommercials] = useState({});
	const [editing, setEditing] = useState(false);
	const history = useHistory();

	const { coachId } = useParams();

	const toggleEdit = () => setEditing(!editing);

	useEffect(() => {
		let publicData = firestore()
			.collection("Coaches")
			.doc(coachId)
			.onSnapshot((doc) => {
				setData({ ...doc.data(), key: doc.id });
			});

		let privateData = firestore()
			.collection("UserRecords")
			.doc(coachId)
			.onSnapshot((doc) => {
				setPrivateData({ ...doc.data(), key: doc.id });
			});

		let packagesData = firestore()
			.collection("Coaches")
			.doc(coachId)
			.collection("Packages")
			.onSnapshot((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setPackages(list);
			});

		let commslistener = firestore()
			.collection("Commercials")
			.doc(coachId)
			.onSnapshot((doc) => {
				setCommercials({ ...doc.data(), key: doc.id });
			});

		return () => {
			publicData();
			privateData();
			packagesData();
			commslistener();
		};
	}, [coachId]);

	return (
		<Layout className="App">
			<MenuMain active="3" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						{data.status === "disabled" && <AccountDisabledAlert />}
						<Row style={styles.row}>
							<Col span={16}>
								<CoachViewHeader
									editing={editing}
									toggleEdit={toggleEdit}
									data={data}
									privateData={privateData}
								/>
								<CoachPackages
									data={packages}
									coachData={data}
									markup={commercials.markup}
								/>
							</Col>
							<Col style={{ paddingLeft: 24 }} span={8}>
								<CoachViewQuickActions
									toggleEdit={toggleEdit}
									data={data}
									privateData={privateData}
									commercials={commercials}
								/>
								<CoachCommercialsPanel
									commercials={commercials}
									coachId={coachId}
								/>
								<CoachAvailabilityTable data={data} />
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 22,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	row: {
		width: "100%",
	},
};

export default CoachViewPage;
