import { Row, Col, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import BackButton from "../../../../Components/Navigation/BackButton";
import { useHistory, useParams } from "react-router-dom";
import MyAccountIcons from "../../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../../Components/MainMenu";
import { colorsLight } from "../../../../Styles/Themes";
import CoachPackageViewHeader from "./Components/CoachPackageViewHeader";
import CoachPackageViewQuickActions from "./Components/CoachPackageViewQuickActions";
import CoachPackageViewCommercials from "./Components/CoachPackageViewCommercials";
import CoachPackageDetails from "./Components/CoachPackageDetails";
import { firestore } from "firebase";
import StoreSyncStatus from "../../../../Components/StoreSyncStatus2";
import { getImage } from "../../../../Functions/utilities";
const { Header, Content } = Layout;

const CoachPackageView = () => {
	const [data, setData] = useState({});
	const { coachIdANDpackageId } = useParams();
	const [coachId, packageId] = coachIdANDpackageId.split("&");
	const [editing, setEditing] = useState(false);
	const [originalRecord] = useState({});
	const history = useHistory();

	//EDITING STATE
	const [editData, setEditData] = useState({
		name: "",
		details: "",
		sessions: 0,
		duration: 0,
		sessionFrequency: "",
		summary: "",
		delivery: "",
		costPrice: 0,
	});
	const [markup, setMarkup] = useState(0);
	const [salePrice, setSalePrice] = useState(0);
	const [profit, setProfit] = useState(0);
	const [coachData, setCoachData] = useState({});

	const toggleEditing = () => setEditing(!editing);

	const handleEditData = (params, values) => {
		if (typeof params === "string") {
			setEditData({ ...editData, [params]: values });
		} else {
			const newData = {};
			params.forEach((param) => {
				newData[param] = values[param];
			});
			setEditData({ ...editData, ...newData });
		}
	};

	useEffect(() => {
		let listener = firestore()
			.collection("Coaches")
			.doc(coachId)
			.collection("Packages")
			.doc(packageId)
			.onSnapshot((doc) => {
				setData({ ...doc.data(), key: doc.id });
			});

		return () => {
			listener();
		};
	}, [coachId, packageId]);

	useEffect(() => {
		if (coachId) {
			firestore()
				.collection("Coaches")
				.doc(coachId)
				.get()
				.then((doc) => {
					doc.exists && setCoachData({ ...doc.data(), key: doc.id });
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [data, coachId]);

	useEffect(() => {
		if (markup > 0 && editData.costPrice > 0) {
			let markupToPercentage = markup / 100;
			let cogs = editData.costPrice;
			let prof = Number((cogs * markupToPercentage).toFixed(2));
			setSalePrice(prof + cogs);
			setProfit(prof);
		}
	}, [markup, editData.costPrice]);

	useEffect(() => {
		if (data.name) {
			firestore()
				.collection("Commercials")
				.doc(coachId)
				.get()
				.then((doc) => {
					doc.exists && setMarkup(doc.data().markup);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [data, coachId]);

	useEffect(() => {
		handleEditData(
			[
				"name",
				"details",
				"summary",
				"sessions",
				"duration",
				"sessionFrequency",
				"delivery",
				"costPrice",
				"primaryCategory",
			],
			data
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const updatePackage = () => {
		const payload = {
			...editData,
			displayPic: coachData.displayPic,
			coachName: coachData.displayName,
		};
		firestore()
			.collection("Coaches")
			.doc(coachId)
			.collection("Packages")
			.doc(packageId)
			.update(data.coverPic ? payload : { ...payload, coverPic: getImage() })
			.then(() => {
				toggleEditing();
			})
			.catch(() => {
				message.error("Could not update package");
			});
	};

	return (
		<Layout className="App">
			<MenuMain active="3" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={styles.main}>
						<Row justify="center">
							<Col span={16}>
								<CoachPackageViewHeader
									editData={editData}
									updateEditData={handleEditData}
									editing={editing}
									data={data}
									updatePackage={updatePackage}
									toggleEditing={toggleEditing}
									markup={markup}
								/>
							</Col>
							<Col style={{ paddingLeft: 24 }} span={8}>
								<CoachPackageViewQuickActions
									toggleEditing={toggleEditing}
									data={data}
									markup={markup}
									coachData={coachData}
								/>
								<CoachPackageDetails
									editing={editing}
									data={data}
									delivery={editData.delivery}
									primaryCategory={editData.primaryCategory}
									setDelivery={handleEditData}
								/>
								<StoreSyncStatus data={data} type="Packages" markup={markup} />
								<CoachPackageViewCommercials
									data={data}
									originalRecord={originalRecord}
									editing={editing}
									costPrice={editData.costPrice}
									setCostPrice={handleEditData}
									salePrice={salePrice}
									profit={profit}
								/>
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100%",
		backgroundColor: colorsLight("lightgrey"),
		padding: 24,
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
};

export default CoachPackageView;
