import { Button, Col, Row, Statistic, Typography, Modal } from "antd";
import { firestore } from "firebase";
import React, { useState } from "react";

const CalendarSyncChecker = () => {
	const [loading, setLoading] = useState(false);
	const [unsyncedCoaches, setUnsyncedCoaches] = useState([]);
	const [syncedCoaches, setSyncedCoaches] = useState([]);
	const [reads, setReads] = useState(0);
	const [modal, setModal] = useState(false);
	const [modal2, setModal2] = useState(false);

	const checkCalendar = async () => {
		setLoading(true);
		let reads = 0;
		//GETS LIST OF COACHES
		let coachesList = await firestore()
			.collection("Coaches")
			.get()
			.then((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
					reads++;
				});
				return list;
			});
		//GETS LIST OF ANON-EVENTS
		let anonEvents = await firestore()
			.collection("AnonAvailability")
			.get()
			.then((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
					reads++;
				});
				return list;
			});
		setReads(reads);
		//GETS LIST OF COACHES SYNC STATUS
		let syncedCoaches = [];
		let unsyncedCoaches = [];
		coachesList.forEach((coach) => {
			if (coach.calendarSync === true) {
				syncedCoaches.push(coach);
			} else {
				unsyncedCoaches.push(coach);
			}
		});
		setUnsyncedCoaches(unsyncedCoaches);
		//GETS LIST OF CAL SYNCED COACHES AND THE NUMBER OF EVENTS
		let coachesAndEvents = [];
		syncedCoaches.forEach((coach) => {
			let events = anonEvents;
			let result = events.filter((evnt) => evnt.owner === coach.key);
			coachesAndEvents.push({
				coachName: coach.displayName,
				eventCount: result.length,
				eventData: result.data,
				key: coach.key,
			});
		});
		setSyncedCoaches(coachesAndEvents);
		setLoading(false);
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Typography.Title level={5}>Calendar sync checker</Typography.Title>
				<Button size="small" onClick={checkCalendar} loading={loading}>
					Check
				</Button>
			</Row>
			<Row>
				<Col onClick={() => setModal2(!modal2)} span={12}>
					<Statistic title="Synced coaches" value={syncedCoaches.length} />
				</Col>
				<Col onClick={() => setModal(!modal)} span={12}>
					<Statistic title="Unsynced coaches" value={unsyncedCoaches.length} />
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Statistic title="Reads" value={reads} />
				</Col>
				<Col span={12}></Col>
			</Row>
			<Modal onCancel={() => setModal(!modal)} onOk={() => setModal(!modal)} visible={modal}>
				<Row justify="space-between">
					<p>Name</p>
					<p>Email</p>
				</Row>
				{unsyncedCoaches.map((coach) => (
					<Row justify="space-between">
						<p>{coach.displayName}</p>
						<p>{coach.email}</p>
					</Row>
				))}
			</Modal>
			<Modal
				onCancel={() => setModal2(!modal2)}
				onOk={() => setModal2(!modal2)}
				visible={modal2}
			>
				<Row justify="space-between">
					<p>Name</p>
					<p>Events</p>
				</Row>
				{syncedCoaches.map((coach) => (
					<Row justify="space-between">
						<p>{coach.coachName}</p>
						<p>{coach.eventCount}</p>
					</Row>
				))}
			</Modal>
		</Col>
	);
};

const styles = {};

export default CalendarSyncChecker;
