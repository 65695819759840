import { Col, Row, Typography, Button } from "antd";
import React, { useState } from "react";
import { colorsLight } from "../../../../Styles/Themes";
import { PlusOutlined } from "@ant-design/icons";
import AddToPlaylistModal from "../../../Playlists/Components/AddToPlaylistModal";
import { priceFormatter } from "../../../../Functions/formatters";

const SPProfilePackageCard = ({ data = {} }) => {
	const [modal, setModal] = useState(false);

	const styles = {
		headerImage: {
			backgroundImage: `url(${data.coverPic})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			height: "100%",
			width: "100%",
		},
		imageCover: {
			backgroundColor: colorsLight("cardCover"),
			height: "100%",
			width: "100%",
			padding: 12,
		},
	};

	const toggleModal = () => setModal(!modal);

	return (
		<Row className="Card" style={{ padding: 0, overflow: "hidden" }}>
			<Col flex="150px">
				<Row style={styles.headerImage}>
					<Row justify="start" align="middle" style={styles.imageCover}></Row>
				</Row>
			</Col>
			<Col flex="auto" style={{ padding: 12 }}>
				<Row>
					<Typography.Title level={4} data-testid="package-name">
						{data.name}
					</Typography.Title>
					<Button
						onClick={toggleModal}
						icon={<PlusOutlined />}
						style={{ position: "absolute", top: 12, right: 12 }}
					>
						Add to playlist
					</Button>
				</Row>
				<Row>
					<Typography.Paragraph style={{ width: "100%" }} data-testid="package-summary">
						{data.summary}
					</Typography.Paragraph>
				</Row>
				<Row style={{ width: "100%" }} justify="end" align="bottom">
					<Col>
						<p className="Key">Price</p>
						<p
							style={{
								fontSize: 22,
								margin: 0,
								fontWeight: "bold",
								color: colorsLight("lightBlue"),
							}}
							data-testid="package-price"
						>
							{priceFormatter(data.price, "dollars")}
						</p>
					</Col>
				</Row>
			</Col>
			<AddToPlaylistModal visible={modal} dismiss={toggleModal} type="Services" data={data} />
		</Row>
	);
};

export default SPProfilePackageCard;
