import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Switch, TimePicker, Form, Button } from "antd";
import moment from "moment";
import firebase from "../../../../API/firebase";

const AvailabilityModalCoach = ({ visible, defs, dismiss, userInfo }) => {
	const [data, setData] = useState();
	const [active, setActive] = useState([]);
	const [changes, setChanges] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (defs && !data) {
			let list = [];
			let list2 = [];
			defs.forEach((day, index) => {
				list.push({
					day: day.day,
					active: day.active,
					start: moment(day.start, "HHmm"),
					finish: moment(day.finish, "HHmm"),
				});
				if (!day.active) {
					list2.push(index);
				}
			});
			setActive(list2);
			setData(list);
		}
	}, [defs, data]);

	const handleActive = (index) => {
		if (active.includes(index)) {
			let cleaned = active.filter((x) => x !== index);
			setChanges(true);
			setActive(cleaned);
		} else {
			setChanges(true);
			setActive((active) => [...active, index]);
		}
	};

	const updateAvail = async (val) => {
		setLoading(true);
		let arr = val.availability;
		let list = [];
		arr.forEach((item) => {
			list.push({
				day: item.day,
				active: item.active,
				start: moment(item.start).format("HHmm"),
				finish: moment(item.finish).format("HHmm"),
			});
		});
		try {
			await firebase.firestore().collection("Coaches").doc(userInfo.key).update({
				availability: list,
			});
			setLoading(false);
			setData();
			setActive([]);
			setChanges(false);
			dismiss();
			return;
		} catch (err) {
			setLoading(false);
			console.log("Error: Couldnt update availability");
			console.log(err);
		}
		setLoading(false);
	};

	return (
		<Modal
			destroyOnClose
			width={750}
			visible={visible}
			onCancel={dismiss}
			footer={[
				<Button key="cancel" onClick={dismiss} data-testid="cancel">
					Cancel
				</Button>,
				<Button
					disabled={!changes}
					type="primary"
					form="myForm"
					loading={loading}
					key="submit"
					htmlType="submit"
					data-testid="save"
				>
					Save
				</Button>,
			]}
		>
			<Col span={24}>
				<Row>
					<h3>Update your availability:</h3>
				</Row>
				<Row>
					<Col span={6}>
						<p>Day</p>
					</Col>
					<Col span={6}>
						<p>Start</p>
					</Col>
					<Col span={6}>
						<p>Finish</p>
					</Col>
					<Col span={6}>
						<p>Active</p>
					</Col>
				</Row>
				{data && (
					<Form
						id="myForm"
						layout="vertical"
						initialValues={{ availability: data }}
						hideRequiredMark
						onFinish={updateAvail}
					>
						<Form.List name="availability">
							{(fields) => (
								<>
									{fields.map((field, index) => (
										<Row
											key={index}
											style={{
												marginBottom: 0,
											}}
										>
											<Col span={6}>
												<h3 style={{ fontWeight: "normal" }}>
													{data[index].day}
												</h3>
											</Col>
											<Col span={6}>
												<Form.Item
													name={[field.name, "start"]}
													rules={[
														{
															type: "object",
															required: true,
															message: "Please select time!",
														},
													]}
												>
													<TimePicker
														disabled={active.includes(index)}
														onChange={() => setChanges(true)}
														format="h:mm a"
														data-testid="start"
													/>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													name={[field.name, "finish"]}
													rules={[
														{
															type: "object",
															required: true,
															message: "Please select time!",
														},
													]}
												>
													<TimePicker
														disabled={active.includes(index)}
														onChange={() => setChanges(true)}
														format="h:mm a"
														data-testid="finish"
													/>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													name={[field.name, "active"]}
													valuePropName="checked"
												>
													<Switch
														onChange={() => handleActive(index)}
														data-testid="switch"
													/>
												</Form.Item>
											</Col>
										</Row>
									))}
								</>
							)}
						</Form.List>
					</Form>
				)}
			</Col>
		</Modal>
	);
};

export default AvailabilityModalCoach;
