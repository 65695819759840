import { Row, Col, Layout, Button, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "../../Styles/main.scss";
import MenuMain from "../../Components/MainMenu";
import { colorsLight } from "../../Styles/Themes";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import FulfilmentTable from "../../Components/Home/FulfilmentTable";
import { app, firestore } from "firebase";
import UserContext from "../../Context/UserContext";
const { Header, Content } = Layout;

const Home = () => {
	const [fulfilmentQueue, setFulfilmentQueue] = useState([]);
	const { userInfo } = useContext(UserContext);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		let listener = firestore()
			.collection("Fulfilment")
			.where("status", "!=", "Completed")
			.onSnapshot((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setFulfilmentQueue(list);
			});

		return () => {
			listener();
		};
	}, []);

	return (
		<Layout className="App">
			<MenuMain active="1" />
			<Layout>
				<Header className="Header">
					<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={{ backgroundColor: colorsLight("lightgrey"), height: "100%" }}>
						<Row>
							<Col span={8}></Col>
							<Col span={8}></Col>
							<Col span={8}></Col>
						</Row>
						<Row style={{ padding: "12px 24px 24px 24px" }}>
							<Col span={24}>
								<FulfilmentTable data={fulfilmentQueue} />
							</Col>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	warningBanner: {
		backgroundColor: "red",
		height: 30,
		width: "100%",
	},
};

export default Home;
