import { Row, Modal, Col, Form, Input, Select, message, Upload, Progress, Button } from "antd";
import { firestore, storage } from "firebase";
import React, { useEffect, useState } from "react";
import { colorsLight } from "../../../Styles/Themes";
import { FileImageOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

const AddPlaylistModal = ({ visible, dismiss }) => {
	const [privatePlaylist, setPrivatePlaylist] = useState();
	const [accounts, setAccounts] = useState([]);
	const [coverPic, setCoverPic] = useState("");
	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		firestore()
			.collection("Accounts")
			.get()
			.then((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setAccounts(list);
			})
			.catch((err) => message.error(err));
	}, []);

	const uploadFile = async ({ onError, onSuccess, file }) => {
		setLoading(true);
		const reff = `playlistPics/${file.name}`;
		let uploadTask = await storage().ref().child(reff).put(file);
		uploadTask.task.on(
			"state_changed",
			(snapshot) => {
				let progress1 = (snapshot.task.bytesTransferred / snapshot.task.totalBytes) * 100;
				setProgress(Number(progress1.toFixed(0)));
			},
			(err) => {
				onError();
				setLoading(false);
				setProgress(0);
				message.error("Something went wrong");
				console.log(err);
			},
			() => {
				uploadTask.task.snapshot.ref.getDownloadURL().then(async (downloadUrl) => {
					onSuccess();
					setCoverPic(downloadUrl);
					setLoading(false);
					setProgress(0);
					return;
				});
			}
		);
	};

	const deleteImage = async () => {
		if (coverPic) {
			try {
				await storage().refFromURL(coverPic).delete();
				setCoverPic("");
				return;
			} catch (err) {
				message.error("Could not delete image");
				return;
			}
		} else {
			setCoverPic("");
			return;
		}
	};

	const closeModal = () => {
		if (coverPic) {
			deleteImage();
			dismiss();
		} else {
			dismiss();
		}
	};

	const handleTypeChange = (val) =>
		val === "Private" ? setPrivatePlaylist(true) : setPrivatePlaylist(false);

	const styles = {
		imageHeader: {
			backgroundImage: `url(${coverPic})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			height: "100%",
			width: "100%",
		},
		delWrapper: {
			height: 30,
			width: 30,
			borderRadius: 15,
			backgroundColor: "white",
			cursor: "pointer",
			position: "absolute",
			top: 20,
			right: 12,
			boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		},
	};

	const savePlaylist = (val) => {
		val.type === "Public" ? savePublicPlaylist(val) : savePrivatePlaylist(val);
	};

	const savePublicPlaylist = (val) => {
		firestore()
			.collection("Playlists")
			.add({
				coverPic,
				packages: [],
				courses: [],
				services: [],
				providers: [],
				name: val.name,
				type: val.type,
				created: moment().format(),
				summary: val.summary,
				coverPicOwner: true,
			})
			.then(() => {
				dismiss();
				return;
			})
			.catch((err) => message.error(err));
	};

	const savePrivatePlaylist = (val) => {
		firestore()
			.collection("Playlists")
			.add({
				coverPic: coverPic || "",
				packages: [],
				courses: [],
				services: [],
				providers: [],
				name: val.name,
				type: val.type,
				owner: val.type === "Private" ? accounts[val.owner].key : "none",
				ownerName: val.type === "Private" ? accounts[val.owner].name : "none",
				created: moment().format(),
				summary: val.summary || "",
				coverPicOwner: true,
			})
			.then(() => {
				dismiss();
				return;
			})
			.catch((err) => message.error(err));
	};

	const DeleteButton = () => {
		return (
			<Row onClick={deleteImage} justify="center" align="middle" style={styles.delWrapper}>
				<DeleteOutlined />
			</Row>
		);
	};

	return (
		<Modal
			width={600}
			title="Add new playlist"
			visible={visible}
			onCancel={closeModal}
			footer={[
				<Button key="cancel" onClick={closeModal}>
					Cancel
				</Button>,
				<Button key="submit" htmlType="submit" form="playlistForm" type="primary">
					Create
				</Button>,
			]}
		>
			<Col>
				<Row style={{ width: "100%", height: 180, paddingBottom: 24 }}>
					{coverPic && (
						<Row justify="end" align="top" style={styles.imageHeader}>
							<DeleteButton />
						</Row>
					)}
					{!coverPic && (
						<Row
							justify="center"
							align="middle"
							style={{
								backgroundColor: colorsLight("grey"),
								height: "100%",
								width: "100%",
							}}
						>
							<Upload
								style={{ height: "100%", width: "100%" }}
								customRequest={uploadFile}
								onRemove={deleteImage}
								showUploadList={false}
							>
								<Col>
									{!loading ? (
										<>
											<Row justify="center" align="middle">
												<FileImageOutlined
													style={{
														fontSize: 32,
														marginBottom: 12,
														color: colorsLight("darkGrey"),
													}}
												/>
											</Row>
											<Row justify="center" align="middle">
												<p
													style={{
														fontSize: 18,
														color: colorsLight("darkGrey"),
													}}
												>
													Upload playlist cover photo
												</p>
											</Row>
										</>
									) : (
										<Row justify="center" align="middle">
											<Progress type="circle" percent={progress} width={80} />
										</Row>
									)}
								</Col>
							</Upload>
						</Row>
					)}
				</Row>
				<Form
					id="playlistForm"
					onFinish={savePlaylist}
					initialValues={{ type: "Public" }}
					layout="vertical"
				>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item label="Playlist name" name="name" required={true}>
								<Input placeholder="Please enter playlist name" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Playlist type" name="type" required={true}>
								<Select onChange={handleTypeChange}>
									<Select.Option value="Public">Public</Select.Option>
									<Select.Option value="Private">Private</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					{privatePlaylist && (
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Account" name="owner" required={true}>
									<Select>
										{accounts.map((item, index) => (
											<Select.Option value={index}>{item.name}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}></Col>
						</Row>
					)}
					<Row>
						<Col span={24}>
							<Form.Item label="Description" name="summary" required={true}>
								<Input.TextArea placeholder="Please enter description of playlist" />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>
		</Modal>
	);
};

export default AddPlaylistModal;
