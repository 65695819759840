import React, { useState } from "react";

const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
	const [coaches, setCoaches] = useState([]);
	const [coachPacks, setCoachPacks] = useState([]);
	const [courseProviders, setCourseProviders] = useState([]);
	const [serviceProviders, setServiceProviders] = useState([]);

	return (
		<DataContext.Provider
			value={{
				coaches,
				setCoaches,
				coachPacks,
				setCoachPacks,
				courseProviders,
				setCourseProviders,
				serviceProviders,
				setServiceProviders,
			}}
		>
			{children}
		</DataContext.Provider>
	);
};

export default DataContext;
