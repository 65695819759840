import { Button, Col, Drawer, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import firebase from "../../../API/firebase";
import ReactQuill from "react-quill";
import "../../../Styles/quill.snow.css";
import { getImage } from "../../../Functions/utilities";

const AddServiceProvider = ({ visible, dismiss }) => {
	const [loading, setloading] = useState(false);
	const [navRef, setNavRef] = useState("");

	const createProvider = (val) => {
		setloading(true);
		firebase
			.firestore()
			.collection("ServiceProviders")
			.add({
				name: val.providerName,
				websiteUrl: val.websiteUrl,
				displayPic: "",
				coverPic: getImage(),
				summary: val.summary || " ",
				details: val.details || " ",
				categories: [],
				status: "inactive",
				cpType: "lib",
			})
			.then((res) => {
				firebase
					.firestore()
					.collection("Commercials")
					.doc(res.id)
					.set({ markup: 15, type: "ServiceProviders" });
				return res;
			})
			.then((res) => {
				setNavRef(`/Service-Provider/${res.id}`);
				setloading(false);
			})
			.catch((err) => {
				setloading(false);
			});
	};

	return (
		<Drawer
			destroyOnClose={true}
			visible={visible}
			title="Add a new service provider"
			width={600}
			onClose={dismiss}
			footer={
				<Row justify="end">
					<Button onClick={dismiss} data-testid="service-cancel">
						Cancel
					</Button>
					<Button
						type="primary"
						form="myForm"
						loading={loading}
						key="submit"
						htmlType="submit"
						style={{ marginLeft: 12 }}
						data-testid="service-create"
					>
						Create
					</Button>
				</Row>
			}
		>
			<Form id="myForm" onFinish={createProvider} layout="vertical" hideRequiredMark>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="providerName"
							label="Provider Name"
							rules={[{ required: true, message: "Please enter provider name" }]}
						>
							<Input
								placeholder="Please enter provider name"
								data-testid="service-name"
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="websiteUrl"
							label="Website URL"
							rules={[{ required: true, message: "Please enter provider's website" }]}
						>
							<Input
								placeholder="Please enter website URL"
								data-testid="service-website"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="summary"
							label="Provider Summary"
							rules={[{ required: true, message: "Please enter provider summary" }]}
						>
							<TextArea
								placeholder="Please enter provider summary"
								data-testid="service-summary"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item name="details" label="Provider Details">
							<ReactQuill
								placeholder="Pleast enter provider details"
								theme="snow"
								style={{ width: "100%" }}
								data-testid="service-details"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}></Col>
					<Col span={12}></Col>
				</Row>
			</Form>
			{navRef && <Redirect to={navRef} />}
		</Drawer>
	);
};

export default AddServiceProvider;
