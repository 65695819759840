import firebase from "../API/firebase";

//THIS FUNCTION FIGURES OUT MARKED UP PRICE OF AN ITEM
//getRealItemPrice(providerId, 'CourseProviders', courseId, 'Courses')
export const getRealItemPrice = async (providerId, providerType, itemId, itemType) => {
	if (providerId && itemId) {
		const db = firebase.firestore();

		//GET MARKUP RATE
		let commercials = await db.collection("Commercials").doc(providerId).get();
		let markup = commercials.data().markup || 15;
		let markupAsPerc = markup / 100;

		//GET COST PRICE OF ITEM
		let itemRecord = await db
			.collection(providerType)
			.doc(providerId)
			.collection(itemType)
			.doc(itemId)
			.get();
		let costPrice = itemRecord.data().costPrice;

		//DOES THE MATHS
		let profit = costPrice * markupAsPerc;
		let result = Number((costPrice + profit).toFixed(2));
		return result;
	} else {
		return "error";
	}
};

export const getSalePrice = (cost, markup, type) => {
	const markupAsPerc = markup / 100;
	const profit = cost * markupAsPerc;
	const salePrice = cost + profit;
	if (type === "points") {
		const toPoints = salePrice * 3.142;
		const toString = Number(toPoints.toFixed(0)).toLocaleString();
		return `${toString} pts`;
	} else if (type === "dollars") {
		const toString = salePrice.toLocaleString("en-AU", { style: "currency", currency: "AUD" });
		return toString;
	} else {
		return salePrice;
	}
};

export const getProfit = (cost, markup) => {
	const ans = Number(cost) * (markup / 100);
	return ans.toLocaleString("en-AU", { style: "currency", currency: "AUD" });
};

export const getPoints = (cost) => {
	const toPoints = cost * 3.142;
	const toString = Number(toPoints.toFixed(0)).toLocaleString();
	return `${toString} pts`;
};

export const randomColour = () => {
	let rando = [];
	let i = 0;
	while (i < 3) {
		const num = Math.floor(Math.random() * 220).toString();
		rando.push(num);
		i++;
	}
	return `rgb(${rando[0]},${rando[1]},${rando[2]})`;
};
