import { Button, Col, Drawer, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import firebase from "../../../API/firebase";
import { getImage } from "../../../Functions/utilities";

const AddCourseProvider = ({ visible, dismiss }) => {
	const [loading, setloading] = useState(false);
	const [navRef, setNavRef] = useState("");

	const createProvider = (val) => {
		setloading(true);
		firebase
			.firestore()
			.collection("CourseProviders")
			.add({
				name: val.name,
				websiteUrl: val.websiteUrl,
				displayPic: "",
				coverPic: getImage(),
				cpType: "lib",
				summary: "Please enter provider sumary...",
				details: "Please enter provider sumary...",
				categories: [],
				status: "inactive",
				courseAttributes: [
					{
						label: "Course Type",
						ref: "courseType",
						type: "Selection",
						filterable: true,
						options: ["Qualification", "Short Course", "Skills bootcamp"],
					},
					{
						label: "Delivery",
						ref: "courseDelivery",
						type: "Selection",
						filterable: true,
						options: ["Online", "In-Person", "Mixed"],
					},
					{
						label: "Workload",
						ref: "workload",
						type: "Selection",
						filterable: false,
						options: ["Part-time", "Full-time"],
					},
				],
			})
			.then((res) => {
				firebase
					.firestore()
					.collection("Commercials")
					.doc(res.id)
					.set({ markup: 15, type: "CourseProviders" });
				return res;
			})
			.then((res) => {
				setNavRef(`/Course-Provider/${res.id}`);
				setloading(false);
			})
			.catch((err) => {
				setloading(false);
			});
	};

	return (
		<Drawer
			destroyOnClose={true}
			visible={visible}
			title="Add a new course provider"
			width={350}
			onClose={dismiss}
			footer={
				<Row justify="end">
					<Button onClick={dismiss} data-testid="provider-cancel">
						Cancel
					</Button>
					<Button
						type="primary"
						form="myForm"
						loading={loading}
						key="submit"
						htmlType="submit"
						style={{ marginLeft: 12 }}
						data-testid="provider-create"
					>
						Create
					</Button>
				</Row>
			}
		>
			<Form id="myForm" onFinish={createProvider} layout="vertical" hideRequiredMark>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="name"
							label="Name"
							rules={[{ required: true, message: "Please enter provider name" }]}
						>
							<Input
								placeholder="Please enter provider name"
								data-testid="provider-name"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="websiteUrl"
							label="Website URL"
							rules={[{ required: true, message: "Please enter provider's website" }]}
						>
							<Input
								placeholder="Please enter website URL"
								data-testid="provider-website"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}></Col>
					<Col span={12}></Col>
				</Row>
			</Form>
			{navRef && <Redirect to={navRef} />}
		</Drawer>
	);
};

export default AddCourseProvider;
