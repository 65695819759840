import React, { useContext } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import Logo from "../Assets/logo-small.png";
import LogoSmall from "../Assets/logoInitials.png";
import {
	PieChartOutlined,
	TeamOutlined,
	AppstoreOutlined,
	RocketOutlined,
	CrownOutlined,
	SolutionOutlined,
	ThunderboltOutlined,
	UserOutlined,
	BankOutlined,
	CoffeeOutlined,
	PictureOutlined,
	UnorderedListOutlined,
} from "@ant-design/icons";
import { colorsLight } from "../Styles/Themes";
import UserContext from "../Context/UserContext";
const { Sider } = Layout;
const { SubMenu } = Menu;

const MenuMain = ({ active }) => {
	const { collapsed, setCollapsed } = useContext(UserContext);

	const getActiveKeys = (act) => {
		switch (act) {
			case "1":
				return [];
			case "2":
				return ["sub1"];
			case "3":
				return ["sub1"];
			case "4":
				return ["sub1"];
			case "5":
				return ["sub1"];
			case "6":
				return ["sub2"];
			case "7":
				return ["sub2"];
			case "8":
				return ["sub2"];
			// case "9":
			// 	return ["sub2"];
			case "x":
				return ["sub3"];
			case "z":
				return ["sub3"];
			case "c":
				return ["sub3"];
			default:
				return [];
		}
	};

	return (
		<Sider
			collapsible
			collapsed={collapsed}
			onCollapse={() => setCollapsed(!collapsed)}
			width={200}
			className="site-layout-background"
			data-testid="menu"
		>
			<div
				style={{
					backgroundColor: colorsLight("primary"),
					height: 64,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
				}}
			>
				<img
					src={collapsed ? LogoSmall : Logo}
					alt="Careerbase logo"
					style={{ height: 22, alignSelf: "center" }}
					data-testid="menu-logo"
				/>
			</div>
			<Menu
				theme="dark"
				mode="inline"
				defaultSelectedKeys={["1"]}
				defaultOpenKeys={getActiveKeys(active)}
				selectedKeys={[active]}
				style={{ height: "100%" }}
			>
				<Menu.Item
					key="1"
					icon={<PieChartOutlined />}
					title={"Home"}
					data-testid="menu-home"
				>
					<Link to="/Home">Home</Link>
				</Menu.Item>
				<SubMenu
					key="sub1"
					icon={<AppstoreOutlined />}
					title="Store"
					data-testid="menu-store"
				>
					<Menu.Item key="2" icon={<RocketOutlined />} data-testid="menu-browse">
						<Link to="/Browse">Browse</Link>
					</Menu.Item>
					<Menu.Item key="3" icon={<CrownOutlined />} data-testid="menu-coaches">
						<Link to="/Coaches">Coaches</Link>
					</Menu.Item>
					<Menu.Item key="4" icon={<SolutionOutlined />} data-testid="menu-courses">
						<Link to="/Course-Providers">Courses</Link>
					</Menu.Item>
					<Menu.Item key="5" icon={<ThunderboltOutlined />} data-testid="menu-services">
						<Link to="/Service-Providers">Services</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="sub2" icon={<TeamOutlined />} title="Users" data-testid="menu-users">
					<Menu.Item key="6" icon={<UserOutlined />} data-testid="menu-members">
						<Link to="/Members">Members</Link>
					</Menu.Item>
					<Menu.Item key="7" icon={<BankOutlined />} data-testid="menu-accounts">
						<Link to="/Accounts">Accounts</Link>
					</Menu.Item>
					<Menu.Item key="8" icon={<CoffeeOutlined />} data-testid="menu-managers">
						<Link to="/Managers">Managers</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu
					key="sub3"
					icon={<ThunderboltOutlined />}
					title="Admin tools"
					data-testid="menu-admin"
				>
					<Menu.Item key="x" icon={<CrownOutlined />} data-testid="menu-team">
						<Link to="/Team">Team Members</Link>
					</Menu.Item>
					<Menu.Item key="z" icon={<PictureOutlined />} data-testid="menu-image">
						<Link to="/Image-Library">Image Library</Link>
					</Menu.Item>
					<Menu.Item key="c" icon={<UnorderedListOutlined />} data-testid="menu-playlist">
						<Link to="/Playlists">Playlists</Link>
					</Menu.Item>
					<Menu.Item key="v" icon={<UnorderedListOutlined />}>
						<Link to="/AdminTools">Admin Tools</Link>
					</Menu.Item>
				</SubMenu>
			</Menu>
		</Sider>
	);
};

export default MenuMain;
