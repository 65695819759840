import { Col, Row, Typography, Avatar, Tag, Spin, Divider } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { colorsLight } from "../../../../Styles/Themes";
const { Title } = Typography;

const MemberViewHeaderData = ({ data, privateData, accountOwner, playlistData }) => {
	return (
		<>
			<Row style={{ padding: "12px 12px 0px 12px" }}>
				<Typography.Title level={5}>Public Info</Typography.Title>
			</Row>
			<Row style={{ padding: "0px 24px" }} gutter={24}>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">First Name</p>
						<p className="Value">{data.firstName}</p>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Email</p>
						<p className="Value">{data.email}</p>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Last Name</p>
						<p className="Value">{data.lastName}</p>
					</Row>
				</Col>
			</Row>
			<Row style={{ padding: "12px 12px 0px 12px" }}>
				<Col span={12}>
					<Typography.Title level={5}>Private Info</Typography.Title>
				</Col>
			</Row>
			<Row gutter={24} style={{ padding: "0px 24px 12px 24px" }}>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Mobile</p>
						<p className="Value">{privateData.mobile || "-"}</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Alternate Email</p>
						<p className="Value">{privateData.alternateEmail || "-"}</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Calendar Service</p>
						<p className="Value">{privateData.calendarService || "-"}</p>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Address line 1</p>
						<p className="Value">{privateData.addressLine1 || "-"}</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Address line 2</p>
						<p className="Value">{privateData.addressLine2 || "-"}</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Suburb</p>
						<p className="Value">{privateData.suburb || "-"}</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">City</p>
						<p className="Value">{privateData.city || "-"}</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Postcode</p>
						<p className="Value">{privateData.postCode || "-"}</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">State</p>
						<p className="Value">{privateData.state || "-"}</p>
					</Row>
				</Col>
			</Row>
			<Row style={{ padding: "12px 12px 0px 12px" }}>
				<Col span={12}>
					<Typography.Title level={5}>Active Playlist</Typography.Title>
				</Col>
			</Row>
			<Row gutter={24} style={{ padding: "12px 24px" }}>
				<Col span={12}>
					{data.activePlaylist ? (
						<Col
							style={{
								backgroundColor: colorsLight("lightgrey"),
								borderRadius: 12,
								padding: 6,
								boxShadow: "1px 1px 3px rgba(0,0,0,0.5)",
							}}>
							<Row justify="space-between">
								<p style={{ fontSize: 22, fontWeight: 300, margin: 0 }}>{playlistData.name || "None active"}</p>
								<div>
									<Tag color="green">ACTIVE</Tag>
								</div>
							</Row>
							<Divider style={{ margin: "3px 0px" }} />
							<Row>
								<Col span={8}>
									<Row justify="center">
										<p style={{ margin: 0 }}>Coaches</p>
									</Row>
									<Row justify="center">
										{playlistData.packages ? (
											<p style={{ margin: 0, fontSize: 16, fontWeight: "bold" }}>{playlistData.packages.length}</p>
										) : (
											<Spin size="small" />
										)}
									</Row>
								</Col>
								<Col span={8}>
									<Row justify="center">
										<p style={{ margin: 0 }}>Courses</p>
									</Row>
									<Row justify="center">
										{playlistData.courses ? (
											<p style={{ margin: 0, fontSize: 16, fontWeight: "bold" }}>{playlistData.courses.length}</p>
										) : (
											<Spin size="small" />
										)}
									</Row>
								</Col>
								<Col span={8}>
									<Row justify="center">
										<p style={{ margin: 0 }}>Service</p>
									</Row>
									<Row justify="center">
										{playlistData.services ? (
											<p style={{ margin: 0, fontSize: 16, fontWeight: "bold" }}>{playlistData.services.length}</p>
										) : (
											<Spin size="small" />
										)}
									</Row>
								</Col>
							</Row>
						</Col>
					) : (
						<Row>
							<p>None selected</p>
						</Row>
					)}
				</Col>
			</Row>
			<Row style={{ padding: "12px 12px 0px 12px" }}>
				<Col span={12}>
					<Typography.Title level={5}>Parent Account</Typography.Title>
				</Col>
				<Col span={12}>
					<Typography.Title level={5}>Calendar Sync</Typography.Title>
				</Col>
			</Row>
			<Row gutter={24} style={{ padding: "12px 24px" }}>
				<Col span={12}>
					<Row
						style={{
							backgroundColor: colorsLight("lightgrey"),
							borderRadius: 12,
							padding: 6,
							boxShadow: "1px 1px 3px rgba(0,0,0,0.5)",
						}}>
						<Avatar
							style={{ borderStyle: "solid", borderColor: "grey", borderWidth: 1 }}
							src={accountOwner.displayPic}
							size={70}
						/>
						<Col style={{ paddingLeft: 12 }}>
							<p style={{ fontSize: 22, margin: 0 }} className="Value">
								{accountOwner.name}
							</p>
							<Link style={{ margin: 0 }} to={`/Account/${accountOwner.key}`}>
								View Account
							</Link>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Row
						justify="space-between"
						style={{
							height: 82,
							backgroundColor: colorsLight("lightgrey"),
							borderRadius: 12,
							padding: 6,
							boxShadow: "1px 1px 3px rgba(0,0,0,0.5)",
						}}>
						<Col>
							<p style={{ fontSize: 22, fontWeight: 300, margin: 0 }}>Calendar Integration</p>
							<p style={{ margin: 0 }}>{accountOwner.calendarService ? accountOwner.calendarService : "Not yet synced"}</p>
						</Col>
						<Col>
							<Tag color={privateData.calendarSync ? "green" : "red"}>{privateData.calendarSync ? "ACTIVE" : "INACTIVE"}</Tag>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

const styles = {
	imageWrapper: {
		height: 142,
		width: 142,
		borderRadius: 71,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginTop: -60,
		marginLeft: 24,
	},
	paddingHoro: {},
	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
	formField: {
		margin: "0px 0px 6px 0px",
	},
};

export default MemberViewHeaderData;
