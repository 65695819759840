import { Button, Col, Modal, Row } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";

const AddToPlaylistModal = ({ visible, dismiss, type, data }) => {
	const [playlists, setPlaylists] = useState([]);
	// const [loading, setLoading] = useState(false);
	// const [selected, setSelected] = useState();

	useEffect(() => {
		let listener = firestore()
			.collection("Playlists")
			.onSnapshot((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setPlaylists(list);
			});

		return listener;
	}, []);

	// const toggleSelection = (i) => {
	// 	if (selected !== i) {
	// 		setSelected(i);
	// 	} else {
	// 		setSelected();
	// 	}
	// };

	const playlistInclusionCheck = (i) => {
		const selectedPlaylist = playlists[i];
		if (type === "Packages") {
			const check = CheckArrayForItem(selectedPlaylist.packages, {
				key: data.key,
				provider: data.owner,
			});
			return check ? true : false;
		} else if (type === "Courses") {
			const check = CheckArrayForItem(selectedPlaylist.courses, {
				key: data.key,
				provider: data.owner,
			});
			return check ? true : false;
		} else if (type === "Services") {
			const check = CheckArrayForItem(selectedPlaylist.services, {
				key: data.key,
				provider: data.owner,
			});
			return check ? true : false;
		}
	};

	const CheckArrayForItem = (array, object) => {
		const keys = Object.keys(object);
		let includes = false;
		let i;
		for (i = 0; i < array.length; i++) {
			const objToCheck = array[i];
			let passNum = 0;
			keys.forEach((key) => {
				objToCheck[key] === object[key] && passNum++;
			});
			if (passNum === keys.length) {
				includes = true;
				break;
			}
		}
		return includes;
	};

	const addToPlaylist = (i) => {
		const selectedPlaylist = playlists[i];
		if (type === "Packages") {
			firestore()
				.collection("Playlists")
				.doc(selectedPlaylist.key)
				.update({
					packages: firestore.FieldValue.arrayUnion({
						key: data.key,
						provider: data.owner,
					}),
				});
		} else if (type === "Courses") {
			firestore()
				.collection("Playlists")
				.doc(selectedPlaylist.key)
				.update({
					courses: firestore.FieldValue.arrayUnion({
						key: data.key,
						provider: data.owner,
					}),
				});
		} else if (type === "Services") {
			firestore()
				.collection("Playlists")
				.doc(selectedPlaylist.key)
				.update({
					services: firestore.FieldValue.arrayUnion({
						key: data.key,
						provider: data.owner,
					}),
				});
		}
	};

	const removeFromPlaylist = (i) => {
		const selectedPlaylist = playlists[i];
		if (type === "Packages") {
			firestore()
				.collection("Playlists")
				.doc(selectedPlaylist.key)
				.update({
					packages: firestore.FieldValue.arrayRemove({
						key: data.key,
						provider: data.owner,
					}),
				});
		} else if (type === "Courses") {
			firestore()
				.collection("Playlists")
				.doc(selectedPlaylist.key)
				.update({
					courses: firestore.FieldValue.arrayRemove({
						key: data.key,
						provider: data.owner,
					}),
				});
		} else if (type === "Services") {
			firestore()
				.collection("Playlists")
				.doc(selectedPlaylist.key)
				.update({
					services: firestore.FieldValue.arrayRemove({
						key: data.key,
						provider: data.owner,
					}),
				});
		}
	};

	return (
		<Modal
			width={850}
			title="Add to playlist"
			visible={visible}
			onCancel={dismiss}
			footer={[
				<Button key="cancel" onClick={dismiss}>
					Cancel
				</Button>,
				<Button
					onClick={dismiss}
					key="submit"
					htmlType="submit"
					form="playlistForm"
					type="primary"
				>
					Done
				</Button>,
			]}
		>
			<Col>
				<Row>
					<Col span={12}>
						<p className="Key">Name</p>
					</Col>
					<Col flex="auto">
						<p className="Key">Type</p>
					</Col>
					<Col flex="auto">
						<p className="Key">Owner</p>
					</Col>
					<Col flex="60px"></Col>
				</Row>
				<Row>
					{playlists.length > 0 && (
						<Col span={24}>
							{playlists.map((item, index) => (
								<Row key={index} style={{ width: "100%", cursor: "pointer" }}>
									<Col span={12}>
										<p className="Value">{item.name}</p>
									</Col>
									<Col flex="auto">
										<p className="Value">{item.type}</p>
									</Col>
									<Col flex="auto">
										<p className="Value">{item.ownerName}</p>
									</Col>
									<Col flex="70px">
										<Row style={{ marginBottom: 9 }} justify="end">
											{playlistInclusionCheck(index) ? (
												<Button
													style={{ width: "100%" }}
													type="danger"
													onClick={() => removeFromPlaylist(index)}
													size="small"
												>
													Remove
												</Button>
											) : (
												<Button
													style={{ width: "100%" }}
													onClick={() => addToPlaylist(index)}
													size="small"
												>
													Add
												</Button>
											)}
										</Row>
									</Col>
								</Row>
							))}
						</Col>
					)}
				</Row>
			</Col>
		</Modal>
	);
};

export default AddToPlaylistModal;
