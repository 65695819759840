import React, { useState } from "react";
import { Col, Row, Divider, Typography, Badge, Skeleton, Button } from "antd";
import { getImage } from "../../../../Functions/utilities";
import { colorsLight } from "../../../../Styles/Themes";
import { getPoints } from "../../../../Functions/helpers";
import { coachTagFormatter, priceFormatter } from "../../../../Functions/formatters";
import { PlusOutlined } from "@ant-design/icons";
import AddToPlaylistModal from "../../../Playlists/Components/AddToPlaylistModal";
const { Title, Paragraph } = Typography;

const StorePackageDetails = ({ data = {}, loading = false }) => {
	const [playlistModal, setPlaylistModal] = useState(false);

	const styles = {
		wrapper: {
			borderRadius: 9,
			backgroundColor: colorsLight("white"),
			width: "100%",
		},
		header: {
			backgroundImage: `url(${data.coverPic ? data.coverPic : getImage()})`,
			backgroundSize: "cover",
			height: 180,
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
		},
		imageOverlay: {
			backgroundColor: loading ? "rgb(216 216 216)" : "rgba(2, 48, 71,0.7)",
			height: "100%",
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: "0px 20px",
		},
		name: {
			fontWeight: "bold",
			fontSize: 26,
			color: "#ffffff",
			marginBottom: 0,
		},
		coachType: {
			fontSize: 14,
			color: "#5c5c5c",
			marginBottom: 15,
		},
		avatar: {
			padding: 4,
			backgroundColor: "white",
			boxShadow: "1px 1px 6px rgba(0,0,0,0.2)",
			borderRadius: "50%",
			margin: "0px 0px 10px 0px",
			maxWidth: 145,
			height: 145,
			position: "absolute",
			top: 70,
		},
		coachHeader: {
			width: "100%",
			borderRadius: "9px 9px 0px 0px",
			margin: 0,
			padding: "0px 20px",
		},
		title: {
			fontSize: 22,
		},
		description: {
			fontSize: 14,
			marginBottom: 0,
			width: "100%",
		},
		video: {
			marginTop: 10,
			width: "100%",
		},
		row: {
			padding: "20px 30px",
			width: "100%",
		},
		label: {
			fontSize: 18,
			color: "#565656",
		},
		text: {
			fontWeight: 600,
			fontSize: 18,
			color: "#000000",
			marginTop: 3,
		},
		pts: {
			fontWeight: 600,
			fontSize: 22,
			color: colorsLight("lightBlue"),
			margin: 0,
		},
	};

	const toggleModal = () => setPlaylistModal(!playlistModal);

	return (
		<Badge.Ribbon
			text={
				<span data-testid="package-category">
					{data.primaryCategory ? data.primaryCategory.toUpperCase() : "LOADING"}
				</span>
			}
			placement="start"
			color={coachTagFormatter(data.primaryCategory)}
		>
			<Col span={24} style={styles.wrapper}>
				<Row style={styles.header}>
					<Row
						justify="space-between"
						align="bottom"
						style={styles.imageOverlay}
						data-testid="package-overlay"
					>
						<Typography.Title
							level={2}
							style={{ color: "white", width: "66%" }}
							data-testid="package-name"
						>
							{data.name}
						</Typography.Title>
						<Button
							onClick={toggleModal}
							icon={<PlusOutlined />}
							style={{ position: "absolute", top: 12, right: 12 }}
						>
							Add to playlist
						</Button>
					</Row>
				</Row>
				<Row justify="space-between" style={styles.row}>
					<Col span={8}>
						<div style={styles.label}>Delivery:</div>
						<div style={styles.text} data-testid="package-delivery">
							{data.delivery ? data.delivery : "-"}
						</div>
					</Col>
					<Col span={8}>
						<div style={styles.label}>Duration:</div>
						<div style={styles.text} data-testid="package-sessions">
							{data.sessions ? `${data.sessions} Sessions` : "-"}
						</div>
					</Col>
					<Col span={8}>
						<div style={styles.label}>Price:</div>
						{data.price ? (
							<Row data-testid="package-priceWithPoints" align="middle">
								<div style={styles.pts}>{getPoints(data.price)}</div>
								<p
									style={{
										margin: 0,
										fontSize: 14,
										color: colorsLight("grey"),
										marginLeft: 6,
									}}
								>
									{`(${priceFormatter(data.price, "dollars")})`}
								</p>
							</Row>
						) : (
							<div style={styles.text} data-testid="package-price">
								-
							</div>
						)}
					</Col>
				</Row>
				<Divider style={{ margin: "0px 0px 0px" }} />
				<Row style={styles.row}>
					<Title style={styles.title}>Summarys</Title>
					<div data-testid="package-summary" style={{ display: "contents" }}>
						{data.summary ? (
							<Paragraph
								style={styles.description}
								data-testid="package-summarySkeleton"
							>
								{data.summary}
							</Paragraph>
						) : (
							<Skeleton title={false} paragraph={{ rows: 3 }} active />
						)}
					</div>
				</Row>
				<Row style={styles.row}>
					<Col span={24}>
						<Title style={styles.title}>Details</Title>
						<div data-testid="package-details">
							{data.details && (
								<div
									style={{ width: "100%" }}
									dangerouslySetInnerHTML={{ __html: data.details }}
									data-testid="package-detailsSkeleton"
								/>
							)}
							{!data && <Skeleton title={false} paragraph={{ rows: 5 }} active />}
						</div>
					</Col>
				</Row>
				<AddToPlaylistModal
					visible={playlistModal}
					dismiss={toggleModal}
					type="Packages"
					data={data}
				/>
			</Col>
		</Badge.Ribbon>
	);
};

export default StorePackageDetails;
