import { Col, Layout, Row, Typography } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MyAccountIcons from "../../../../Components/Account/MyAccountIcon";
import MenuMain from "../../../../Components/MainMenu";
import BackButton from "../../../../Components/Navigation/BackButton";
import { colorsLight, maxContentWidth } from "../../../../Styles/Themes";
import ProgramListItem from "./Components/ProgramListItem";
import ProgramUpcomingEvents from "./Components/ProgramsUpcomingEvents";

const { Header, Footer, Content } = Layout;

const ViewAllProgramsPage = () => {
	const [programs, setPrograms] = useState([]);
	const [events, setEvents] = useState([]);
	const [data, setData] = useState({});
	const { memberId } = useParams();
	const history = useHistory();

	useEffect(() => {
		let listener = firestore()
			.collection("Programs")
			.where("member", "==", memberId)
			.onSnapshot((snap) => {
				let list = [];
				snap.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setPrograms(list);
			});

		let listener2 = firestore()
			.collection("Members")
			.doc(memberId)
			.onSnapshot((doc) => {
				setData({ ...doc.data(), key: doc.id });
			});

		let listener3 = firestore()
			.collection("Events")
			.where("attendees", "array-contains", memberId)
			.onSnapshot((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setEvents(list);
			});

		return () => {
			listener();
			listener2();
			listener3();
		};
	}, [memberId]);

	return (
		<Layout className="App">
			<MenuMain active="6" />
			<Layout>
				<Header className="Header">
					<Row
						className="Fill"
						justify="space-between"
						align="middle"
						style={styles.topRow}
					>
						<BackButton history={history} />
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Row
						justify="center"
						style={{
							backgroundColor: colorsLight("lightgrey"),
							width: "100%",
							padding: 24,
						}}
					>
						<Col span={24} style={{ maxWidth: maxContentWidth }}>
							<Row>
								<Typography.Title>
									{data.firstName
										? `${data.firstName}'s programs`
										: "Getting details..."}
								</Typography.Title>
							</Row>
							<Row gutter={24}>
								<Col span={16}>
									{programs.map((item, index) => (
										<ProgramListItem data={item} key={index} />
									))}
								</Col>
								<Col span={8}>
									<ProgramUpcomingEvents events={events} />
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
	},
};

export default ViewAllProgramsPage;
