import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Divider, Row, Typography, Badge, Skeleton, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import firebase from "../../../../API/firebase";
import CoverPhotoUploadModal from "../../../../Components/CoverPhotoUploadModal";
import ProfilePicUploadModal from "../../../../Components/ProfilePicUploadModal";
import { statusTagFormatter } from "../../../../Functions/formatters";
import "../../../../Styles/quill.snow.css";
import { colorsLight } from "../../../../Styles/Themes";
import { motion } from "framer-motion";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

const { Title } = Typography;

const ServiceProviderViewHeader = ({ data }) => {
	const [editing, setEditing] = useState(false);
	const [summary, setSummary] = useState("");
	const [details, setDetails] = useState("");
	const [hoverProfilePic, setHoverProfilePic] = useState(false);
	const [, setloading] = useState(false);
	const [editProfilePic, setEditProfilePic] = useState(false);
	const [editCover, setEditCover] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const [name, setName] = useState("");

	const toggleEdit1 = () => {
		setEditProfilePic(false);
	};

	const toggleEdit2 = () => {
		setEditCover(false);
	};

	const imageBackground = {
		backgroundImage: `url(${data.coverPic})`,
		backgroundColor: colorsLight("grey"),
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: 130,
	};

	useEffect(() => {
		if (data.summary && data.details) {
			setSummary(data.summary);
			setDetails(data.details);
			setName(data.name);
		}
	}, [data]);

	const updateProfile = () => {
		setloading(true);
		firebase
			.firestore()
			.collection("ServiceProviders")
			.doc(data.key)
			.update({
				summary,
				details,
				name,
			})
			.then(() => {
				setloading(false);
				setEditing(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const toggleEdit = () => {
		if (editing) {
			setEditing(false);
		} else {
			setExpanded(true);
			setEditing(true);
		}
	};

	const animVariants = {
		open: {
			maxHeight: 10000,
			opacity: 1,
		},
		closed: {
			maxHeight: 0,
			opacity: 0,
		},
	};

	return (
		<Badge.Ribbon
			color={statusTagFormatter(data.status)}
			text={
				<p style={{ fontSize: 14, marginLeft: 12, marginRight: 12 }}>
					{data.status ? data.status.toUpperCase() : "Loading..."}
				</p>
			}
			placement="start"
		>
			<Col className="Card" style={{ padding: 0, overflow: "hidden" }}>
				<Row justify="end" align="top" style={imageBackground}>
					<Row
						onClick={() => setEditCover(true)}
						justify="center"
						align="middle"
						style={styles.editIconWrapper}
						data-testid="edit-icon"
					>
						<EditOutlined style={styles.editIcon} />
					</Row>
				</Row>
				<Row justify="space-between">
					<Row>
						<Row justify="center" align="middle" style={styles.imageWrapper}>
							<Avatar
								src={data.displayPic}
								size={130}
								icon={<UserOutlined />}
								data-testid="avatar"
							/>
							<Row
								justify="center"
								align="middle"
								onClick={() => setEditProfilePic(true)}
								onMouseEnter={() => setHoverProfilePic(true)}
								onMouseLeave={() => setHoverProfilePic(false)}
								style={{
									height: 130,
									width: 130,
									backgroundColor: hoverProfilePic
										? "rgba(0,0,0,0.5)"
										: "rgba(0,0,0,0)",
									position: "absolute",
									borderRadius: 65,
									cursor: "pointer",
								}}
								data-testid="edit-photo"
							>
								{hoverProfilePic && (
									<p style={{ color: "white", fontWeight: "bold", margin: 0 }}>
										Edit Photo
									</p>
								)}
							</Row>
						</Row>
						<Col style={{ paddingLeft: 12, marginTop: 6 }}>
							{!editing ? (
								<Title
									style={{ margin: 0, maxWidth: 450 }}
									level={2}
									data-testid="service-name"
								>
									{data.name}
								</Title>
							) : (
								<Input
									style={{ fontSize: 22, fontWeight: "bold" }}
									value={name}
									onChange={(val) => setName(val.target.value)}
									placeholder="Please enter provider name"
								/>
							)}
							<Title
								style={{ margin: 0, fontWeight: 300, marginLeft: 2 }}
								level={4}
								data-testid="category"
							>
								{data.primaryCategory}
							</Title>
						</Col>
					</Row>
					<Row style={{ paddingRight: 12, paddingTop: 12 }}>
						{!editing && (
							<Button onClick={toggleEdit} type="primary" data-testid="edit">
								Edit Profile
							</Button>
						)}
						{editing && (
							<>
								<Button size="small" onClick={toggleEdit} data-testid="cancel">
									Cancel
								</Button>
								<Button
									size="small"
									style={{ marginLeft: 12 }}
									onClick={updateProfile}
									type="primary"
									data-testid="update"
								>
									Update Profile
								</Button>
							</>
						)}
					</Row>
				</Row>

				<Row style={{ paddingLeft: 20, paddingRight: 20 }}>
					<Divider style={{ marginTop: 12, marginBottom: 12 }} />
				</Row>
				<Row style={styles.paddingHoro}>
					<Title level={3}>Summary</Title>
				</Row>
				{!editing && (
					<Row style={styles.paddingHoro}>
						<p data-testid="summary">{data.summary}</p>
					</Row>
				)}
				{!data.summary && (
					<Row style={styles.paddingHoro}>
						<Skeleton active paragraph={{ width: "100%", rows: 5 }} />
					</Row>
				)}
				{editing && (
					<Row style={{ ...styles.paddingHoro, width: "100%" }}>
						<Col span={24}>
							<TextArea
								style={{ width: "100%" }}
								value={summary}
								onChange={(val) => setSummary(val.target.value)}
								placeholder="Please enter summary"
								data-testid="summary"
							/>
						</Col>
					</Row>
				)}
				<motion.div
					initial={{ opacity: 0 }}
					animate={expanded ? "open" : "closed"}
					variants={animVariants}
				>
					<Row style={{ ...styles.paddingHoro, marginTop: 42 }}>
						<Title level={3}>About</Title>
					</Row>
					{!editing && (
						<Row style={{ ...styles.paddingHoro, marginBottom: 42 }}>
							<div
								dangerouslySetInnerHTML={{ __html: data.details }}
								data-testid="details"
							/>
						</Row>
					)}
					{!data.details && (
						<Row style={styles.paddingHoro}>
							<Skeleton active paragraph={{ width: "100%", rows: 8 }} />
						</Row>
					)}
					{editing && (
						<Row style={{ ...styles.paddingHoro, marginBottom: 70 }}>
							<ReactQuill
								placeholder="Enter coach bio..."
								theme="snow"
								value={details}
								onChange={setDetails}
								style={{ width: "100%" }}
								data-testid="details"
							/>
						</Row>
					)}
				</motion.div>
				<Row justify="center" align="middle" style={{ width: "100%" }}>
					{!editing && (
						<Col style={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
							<Row justify="center" align="middle">
								<Row
									justify="center"
									align="middle"
									style={{
										height: 30,
										width: 30,
										backgroundColor: "white",
										boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
										borderRadius: 15,
									}}
								>
									{!expanded && <ArrowDownOutlined />}
									{expanded && <ArrowUpOutlined />}
								</Row>
							</Row>
							<Row>
								<p
									style={{
										color: colorsLight("grey"),
										fontSize: 14,
										marginTop: 6,
									}}
								>
									{expanded ? "Minimise Profile" : "Expand Profile"}
								</p>
							</Row>
						</Col>
					)}
				</Row>
				<ProfilePicUploadModal
					visible={editProfilePic}
					dismiss={toggleEdit1}
					userType="ServiceProviders"
					data={data}
				/>
				<CoverPhotoUploadModal
					visible={editCover}
					dismiss={toggleEdit2}
					userType="ServiceProviders"
					data={data}
				/>
			</Col>
		</Badge.Ribbon>
	);
};

const styles = {
	imageWrapper: {
		height: 142,
		width: 142,
		borderRadius: 71,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginTop: -60,
		marginLeft: 24,
	},
	paddingHoro: {
		paddingLeft: 32,
		paddingRight: 32,
	},
	editIcon: {
		color: colorsLight("darkGrey"),
		cursor: "pointer",
	},
	editIconWrapper: {
		height: 30,
		width: 30,
		borderRadius: 15,
		backgroundColor: "white",
		boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
		marginRight: 9,
		marginTop: 9,
		cursor: "pointer",
	},
};

export default ServiceProviderViewHeader;
