import { Row, Col, Layout, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import "../../Styles/main.scss";
import MenuMain from "../../Components/MainMenu";
import { colorsLight } from "../../Styles/Themes";
import MyAccountIcons from "../../Components/Account/MyAccountIcon";
import CoachCard from "./Components/CoachPackage";
import { Link } from "react-router-dom";
import firebase from "../../API/firebase";
import CourseProviderCard from "./Components/CourseProviderCard";
import ServiceProviderCard from "./Components/ServiceProviderCard";
import DataContext from "../../Context/DataContext";
const { Header, Content } = Layout;

const Browse = () => {
	const {
		coachPacks,
		setCoachPacks,
		courseProviders,
		setCourseProviders,
		serviceProviders,
		setServiceProviders,
	} = useContext(DataContext);
	// const { setCollapsed } = useContext(UserContext);
	// const history = useHistory();

	useEffect(() => {
		//setCollapsed(true);
		let coachPackageListener = async () =>
			await firebase
				.firestore()
				.collection("Packages")
				.onSnapshot((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id, loading: false });
					});
					setCoachPacks(list);
				});

		let courseProvidersListener = async () =>
			await firebase
				.firestore()
				.collection("CourseProviders")
				.onSnapshot((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id, loading: false });
					});
					setCourseProviders(list);
				});

		let serviceProvidersListener = async () =>
			await firebase
				.firestore()
				.collection("ServiceProviders")
				.onSnapshot((snap) => {
					let list = [];
					snap.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id, loading: false });
					});
					setServiceProviders(list);
				});

		coachPackageListener();
		courseProvidersListener();
		serviceProvidersListener();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout className="App">
			<MenuMain active="2" />
			<Layout>
				<Header className="Header">
					<Row className="Fill" justify="end" align="middle" style={styles.topRow}>
						<MyAccountIcons />
					</Row>
				</Header>
				<Content className="content">
					<Col style={{ backgroundColor: colorsLight("lightgrey"), height: "100%" }}>
						<Row style={{ padding: 24 }}>
							<Typography.Title data-testid="title">Browse</Typography.Title>
						</Row>
						<Row
							justify="space-between"
							style={{ paddingLeft: 24, marginBottom: 12, paddingRight: 24 }}
						>
							<Typography.Title level={3} data-testid="coach-title">
								Top Coaching Packages
							</Typography.Title>
							<Link>See more</Link>
						</Row>
						<Row>
							<div style={styles.itemRow} data-testid="coach-list">
								{coachPacks.map((item, index) => (
									<div key={index}>
										<CoachCard
											buttonLabel="View Package"
											buttonLink={`/Store-Package-View/${item.key}&Browse`}
											index={index}
											data={item}
											loading={item.loading}
										/>
									</div>
								))}
							</div>
						</Row>
						<Row
							justify="space-between"
							style={{ paddingLeft: 24, marginBottom: 12, paddingRight: 24 }}
						>
							<Typography.Title level={3} data-testid="course-title">
								Top Course Providers
							</Typography.Title>
							<Link>See more</Link>
						</Row>
						<Row>
							<div
								style={{ ...styles.itemRow, marginBottom: 48 }}
								data-testid="course-list"
							>
								{courseProviders.map((item, index) => (
									<div key={index}>
										<CourseProviderCard
											index={index}
											data={item}
											loading={item.loading}
										/>
									</div>
								))}
							</div>
						</Row>
						<Row
							justify="space-between"
							style={{ paddingLeft: 24, marginBottom: 12, paddingRight: 24 }}
						>
							<Typography.Title level={3} data-testid="service-title">
								Top Service Providers
							</Typography.Title>
							<Link to="/Store-Service-Providers">See more</Link>
						</Row>
						<Row>
							<div style={styles.itemRow} data-testid="service-list">
								{serviceProviders.map((item, index) => (
									<div key={index}>
										<ServiceProviderCard
											index={index}
											data={item}
											loading={item.loading}
										/>
									</div>
								))}
							</div>
						</Row>
					</Col>
				</Content>
			</Layout>
		</Layout>
	);
};

const styles = {
	main: {
		height: "100vh",
		width: "100%",
	},
	topRow: {
		paddingRight: 12,
		backgroundColor: colorsLight("white"),
	},
	itemRow: {
		flexDirection: "row",
		overflowY: "hidden",
		overflowX: "scroll",
		display: "flex",
		paddingLeft: 20,
		marginBottom: 24,
		width: "100%",
	},
};

export default Browse;
