import { Button, Col, message, Popconfirm, Row, Select, Typography } from "antd";
import { firestore } from "firebase";
import React, { useEffect, useState } from "react";

const DemoAccountReset = () => {
	const [loading, setLoading] = useState(false);
	const [members, setMembers] = useState([]);
	const [selectedMember, setSelectedMember] = useState("");

	useEffect(() => {
		firestore()
			.collection("Members")
			.get()
			.then((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
				});
				setMembers(list);
			})
			.catch((err) => {
				message.error("Could not get list of members. Check log for more info");
				console.log(err);
			});
	}, []);

	const resetAccount = async () => {
		setLoading(true);
		try {
			if (!selectedMember) {
				throw new SyntaxError("No selected member!");
			}
			const programs = await firestore()
				.collection("Programs")
				.where("member", "==", selectedMember)
				.get()
				.then((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					return list;
				});

			const events = await firestore()
				.collection("Events")
				.where("attendees", "array-contains", selectedMember)
				.get()
				.then((docs) => {
					let list = [];
					docs.forEach((doc) => {
						list.push({ ...doc.data(), key: doc.id });
					});
					return list;
				});

			const records = events.length + programs.length;

			const batch = firestore().batch();

			programs.forEach((program) => {
				batch.delete(firestore().collection("Programs").doc(program.key));
			});
			events.forEach((item) => {
				batch.delete(firestore().collection("Events").doc(item.key));
			});

			await batch.commit();
			message.info(`Deleted ${records} records!`);
			setLoading(false);
		} catch (err) {
			message.error("Could not delete records, check log for more info");
			setLoading(false);
		}
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Typography.Title level={5}>Demo Account Reset</Typography.Title>
			</Row>
			<Row>
				<Col span={15}>
					<Select
						style={{ width: "100%" }}
						value={selectedMember}
						onChange={(val) => setSelectedMember(val)}
						placeholder="Select user account"
					>
						{members.map((item) => (
							<Select.Option value={item.key}>{item.displayName}</Select.Option>
						))}
					</Select>
				</Col>
				<Col span={9}>
					<Row justify="end">
						<Popconfirm
							title={
								process.env.REACT_APP_PROJECT_ID === "careerbase-development-2610e"
									? "Are you sure you want to do this?"
									: "This is a production environment, ARE YOU SURE?!?!"
							}
							onConfirm={resetAccount}
						>
							<Button disabled={!selectedMember} loading={loading}>
								Reset
							</Button>
						</Popconfirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

const styles = {};

export default DemoAccountReset;
