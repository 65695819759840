import { Button, Col, Row, Statistic, Typography, Modal } from "antd";
import { firestore } from "firebase";
import React, { useState } from "react";

const CoachDataChecker = () => {
	const [loading, setLoading] = useState(false);
	const [passes, setPasses] = useState([]);
	const [fails, setFails] = useState([]);
	const [reads, setReads] = useState(0);
	const [modal, setModal] = useState(false);
	const [modal2, setModal2] = useState(false);

	const checkRecords = async () => {
		setLoading(true);
		let reads = 0;
		//GETS LIST OF COACHES
		let coachesList = await firestore()
			.collection("Coaches")
			.get()
			.then((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
					reads++;
				});
				return list;
			});

		//GETS LIST OF USER RECORDS
		let userRecords = await firestore()
			.collection("UserRecords")
			.get()
			.then((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
					reads++;
				});
				return list;
			});

		//GETS LIST OF ACCESS KEYS
		let accessKeys = await firestore()
			.collection("accessKeys")
			.get()
			.then((docs) => {
				let list = [];
				docs.forEach((doc) => {
					list.push({ ...doc.data(), key: doc.id });
					reads++;
				});
				return list;
			});
		setReads(reads);
		let fails = [];
		let passes = [];
		coachesList.forEach((coach) => {
			let t1 = false;
			let t2 = false;
			let i;
			for (i = 0; i < userRecords.length; i++) {
				if (userRecords[i].key === coach.key) {
					t1 = true;
					break;
				}
			}
			let x;
			for (x = 0; x < accessKeys.length; x++) {
				if (accessKeys[x].key === coach.key) {
					t2 = true;
					break;
				}
			}
			if (t1 === false) {
				fails.push({ ...coach, userRecord: t1, accessKey: t2 });
			} else {
				passes.push(coach);
			}
		});
		setPasses(passes);
		setFails(fails);
		setLoading(false);
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Typography.Title level={5}>Coach Record Checker</Typography.Title>
				<Button size="small" onClick={checkRecords} loading={loading}>
					Check
				</Button>
			</Row>
			<Row>
				<Col onClick={() => setModal(!modal)} span={12}>
					<Statistic title="Fail" value={fails.length} />
				</Col>
				<Col onClick={() => setModal2(!modal2)} span={12}>
					<Statistic title="Pass" value={passes.length} />
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Statistic title="Reads" value={reads} />
				</Col>
				<Col span={12}></Col>
			</Row>
			<Modal onCancel={() => setModal(!modal)} onOk={() => setModal(!modal)} visible={modal}>
				<Row justify="space-between">
					<p>Name</p>
					<p>User Rec</p>
				</Row>
				{fails.map((coach) => (
					<Row justify="space-between">
						<p>{coach.displayName}</p>
						<p>{coach.userRecord ? "pass" : "fail"}</p>
					</Row>
				))}
			</Modal>
			<Modal
				onCancel={() => setModal2(!modal2)}
				onOk={() => setModal2(!modal2)}
				visible={modal2}
			>
				<Row justify="space-between">
					<p>Name</p>
					<p>Email</p>
				</Row>
				{passes.map((coach) => (
					<Row justify="space-between">
						<p>{coach.displayName}</p>
						<p>{coach.email}</p>
					</Row>
				))}
			</Modal>
		</Col>
	);
};

const styles = {};

export default CoachDataChecker;
