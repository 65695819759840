import {
	Button,
	Checkbox,
	Col,
	Divider,
	Form,
	Input,
	message,
	Row,
	Select,
	Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import firebase from "../../../../../API/firebase";
const { Title } = Typography;

const CourseOverview = ({ data, providerData }) => {
	const [editing, setEditing] = useState(false);
	const [form] = Form.useForm();

	const toggleEdit = () => {
		setEditing(!editing);
	};

	useEffect(() => {
		form.resetFields();
	}, [data, form]);

	// useEffect(() => {
	// console.log(providerData.courseAttributes);
	// }, [providerData]);

	const update = (val) => {
		let keys = Object.keys(val);
		let values = val;
		keys.forEach((key) => {
			if (val[key] === undefined) {
				val[key] = "";
			}
		});

		firebase
			.firestore()
			.collection("CourseProviders")
			.doc(data.owner)
			.collection("Courses")
			.doc(data.key)
			.update(values)
			.then(() => {
				setEditing(false);
				message.success("Updated course overview");
			})
			.catch((err) => {
				message.error("Could not update record");
			});
	};

	return (
		<Col className="Card">
			<Row justify="space-between">
				<Title level={5}>Course Overview</Title>
				{!editing ? (
					/* eslint-disable jsx-a11y/anchor-is-valid */
					<a onClick={toggleEdit} data-testid="edit">
						Edit
					</a>
				) : (
					<Row>
						<Button size="small" type="link" onClick={toggleEdit} data-testid="cancel">
							Cancel
						</Button>
						<Button
							form="myForm"
							htmlType="submit"
							size="small"
							type="link"
							data-testid="update"
						>
							Update
						</Button>
					</Row>
				)}
			</Row>
			<Divider style={{ marginTop: 0, marginBottom: 6 }} />

			{providerData.courseAttributes && data && (
				<Form
					form={form}
					initialValues={data}
					id="myForm"
					onFinish={update}
					layout="horizontal"
				>
					{providerData.courseAttributes.map((item, index) => (
						<div key={index}>
							{item.type === "Text Input" && (
								<Row justify="space-between">
									<Col span={12}>
										<p className="Key">{item.label}:</p>
									</Col>
									<Col span={12}>
										{!editing ? (
											<p data-testid="input">{data[item.ref] || "-"}</p>
										) : (
											<Form.Item name={item.ref}>
												<Input
													placeholder={`Please enter ${item.label.toLowerCase()}`}
													data-testid="input"
												/>
											</Form.Item>
										)}
									</Col>
								</Row>
							)}
							{item.type === "Text Area" && (
								<Row justify="space-between">
									<Col span={12}>
										<p className="Key">{item.label}:</p>
									</Col>
									<Col span={12}>
										{!editing ? (
											<p data-testid="text-area">{data[item.ref] || "-"}</p>
										) : (
											<Form.Item name={item.ref}>
												<TextArea
													style={{ height: 170 }}
													placeholder={`Please enter ${item.label.toLowerCase()}`}
													data-testid="text-area"
												/>
											</Form.Item>
										)}
									</Col>
								</Row>
							)}
							{item.type === "Check Box" && (
								<Row justify="space-between">
									<Col span={12}>
										<p className="Key">{item.label}:</p>
									</Col>
									<Col span={12}>
										{!editing ? (
											<p data-testid="checkbox">{data[item.ref] || "-"}</p>
										) : (
											<Form.Item name={item.ref}>
												<Checkbox
													placeholder={`Please enter ${item.label.toLowerCase()}`}
													data-testid="checkbox"
												/>
											</Form.Item>
										)}
									</Col>
								</Row>
							)}
							{item.type === "Selection" && (
								<Row justify="space-between">
									<Col span={12}>
										<p className="Key">{item.label}:</p>
									</Col>
									<Col span={12}>
										{!editing ? (
											<p data-testid="select">{data[item.ref] || "-"}</p>
										) : (
											<Form.Item name={item.ref}>
												<Select
													placeholder={`Please enter ${item.label.toLowerCase()}`}
													data-testid="select"
												>
													{item.options.map((item, index) => (
														<Select.Option key={index} value={item}>
															{item}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										)}
									</Col>
								</Row>
							)}
						</div>
					))}
				</Form>
			)}
		</Col>
	);
};

export default CourseOverview;
