import { Col, Divider, Row, Typography, Avatar, Button, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { colorsLight } from "../../../../Styles/Themes";

const { Title, Paragraph } = Typography;

const StoreCourseProviderInfo = ({ data = {}, courseId }) => {
	return (
		<Col className="Card">
			<Row>
				<Avatar
					src={data.displayPic}
					size={84}
					style={{
						boxShadow: "1px 1px 4px rgba(0,0,0,0.5)",
						borderStyle: "solid",
						borderColor: colorsLight("white"),
						borderWidth: 3,
						backgroundColor: "white",
					}}
					data-testid="course-avatar"
				/>
				<Col>
					<Title
						style={{
							maxWidth: 250,
							marginLeft: 12,
							marginTop: 0,
							fontWeight: 300,
							marginBottom: 0,
						}}
						level={3}
						data-testid="course-name"
					>
						{data.name || "Loading"}
					</Title>
					<a
						style={{ margin: 0, marginLeft: 12 }}
						href={data.websiteUrl}
						data-testid="course-website"
					>
						{data.websiteUrl}
					</a>
				</Col>
			</Row>
			<Divider style={{ marginTop: 12, marginBottom: 6 }} />
			<Row>
				<p className="Key">About</p>
			</Row>
			<Row>
				{data.summary ? (
					<Paragraph
						ellipsis={{
							rows: 3,
							expandable: false,
							symbol: "...",
							suffix: <a href={`/Course-Provider/${data.key}`}>Read on</a>,
						}}
						data-testid="course-summary"
					>
						{data.summary}
					</Paragraph>
				) : (
					<Skeleton title={false} paragraph={{ rows: 3 }} />
				)}
			</Row>
			<Row justify="end" align="bottom" style={{ width: "100%" }}>
				<Link to={`/Course/${data.key}&${courseId}`} data-testid="course-manage">
					<Button>Manage Course</Button>
				</Link>
				<Link
					style={{ marginLeft: 12 }}
					to={`/Course-Provider/${data.key}`}
					data-testid="course-view"
				>
					<Button>View Profile</Button>
				</Link>
			</Row>
		</Col>
	);
};

export default StoreCourseProviderInfo;
